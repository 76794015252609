import React from 'react';
import { useHistory } from 'hooks/hooks.js';
import { makeStyles } from '@mui/styles';
import { Alert, Button, BUTTON_VARIANT } from 'Components/components.js';
import { ALERT_SEVERITY } from 'constants/constants.js';
import { ReactComponent as Logo } from 'assets/img/full-white-logo.svg';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: 608,
    '& .logo': {
      marginBottom: 32,
    },
    '& .form': {
      display: 'flex',
      flexDirection: 'column',
      '& .formBlock': {
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
      },
    },
  },
}));

const SetUpProject = ({
  title,
  error,
  TextBlockComponent,
  FormComponent,
  severity = ALERT_SEVERITY.error,
  ...props
}) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.wrapper}>
      <div className='headerContainer'>
        <Logo className='logo' />

        <h1 className='txt-mainDark-32-700 textWhiteColor mb-1'>{title}</h1>

        <div className='txt-mainDark-13-500 textWhiteColor'>
          <TextBlockComponent {...props} />
        </div>
      </div>

      <div className='mainContainer'>
        <Alert
          alertComponent={error}
          severity={severity}
          withTopMargin={false}
          className='mb-4'
        />

        <FormComponent {...props} />
      </div>

      <Button
        onClick={history.goBack}
        text='Go back'
        variant={BUTTON_VARIANT.text}
        className='p-0 bottomText'
        fullWidth={false}
      />
    </div>
  );
};

export { SetUpProject };
