import React from 'react';
import {
  useCallback,
  useEffect,
  useState,
  useMutation,
  useQueryClient,
  useSyncTableToSearchParam,
  useAutoChangeProject,
  useLocation,
  useMemo,
  useHistory,
  useAllCostsSourceTables,
} from 'hooks/hooks.js';
import { makeStyles } from '@mui/styles';
import { fetcherPost } from 'utils/utils.js';
import {
  AMPL_PAGE_EVENT,
  QUERY_TYPES,
  SEARCH_PARAMS,
  LineageTabs,
  DOCUMENT_TITLE,
} from 'constants/constants.js';
import { useProjectTree, useUserInfo } from 'context/context.js';
import {
  LatestTable,
  SourcesButton,
  TableDatasetSearch,
  GraphPipes,
  EmptyData,
  PageHeader,
} from 'Components/components.js';
import { amplEvent } from 'service/services.js';
import { getFullTableName } from 'utils/helpers/helpers.js';
import {
  MH_PIPELINE_PREFIX,
  PIPELINE_TYPE,
  PIPELINE_TYPE_STRING,
} from 'utils/constants.js';

const LIVE_SPORT_TENANT = 'livesport-dwh-transformation';

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    display: 'flex',
    gap: 12,
    width: '100%',
    '& .tableSearch': {
      flexGrow: 1,
    },
    marginBottom: 24,
  },
}));

const Lineage = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { user } = useUserInfo();
  const [tableInfo, setTableInfo] = useState({});
  const { tableFromURL, tableInfoFromUrl, clearSearchParams } =
    useSyncTableToSearchParam(tableInfo?.table, tableInfo?.dataset, null);
  const [startPage, setStartPage] = useState(tableFromURL === null);
  const [existingLineage, setExistingLineage] = useState(true);
  const queryClient = useQueryClient();
  const { setIsOpen, setSelectTable, setOnSelectTable, setSelectDataset } =
    useProjectTree();
  const [tableauPipelines, setTableauPipelines] = useState(null);

  const searchParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);
  const getCurrentTabFromUrl = () => {
    const tab = searchParams.get(SEARCH_PARAMS.TAB);
    if (tab && Object.values(LineageTabs).includes(tab)) {
      return tab;
    }
    return LineageTabs.COLUMN;
  };
  const [currentTab, setCurrentTab] = useState(getCurrentTabFromUrl());

  const isAllowedRequest =
    user?.info?.adminData?.currentTenant === LIVE_SPORT_TENANT ||
    user?.info.tenant === LIVE_SPORT_TENANT;

  const { sources, isFetchingSources } = useAllCostsSourceTables({
    dataset: `${MH_PIPELINE_PREFIX}${PIPELINE_TYPE_STRING.TABLEAU}`,
    table: `${MH_PIPELINE_PREFIX}${PIPELINE_TYPE_STRING.TABLEAU}`,
    pipelineType: PIPELINE_TYPE.TABLEAU,
    page: 0,
    enabled: isAllowedRequest,
  });

  useEffect(() => {
    document.title = DOCUMENT_TITLE.lineage;
  }, []);

  useEffect(() => {
    if (!isAllowedRequest || currentTab !== LineageTabs.PIPELINE) {
      setTableauPipelines([]);
    } else if (!isFetchingSources && currentTab === LineageTabs.PIPELINE) {
      setTableauPipelines(sources);
    }
  }, [isAllowedRequest, sources, isFetchingSources, currentTab]);

  useEffect(() => {
    if (
      !startPage &&
      currentTab &&
      searchParams.get(SEARCH_PARAMS.TAB) !== currentTab
    ) {
      searchParams.set(SEARCH_PARAMS.TAB, currentTab);
      history.replace({
        pathname: location.pathname,
        search: searchParams.toString(),
        state: history.location.state,
      });
    } else if (startPage && searchParams.get(SEARCH_PARAMS.TAB)) {
      searchParams.delete(SEARCH_PARAMS.TAB);
      history.replace({
        pathname: location.pathname,
        search: searchParams.toString(),
        state: history.location.state,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab, history, location.pathname, searchParams, startPage]);

  useAutoChangeProject();

  const { mutateAsync: updateLastSeenTables } = useMutation(
    (params) => {
      return fetcherPost('/api/v1/last-tables', params);
    },
    {
      onSuccess: (data) => {
        if (data) {
          queryClient.invalidateQueries(QUERY_TYPES.lastSeenPages);
        }
      },
    }
  );

  useEffect(() => {
    if (tableFromURL === null) {
      setStartPage(true);
      setTableInfo(null);
      setSelectTable(null);
      setSelectDataset(null);
      setIsOpen(true);
      setCurrentTab(LineageTabs.COLUMN);
    } else {
      setStartPage(false);
      setIsOpen(false);
    }
  }, [tableFromURL]);

  useEffect(() => {
    return () => setIsOpen(false);
  }, [setIsOpen]);

  useEffect(() => {
    if (tableInfo?.table && tableInfo?.dataset) {
      updateLastSeenTables({
        name: tableInfo?.table,
        dataset: tableInfo?.dataset,
        category: 'LINEAGE',
      });

      amplEvent(
        `${AMPL_PAGE_EVENT.lineage} -> selected table -> ${getFullTableName(
          tableInfo.table,
          tableInfo.dataset
        )}`
      );
    }
  }, [tableInfo?.table, tableInfo?.dataset, updateLastSeenTables]);

  const handleChange = useCallback((value) => {
    setTableInfo(value);
    setSelectTable(value);
    setStartPage(!value);
    setExistingLineage(true);

    if (value?.table && value?.dataset) {
      amplEvent(
        `${AMPL_PAGE_EVENT.lineage} -> ${
          currentTab || LineageTabs.COLUMN
        } -> ${getFullTableName(value.table, value.dataset)}`
      );
    }

    if (value === null) clearSearchParams();
  }, []);

  useEffect(() => {
    if (handleChange !== undefined) {
      setOnSelectTable({ onChange: handleChange });
    }
  }, [handleChange]);

  const handleProjectTree = () => setIsOpen((prev) => !prev);

  const handleChangeTab = useCallback((selectedTab) => {
    if (tableInfo?.table && tableInfo?.dataset) {
      amplEvent(
        `${AMPL_PAGE_EVENT.lineage} -> ${selectedTab} -> ${getFullTableName(
          tableInfo.table,
          tableInfo.dataset
        )}`
      );
    }
    setCurrentTab(selectedTab);
  }, []);

  return (
    <>
      <div className={classes.searchContainer}>
        <SourcesButton onClick={handleProjectTree} />
        <TableDatasetSearch
          optionLabel='Lineage for:'
          onTableChange={handleChange}
          defaultDataset={tableInfoFromUrl?.dataset}
          defaultTable={tableInfoFromUrl?.table}
          className='tableSearch'
          disableDatasets={true}
          withSearch={false}
        />
      </div>
      {startPage ? (
        <LatestTable onChange={handleChange} seenCategory='LINEAGE' />
      ) : (
        <>
          <PageHeader hasDateRangePicker={false}>
            <span className='no-select-text'>Lineage</span>
          </PageHeader>
          {!existingLineage ? (
            <EmptyData
              title='There is no lineage for this table'
              subtitle='The table does not have any interactions inside Google BigQuery.'
            />
          ) : (
            <GraphPipes
              {...tableInfo}
              setExistingLineage={setExistingLineage}
              onTableChange={handleChange}
              onLineageViewChange={handleChangeTab}
              selectedLineageView={currentTab}
              tableauPipelines={tableauPipelines}
            />
          )}
        </>
      )}
    </>
  );
};

export default Lineage;
