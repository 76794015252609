const PipelineTableCellStyles = {
  DESTINATION_TABLE_NAME_WIDTH: 360,
  DESTINATION_TABLE_NAME_FULL_WIDTH: 512,
  JOB_NAME_WIDTH: 200,
  PRINCIPAL_EMAIL_WIDTH: 190,
  PRINCIPAL_EMAIL_FULL_WIDTH: 300,
  TAGS_CELL_WIDTH: 300,
  RESERVATION_WIDTH: 180,
  LAST_ACTIVITY_CELL_WIDTH: 180,
};

export { PipelineTableCellStyles };
