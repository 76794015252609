const AMPL_PAGE_EVENT = {
  dashboard: 'Dashboard page',
  monitors: 'Monitors page',
  incidents: 'Incidents page',
  lineage: 'Lineage page',
  dictionary: 'Dictionary page',
  dataQuality: 'Data Quality page',
  createDataScan: 'Create data scan page',
  pipeline: 'Pipelines page',
  costs: 'Costs page',
  integrations: 'Integrations page',
  users: 'Users page',
};
export { AMPL_PAGE_EVENT };
