const DOCUMENT_TITLE = {
  login: 'Login | Masthead Data',
  signUp: 'Sign Up | Masthead Data',
  deployment: 'Deployment | Masthead Data',
  dashboard: 'Dashboard | Masthead Data',
  monitors: 'Monitors | Masthead Data',
  incidents: 'Incidents | Masthead Data',
  lineage: 'Lineage | Masthead Data',
  dictionary: 'Dictionary | Masthead Data',
  dataQuality: 'Data Quality | Masthead Data',
  pipelines: 'Pipelines | Masthead Data',
  integrations: 'Integrations | Masthead Data',
  users: 'Users | Masthead Data',
  admin: 'Admin | Masthead Data',
};

export { DOCUMENT_TITLE };
