import { useState, useEffect } from 'hooks/hooks.js';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { IconButton } from 'Components/components.js';
import { ReactComponent as ArrowUpIcon } from 'assets/img/icons/arrows/arrow-right.svg';

const useStyles = makeStyles((theme) => ({
  button: {
    '&.MuiButtonBase-root': {
      position: 'fixed',
      bottom: theme.spacing(10),
      right: theme.spacing(10),
      width: 56,
      height: 56,
      backgroundColor: theme.palette.common.black,
      zIndex: 99,
      '-webkit-transition': 'all .4s ease-in-out',
      transition: 'all .4s ease-in-out',

      '&:hover': {
        backgroundColor: theme.palette.common.black,
        '& svg path': {
          fill: theme.palette.common.white,
        },
      },
    },
    '& svg path': {
      fill: theme.palette.common.white,
    },
  },
  fadeIn: {
    opacity: 1,
    visibility: 'visible',
  },
  fadeOut: {
    opacity: 0,
    visibility: 'hidden',
  },
  rotateIcon: {
    transform: 'rotate(-90deg)',
  },
}));

const ScrollUpButton = () => {
  const classes = useStyles();
  const [isVisibleButton, setIsVisibleButton] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);

  const handleVisibleButton = () => {
    const currentScrollY = window.pageYOffset;

    if (currentScrollY < lastScrollY && currentScrollY > 500) {
      setIsVisibleButton(true);
    } else {
      setIsVisibleButton(false);
    }

    setLastScrollY(currentScrollY);
  };

  const handleScrollUp = () => {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleVisibleButton);

    return () => {
      window.removeEventListener('scroll', handleVisibleButton);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastScrollY]);

  return (
    <IconButton
      icon={
        <ArrowUpIcon className={classes.rotateIcon} width={10} height={20} />
      }
      onClick={handleScrollUp}
      className={clsx(
        classes.button,
        isVisibleButton ? classes.fadeIn : classes.fadeOut
      )}
    />
  );
};

export { ScrollUpButton };
