import React from 'react';
import { usePagination, usePipelineLastRuns, useEffect } from 'hooks/hooks.js';
import moment from 'moment';
import { Table, TableBody, TableContainer, TableHead } from '@mui/material';
import {
  IconButton,
  OverflownText,
  Pagination,
  PositionLoader,
  StyledTableCell,
  StyledTableRow,
} from 'Components/components.js';
import {
  JobName,
  StartTime,
} from 'Components/PipelineDetails/libs/constants/constants.js';
import { handleCopyText, getUnixDateByStr } from 'utils/helpers/helpers.js';
import { DATE_FORMAT } from 'constants/constants.js';
import { ReactComponent as CopyIcon } from 'assets/img/icons/copyIcon.svg';

const HEAD_CELLS = [StartTime, JobName];
const JOB_NAME_MAX_WIDTH = 480;
const DEFAULT_ROWS_PER_PAGE = 5;

const JobsRunsTable = ({ jobHash, frequencyMilliseconds, startDate }) => {
  const startPeriodUnix = getUnixDateByStr(startDate);
  const endPeriodUnix = startPeriodUnix + frequencyMilliseconds;
  const formattedStartDate = startDate ? new Date(startPeriodUnix) : null;
  const formattedEndDate = startDate ? new Date(endPeriodUnix) : null;

  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination(false, 0, DEFAULT_ROWS_PER_PAGE);

  const { pipelineLastRuns, pipelineLastJobsCount, isFetching } =
    usePipelineLastRuns({
      jobHash,
      limit: rowsPerPage,
      page,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    });

  useEffect(() => {
    handleChangePage({}, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate]);

  return (
    <TableContainer>
      <Table>
        <LastRunsTableHead headCells={HEAD_CELLS} />
        <TableBody className='position-relative'>
          {isFetching ? (
            <StyledTableRow>
              <StyledTableCell colSpan={HEAD_CELLS.length}>
                <div style={{ minHeight: 275 }}>
                  <PositionLoader />
                </div>
              </StyledTableCell>
            </StyledTableRow>
          ) : !pipelineLastRuns.length ? (
            <StyledTableRow>
              <StyledTableCell colSpan={HEAD_CELLS.length}>
                <div className='txt-grey-13-500 text-center text-italic'>
                  There were no jobs run during this period
                </div>
              </StyledTableCell>
            </StyledTableRow>
          ) : (
            pipelineLastRuns.map((item) => (
              <LastRunsTableBody
                key={`${item.jobName}/${item.bytes}`}
                rawData={item}
              />
            ))
          )}
        </TableBody>
      </Table>

      <Pagination
        count={pipelineLastJobsCount}
        rowsPerPage={rowsPerPage}
        page={page}
        defaultRowsPerPage={DEFAULT_ROWS_PER_PAGE}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};

const LastRunsTableHead = ({ headCells }) => {
  return (
    <TableHead>
      <StyledTableRow>
        {headCells.map(
          (headCell) =>
            headCell.IS_SHOW && (
              <StyledTableCell key={headCell.ID} align={headCell.ALIGN}>
                <span className='txt-grey-13-500 nowrapText'>
                  {headCell.LABEL}
                </span>
              </StyledTableCell>
            )
        )}
      </StyledTableRow>
    </TableHead>
  );
};

const LastRunsTableBody = ({ rawData }) => {
  return (
    <StyledTableRow>
      <StyledTableCell>
        {!rawData.startTime.length ? (
          <span className='txt-grey-13-500'>N/A</span>
        ) : (
          moment(rawData.startTime)
            .utc()
            .format(DATE_FORMAT.shortMonthYearTimeSecond)
        )}
      </StyledTableCell>

      <StyledTableCell>
        <OverflownText title={rawData.jobName} maxWidth={JOB_NAME_MAX_WIDTH}>
          {rawData.jobName}
        </OverflownText>
      </StyledTableCell>

      <StyledTableCell>
        <IconButton
          className='p-0'
          icon={<CopyIcon />}
          onClick={() => handleCopyText(rawData.jobName)}
        />
      </StyledTableCell>
    </StyledTableRow>
  );
};

export { JobsRunsTable };
