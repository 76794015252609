import { useQuery, useMemo } from 'hooks/hooks.js';
import moment from 'moment/moment';
import { fetcherGet } from 'utils/utils.js';
import { QUERY_TYPES } from 'constants/constants.js';

const usePipelineLastRuns = ({ jobHash, limit, page, startDate, endDate }) => {
  const startDateUtc = startDate ? moment(startDate).utc().format() : null;
  const endDateUtc = endDate ? moment(endDate).utc().format() : null;

  const { data, isFetching } = useQuery(
    [
      QUERY_TYPES.pipelineLastJobs,
      jobHash,
      limit,
      page,
      startDateUtc,
      endDateUtc,
    ],
    ({ queryKey }) => {
      const [url] = queryKey;
      return fetcherGet(url, {
        jobHash,
        limit,
        page: page + 1,
        from: startDateUtc,
        to: endDateUtc,
      });
    },
    { enabled: Boolean(jobHash && startDateUtc && endDateUtc) }
  );

  const pipelineLastRuns = useMemo(() => {
    return data?.values || [];
  }, [data?.values]);

  const pipelineLastJobsCount = useMemo(() => {
    return data?.pagination.total || 0;
  }, [data?.pagination.total]);

  return { pipelineLastRuns, pipelineLastJobsCount, isFetching };
};

export { usePipelineLastRuns };
