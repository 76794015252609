import React from 'react';
import { useState, useMutation, useQueryClient, useMemo } from 'hooks/hooks.js';
import { makeStyles } from '@mui/styles';
import { IconButton } from '@mui/material';
import { Image } from 'react-bootstrap';
import {
  Button,
  Input,
  INPUT_TYPE,
  PositionLoader,
} from 'Components/components.js';
import { useModal, useMessages } from 'context/context.js';
import { fetcherPost } from 'utils/utils.js';
import { ALERT_SEVERITY, QUERY_TYPES } from 'constants/constants.js';
import { amplEvent } from 'service/services.js';
import { ReactComponent as Visibility } from 'assets/img/icons/visibilityIcon.svg';
import { ReactComponent as VisibilityOff } from 'assets/img/icons/visibilityOffIcon.svg';
import { ReactComponent as CloseButton } from 'assets/img/icons/closeIconBgd.svg';
import { ReactComponent as Looker } from 'assets/img/looker.svg';
import bookIcon from 'assets/img/book.svg';

const eventButtonConnect = '-> Click Connect button -';

const useStyles = makeStyles((theme) => ({
  modalWrapper: {
    width: 600,
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    background: theme.palette.secondary.light,
  },
  infoContainer: {
    width: '100%',
    padding: theme.spacing(8),
    background: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(6),
  },
  cancelButton: {
    cursor: 'pointer',
  },
  modalDesc: {
    marginBottom: theme.spacing(6),
  },
  instructionContainer: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
  formContainer: {
    padding: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(8),
  },
}));

const LookerModal = () => {
  const classes = useStyles();
  const { setModal } = useModal();
  const { setMessage } = useMessages();
  const [isLoading, setIsLoading] = useState(false);
  const closeModal = () => setModal(null);
  const queryClient = useQueryClient();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => event.preventDefault();

  const [clientId, setClientId] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [clientUrl, setClientUrl] = useState('');

  const handleChangeClientId = (value) => setClientId(value);
  const handleChangeClientSecret = (value) => setClientSecret(value);
  const handleChangeClientUrl = (value) => setClientUrl(value);

  const isDisabledConnectButton = useMemo(() => {
    return !clientId.length || !clientSecret.length || !clientUrl.length;
  }, [clientId.length, clientSecret.length, clientUrl.length]);

  const { mutateAsync: connectLooker } = useMutation(
    (data) => {
      return fetcherPost('/api/v1/connect-looker', {
        clientId: data?.clientId,
        clientSecret: data?.clientSecret,
        clientUrl: data?.clientUrl,
      });
    },
    {
      onSuccess: (data) => {
        if (data?.success) {
          queryClient.invalidateQueries(QUERY_TYPES.looker);
          setMessage('Looker has been connected', ALERT_SEVERITY.success);
          closeModal();
          amplEvent(`Looker ${eventButtonConnect} success`);
        } else {
          setMessage('Got an error while adding looker connection');
          amplEvent(`Looker ${eventButtonConnect} error`);
        }
      },
      onError: () => {
        setMessage('Got an error while adding looker connection');
        amplEvent(`Looker ${eventButtonConnect} error`);
      },
    }
  );

  const onAgreeClick = () => {
    setIsLoading(true);
    connectLooker({
      clientId: clientId,
      clientSecret: clientSecret,
      clientUrl: clientUrl,
    }).finally(() => {
      setIsLoading(false);
      closeModal();
    });
  };

  return (
    <div className={classes.modalWrapper}>
      {isLoading ? (
        <PositionLoader />
      ) : (
        <>
          <div className={classes.infoContainer}>
            <div className={classes.logoContainer}>
              <Looker />
              <CloseButton
                className={classes.cancelButton}
                onClick={closeModal}
              />
            </div>

            <div className={classes.modalDesc}>
              <div className='txt-mainDark-24-700 mb-1'>Looker</div>
              <div className='txt-grey-16-500 mb-4'>
                Connect Looker to seamlessly monitor and alert for issues with
                the data-feeding Looker dashboards.
              </div>
            </div>

            <a
              href='https://docs.mastheadata.com/integrations/looker'
              target='_blank'
              rel='noreferrer'
            >
              <div className={classes.instructionContainer}>
                <Image src={bookIcon} width={16} height={16} alt='book' />
                <span className='txt-blue-13-500'>How to get started</span>
              </div>
            </a>
          </div>

          <div className={classes.formContainer}>
            <Input
              id='Host-URL'
              value={clientUrl}
              onChange={handleChangeClientUrl}
              label='Host URL and port'
              isRequired={true}
            />

            <Input
              id='ID'
              value={clientId}
              onChange={handleChangeClientId}
              label='Client ID'
              isRequired={true}
            />

            <Input
              id='secret'
              value={clientSecret}
              onChange={handleChangeClientSecret}
              type={showPassword ? INPUT_TYPE.text : INPUT_TYPE.password}
              label='Client Secret'
              isRequired={true}
              withEndAdornment={true}
              EndAdornmentComponent={
                <IconButton
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              }
            />

            <Button
              text='Connect'
              onClick={onAgreeClick}
              isDisabled={isDisabledConnectButton}
            />
          </div>
        </>
      )}
    </div>
  );
};

export { LookerModal };
