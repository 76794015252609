import { SEARCH_PARAMS } from 'constants/constants.js';

const getPipelineDetailsUrl = (currentProject, tab, jobHash) => {
  return encodeURI(
    window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      `?${SEARCH_PARAMS.PROJECT}=${currentProject}` +
      `&${SEARCH_PARAMS.TAB}=${tab}` +
      `&${SEARCH_PARAMS.JOB_HASH}=${jobHash}`
  );
};

export { getPipelineDetailsUrl };
