import React from 'react';
import { useBqBillingData, useMemo, useEffect } from 'hooks/hooks.js';
import moment from 'moment';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { OverflownText } from 'Components/components.js';
import {
  JobTagsTableCell,
  SourceTableCell,
  PipelineDetailsModal,
} from 'Pages/Pipelines/components/components.js';
import { useModal, usePipelinePageContext } from 'context/context.js';
import {
  bytesToSize,
  millisecondsToHoursTime,
  millisecondsToTimeSlots,
} from 'utils/helpers/helpers.js';
import { PipelineTableCellStyles } from 'Pages/Pipelines/libs/enums/enums.js';
import {
  DATE_FORMAT,
  FINISHED_ACTION_LABEL,
  FINISHED_ACTION_STATUS,
  RUNNING_ACTION_LABEL,
  RUNNING_ACTION_STATUS,
} from 'constants/constants.js';
import { BQ_BILLING_TYPE } from 'utils/constants.js';
import {
  SEARCH_TABLE,
  SELECTED_TABLE,
} from 'Pages/Pipelines/libs/constants/constants.js';

const { JOB_NAME_WIDTH, PRINCIPAL_EMAIL_WIDTH, LAST_ACTIVITY_CELL_WIDTH } =
  PipelineTableCellStyles;
const BASE_COL_SPAN = 4;

const useStyles = makeStyles((theme) => ({
  insideTable: {
    '& .MuiTableCell-root': {
      paddingTop: 0,
      paddingRight: 0,
      '&:first-of-type': {
        paddingLeft: 0,
      },
    },
  },
}));

const PipelineTableSourceRaw = ({
  destinationTable,
  sourceData,
  scrollToRef,
  onChangeIsScrollRefRender,
}) => {
  const { setModal } = useModal();
  const { currentBqBillingData } = useBqBillingData();
  const {
    searchValues,
    selectedJobHash,
    handleChangeJobHash,
    pipelineDetailsLink,
    onClickCopyLink,
  } = usePipelinePageContext();

  const {
    tables,
    jobStatus,
    lastJobName,
    lastStartTime,
    lastDuration,
    principalEmail,
    jobTags,
    avgBytes,
    lastBytes,
    avgSlotsMs,
    lastSlotsMs,
    sourceUri,
    frequency,
    routines,
    jobHash,
  } = sourceData;

  const isSlotsCellExists =
    currentBqBillingData?.paymentType !== BQ_BILLING_TYPE.ON_DEMANDS;
  const isBytesCellExists =
    currentBqBillingData?.paymentType === BQ_BILLING_TYPE.ON_DEMANDS;

  const sources = useMemo(() => {
    return [...tables, ...routines];
  }, [routines, tables]);

  useEffect(() => {
    if (selectedJobHash && jobHash === selectedJobHash) {
      setModal(() => (
        <PipelineDetailsModal
          destinationTable={destinationTable}
          sourceData={sourceData}
          pipelineDetailsLink={pipelineDetailsLink}
          onClickCopyLink={onClickCopyLink}
          onClose={() => handleChangeJobHash(null)}
        />
      ));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedJobHash]);

  useEffect(() => {
    if (scrollToRef) {
      onChangeIsScrollRefRender(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPipelineRawClick = () => {
    handleChangeJobHash(jobHash);
  };

  return (
    <TableRow
      ref={scrollToRef}
      onClick={onPipelineRawClick}
      className={clsx(jobHash === selectedJobHash && 'withBackground')}
    >
      <SourceTableCell
        sourceData={sources}
        sourceUri={sourceUri}
        selectedTable={searchValues[SELECTED_TABLE]}
        searchValue={searchValues[SEARCH_TABLE]}
      />

      <TableCell colSpan={BASE_COL_SPAN}>
        <InsideTable
          jobStatus={jobStatus}
          lastJobName={lastJobName}
          lastStartTime={lastStartTime}
          principalEmail={principalEmail}
          frequency={frequency}
          jobTags={jobTags}
          colSpan={BASE_COL_SPAN}
        />
      </TableCell>

      <TableCell align='right' className='nowrapText'>
        {lastDuration ? (
          millisecondsToHoursTime(lastDuration)
        ) : (
          <span className='txt-grey-13-500'>&mdash;</span>
        )}
      </TableCell>

      {isBytesCellExists && (
        <>
          <TableCell align='right' className='nowrapText'>
            {avgBytes ? (
              bytesToSize(avgBytes)
            ) : (
              <span className='txt-grey-13-500'>&mdash;</span>
            )}
          </TableCell>
          <TableCell align='right' className='nowrapText'>
            {lastBytes ? (
              bytesToSize(lastBytes)
            ) : (
              <span className='txt-grey-13-500'>&mdash;</span>
            )}
          </TableCell>
        </>
      )}

      {isSlotsCellExists && (
        <>
          <TableCell align='right' className='nowrapText'>
            {avgSlotsMs ? (
              millisecondsToTimeSlots(avgSlotsMs)
            ) : (
              <span className='txt-grey-13-500'>&mdash;</span>
            )}
          </TableCell>
          <TableCell align='right' className='nowrapText'>
            {lastSlotsMs ? (
              millisecondsToTimeSlots(lastSlotsMs)
            ) : (
              <span className='txt-grey-13-500'>&mdash;</span>
            )}
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

const InsideTable = ({
  jobStatus,
  lastJobName,
  lastStartTime,
  principalEmail,
  frequency,
  jobTags,
  colSpan,
}) => {
  const classes = useStyles();

  const jobStatusLabel = useMemo(() => {
    switch (jobStatus) {
      case RUNNING_ACTION_STATUS:
        return RUNNING_ACTION_LABEL;
      case FINISHED_ACTION_STATUS:
        return FINISHED_ACTION_LABEL;
      default:
        return 'N/A';
    }
  }, [jobStatus]);

  return (
    <Table className={classes.insideTable}>
      <TableBody>
        <TableRow className='noBorder'>
          <TableCell style={{ width: JOB_NAME_WIDTH }}>
            {jobStatusLabel}
            <OverflownText
              title={lastJobName}
              maxWidth={JOB_NAME_WIDTH}
              className='txt-grey-13-500'
            >
              {lastJobName}
            </OverflownText>
          </TableCell>

          <TableCell
            className='nowrapText'
            style={{ width: LAST_ACTIVITY_CELL_WIDTH }}
          >
            {!lastStartTime.length ? (
              <span className='txt-grey-13-500'>N/A</span>
            ) : (
              moment(lastStartTime).utc().format(DATE_FORMAT.monthYearTime)
            )}
          </TableCell>

          <TableCell style={{ width: PRINCIPAL_EMAIL_WIDTH }}>
            <OverflownText
              title={principalEmail}
              maxWidth={PRINCIPAL_EMAIL_WIDTH}
            >
              {principalEmail}
            </OverflownText>
          </TableCell>

          <TableCell align='right' className='nowrapText'>
            {frequency}
          </TableCell>
        </TableRow>

        {jobTags.length > 0 && (
          <TableRow className='noBorder'>
            <JobTagsTableCell jobTags={jobTags} colSpan={colSpan} />
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export { PipelineTableSourceRaw };
