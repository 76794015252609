import React from 'react';
import { useCallback } from 'hooks/hooks.js';
import clsx from 'clsx';
import { Image } from 'react-bootstrap';
import { useStyles } from './CostsWelcome.styles.js';
import CostsForm from './CostsForm/CostsForm.js';
import CostsImage from 'assets/img/page-examples/costs-example.png';

const CostsWelcome = ({ projects, bqBillingData }) => {
  const classes = useStyles();
  const isMultipleProjects = projects.length > 1;
  const bqBillingByProject = useCallback(
    (project) => {
      return bqBillingData?.values?.find((item) => item.project === project);
    },
    [bqBillingData]
  );
  let isOpenFlag = false;

  return (
    <div className={classes.mainContainer}>
      <div className={classes.largeBlock}>
        <div
          className={clsx(
            isMultipleProjects
              ? classes.multipleProjectContainer
              : classes.singleProjectContainer
          )}
        >
          <h1 className='txt-blue-24-700'>Welcome to Costs Insights</h1>
          <div className='txt-grey-13-500 mb-4'>
            Set up your BigQuery analysis pricing models so that you can start
            using costs insights.
          </div>
          <div className={clsx(isMultipleProjects && classes.formContainer)}>
            {projects?.map((item) => {
              const bqBillingItem = bqBillingByProject(item);
              let isOpen = false;
              if (!isOpenFlag && !bqBillingItem) {
                isOpenFlag = true;
                isOpen = true;
              }
              return (
                <CostsForm
                  key={item}
                  project={item}
                  bqBillingData={bqBillingItem}
                  isMultipleProjects={isMultipleProjects}
                  isDefaultOpen={isOpen}
                />
              );
            })}
          </div>
        </div>
      </div>

      <div className={classes.smallBlock}>
        <Image className={classes.image} src={CostsImage} />
      </div>
    </div>
  );
};

export { CostsWelcome };
