import { useMemo, useQuery } from 'hooks/hooks.js';
import { fetcherGet } from 'utils/utils.js';
import { QUERY_TYPES } from 'constants/constants.js';
import { useDateContext } from 'context/context.js';

const usePipelinesSubtypesData = ({ pipelineType, enabled }) => {
  const { startDateUtc, endDateUtc } = useDateContext();

  const { data } = useQuery(
    [QUERY_TYPES.jobPipelineSubtypes, startDateUtc, endDateUtc, pipelineType],
    ({ queryKey }) => {
      const [url, start, end, pipelineType] = queryKey;
      return fetcherGet(url, {
        from: start,
        to: end,
        pipelineType,
      });
    },
    { enabled }
  );

  const pipelineSubtypesData = useMemo(() => {
    return data?.values || [];
  }, [data?.values]);

  return { pipelineSubtypesData };
};

export { usePipelinesSubtypesData };
