import React from 'react';
import { useRef, useEffect, useMemo } from 'hooks/hooks.js';
import { Chart, ChartEvent } from '@antv/g2';
import clsx from 'clsx';
import { useStyles } from './Volume.style.js';
import {
  frequencyBySeconds,
  getUnixDateByStr,
  numberFormat,
} from 'utils/helpers/helpers.js';
import { getDatetimeFormat } from './utils.js';
import { GRAPH_HEIGHT } from 'constants/constants.js';
import { SHAPE_TYPES } from './constants.js';
import theme from 'theme.js';

const BASE_BLUE_COLOR = '#2F50FF';
const LINE_WIDTH = 2;
const MIN_DATA_LENGTH = 2;

const JobsExecutionGraph = ({
  data,
  frequencyMilliseconds,
  selectedDatetime,
  onChangeSelectedDate,
}) => {
  const classes = useStyles({ volumeHeight: GRAPH_HEIGHT.medium });
  const volumeContainerRef = useRef(null);
  const volumeRef = useRef(null);
  const volumeResizeObserverRef = useRef(null);

  useEffect(() => {
    return () => {
      if (volumeRef.current) {
        volumeRef.current.destroy();
        volumeRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (volumeRef.current) {
        volumeRef.current.forceFit();
        volumeRef.current.render();
      }
    };

    volumeResizeObserverRef.current = new ResizeObserver(() => {
      handleResize();
    });

    volumeResizeObserverRef.current.observe(volumeContainerRef.current);

    return () => {
      if (volumeResizeObserverRef.current && volumeContainerRef.current) {
        volumeResizeObserverRef.current.unobserve(volumeContainerRef.current);
      }
    };
  }, []);

  const getPointShape = () => {
    const options = volumeRef.current.options();
    const pointMark = options.children.find(
      (item) => item?.encode?.shape === SHAPE_TYPES.circle
    );

    if (pointMark) {
      return { datetime: pointMark.encode.x, value: pointMark.encode.y };
    }

    return null;
  };

  // Updates
  useEffect(() => {
    if (volumeRef.current && selectedDatetime) {
      const options = volumeRef.current.options();
      options.children = options.children.filter(
        (item) => item?.encode?.shape !== SHAPE_TYPES.circle
      );
      volumeRef.current.options({
        ...options,
      });

      volumeRef.current
        .point()
        .encode('x', new Date(selectedDatetime).getTime())
        .encode('y', (datum) =>
          datum.datetime === selectedDatetime ? datum.value : null
        )
        .encode('shape', SHAPE_TYPES.circle)
        .tooltip(false);

      volumeRef.current.render();
    }
  }, [selectedDatetime]);

  const isReadyChartsForRender = useMemo(() => {
    return data.length > 0 && !volumeRef.current && selectedDatetime !== null;
  }, [data.length, selectedDatetime]);

  // First render
  useEffect(() => {
    if (!isReadyChartsForRender) {
      return;
    }

    volumeRef.current = new Chart({
      container: volumeContainerRef.current,
      height: GRAPH_HEIGHT.medium,
      autoFit: true,
      paddingTop: 6,
      paddingBottom: 6,
      theme: {
        color: BASE_BLUE_COLOR,
      },
    });

    volumeRef.current
      .data(data)
      .encode('x', ({ datetime }) => new Date(datetime).getTime())
      .encode('y', 'value')
      .axis('x', {
        labelAutoRotate: false,
        labelFormatter: (data) => getDatetimeFormat(data),
        line: true,
        grid: null,
      })
      .axis('y', {
        title: false,
        labelFormatter: '~s',
        gridLineDash: null,
        gridStrokeWidth: 1,
        gridStroke: theme.palette.divider,
        gridStrokeOpacity: 0.75,
      });

    volumeRef.current
      .line()
      .style('lineWidth', LINE_WIDTH)
      .tooltip({
        title: ({ datetime }) => getDatetimeFormat(datetime),
        items: [
          ({ value }) => ({
            name: 'jobs count',
            value: numberFormat(value),
          }),
        ],
      });

    if (data.length >= MIN_DATA_LENGTH) {
      const lineDashData = data.slice(-2);
      volumeRef.current
        .line()
        .data(lineDashData)
        .style({
          lineWidth: LINE_WIDTH,
          stroke: theme.palette.common.white,
          lineDash: [5, 7],
        })
        .tooltip(false);
    }

    volumeRef.current.point().encode('shape', SHAPE_TYPES.point).tooltip(false);

    volumeRef.current
      .rangeX()
      .encode('x', (data) => {
        const startInterval =
          getUnixDateByStr(data.datetime) - frequencyMilliseconds / 2;

        return new Date(startInterval).getTime();
      })
      .encode('x1', (data) => {
        const endInterval =
          getUnixDateByStr(data.datetime) + frequencyMilliseconds / 2;

        return new Date(endInterval).getTime();
      })
      .style({
        fillOpacity: 0,
        fill: 'transparent',
      });

    volumeRef.current
      .point()
      .encode('x', new Date(selectedDatetime).getTime())
      .encode('y', (datum) =>
        datum.datetime === selectedDatetime ? datum.value : null
      )
      .encode('shape', SHAPE_TYPES.circle)
      .tooltip(false);

    volumeRef.current.interaction('tooltip', {
      marker: false,
    });

    volumeRef.current.on(`element:${ChartEvent.CLICK}`, (event) => {
      const eventData = event.data.data;

      if (eventData) {
        const currentPoint = getPointShape();
        if (currentPoint?.datetime === getUnixDateByStr(eventData.datetime)) {
          onChangeSelectedDate(null);
        } else {
          onChangeSelectedDate(eventData.datetime);
        }
      }
    });

    volumeRef.current.render();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReadyChartsForRender]);

  return (
    <div className={classes.volumeGraphContainer}>
      <div ref={volumeContainerRef} className={clsx(classes.graph, 'volume')} />
      <div className='greyContainer txt-grey-13-700'>
        aggregation step: {frequencyBySeconds(frequencyMilliseconds * 0.001)}
      </div>
    </div>
  );
};

export { JobsExecutionGraph };
