import { useBqBillingData, useMemo, useQuery } from 'hooks/hooks.js';
import { useDateContext } from 'context/context.js';
import { fetcherGet } from 'utils/utils.js';
import { BQ_BILLING_TYPE } from 'utils/constants.js';
import { JOB_METRIC_TYPES, QUERY_TYPES } from 'constants/constants.js';

const useJobsExecutionCount = ({ jobHash }) => {
  const { defaultStartDateUtc, defaultEndDateUtc } = useDateContext();
  const { currentBqBillingData } = useBqBillingData();
  const { paymentType } = currentBqBillingData || {};

  const metricType = useMemo(() => {
    if (paymentType) {
      return paymentType === BQ_BILLING_TYPE.ON_DEMANDS
        ? JOB_METRIC_TYPES.bytes
        : JOB_METRIC_TYPES.slots;
    }

    return null;
  }, [paymentType]);

  const { data, isFetching: isLoadingJobsExecutionCountData } = useQuery(
    [QUERY_TYPES.jobsCount, jobHash, metricType],
    ({ queryKey }) => {
      const [url, jobHash, metricType] = queryKey;
      return fetcherGet(url, {
        jobHash,
        metricType,
        from: defaultStartDateUtc,
        to: defaultEndDateUtc,
      });
    },
    {
      enabled: Boolean(jobHash && metricType),
      cacheTime: 0,
    }
  );

  const jobsExecutionCountData = useMemo(() => {
    return data?.values || [];
  }, [data?.values]);

  return { jobsExecutionCountData, isLoadingJobsExecutionCountData };
};

export { useJobsExecutionCount };
