import React from 'react';
import { TableCell } from '@mui/material';
import { PipelineTagsChips } from 'Components/components.js';
import { PipelineTableCellStyles } from 'Pages/Pipelines/libs/enums/enums.js';

const VISIBLE_ITEMS = 3;

const JobTagsTableCell = ({ jobTags = [], colSpan }) => {
  const { TAGS_CELL_WIDTH } = PipelineTableCellStyles;

  return (
    <TableCell colSpan={colSpan}>
      <PipelineTagsChips
        pipelineTags={jobTags}
        maxWidth={TAGS_CELL_WIDTH}
        visibleItems={VISIBLE_ITEMS}
      />
    </TableCell>
  );
};

export { JobTagsTableCell };
