import React from 'react';
import { useMemo } from 'hooks/hooks.js';
import { Image } from 'react-bootstrap';
import { useStyles } from '../../GlobalPageComponents/IncidentCard/IncidentCard.styles.js';
import { Chip, OverflownText, IconButton } from 'Components/components.js';
import {
  getAlertColorType,
  getAlertIcon,
  getAlertLabel,
  getPipelineIncidentTitle,
  getPipelineMetaData,
  handleCopyText,
} from 'utils/helpers/helpers.js';
import {
  MH_PIPELINE_PREFIX,
  PIPELINE_TYPE_MAP,
  PIPELINE_TYPE_STRING,
} from 'utils/constants.js';
import { ReactComponent as CopyIcon } from 'assets/img/icons/copyIcon.svg';

const PipelineIncidentTitle = ({ groupIncident }) => {
  const classes = useStyles();
  const { table, alertType, pipelineType, metricType, anomalyType } =
    groupIncident;

  const AlertIconComponent = getAlertIcon(groupIncident.alertType);
  const pipelineTypeToNumber = PIPELINE_TYPE_MAP[pipelineType];

  const isExistingDestinationTable = useMemo(() => {
    return Boolean(table && !table.includes(MH_PIPELINE_PREFIX));
  }, [table]);

  return (
    <section className={classes.incidentTitle}>
      {alertType ? (
        <Chip
          label={getAlertLabel(alertType)}
          color={getAlertColorType(alertType)}
          startIcon={<AlertIconComponent />}
          sx={{ mr: 2 }}
        />
      ) : null}

      {pipelineType && pipelineType !== PIPELINE_TYPE_STRING.BROWSER && (
        <Image
          src={getPipelineMetaData(pipelineTypeToNumber).icon}
          width={16}
          height={16}
          alt={pipelineType}
          className='me-1'
        />
      )}

      <span className='txt-mainDark-16-500'>
        {getPipelineIncidentTitle(anomalyType, metricType)}&nbsp;
      </span>

      {isExistingDestinationTable && (
        <>
          <OverflownText title={table} maxWidth={450}>
            <span className='txt-mainDark-16-500'>Destination table:</span>
            <span className='txt-mainDark-16-700'>&nbsp;{table}</span>
          </OverflownText>
          <IconButton
            icon={<CopyIcon />}
            onClick={() => handleCopyText(table)}
          />
        </>
      )}
    </section>
  );
};

export { PipelineIncidentTitle };
