const PipelineTabSearchOptions = {
  TABLE: 'Table',
  DESTINATION: 'Destination',
  EMAIL: 'Principal email',
  TAGS: 'Pipeline tags / labels',
  LABELS: 'Table labels',
  POWER_BI_REPORT: 'Power BI report',
  POWER_BI_WORKSPACE: 'Power BI workspace',
};

export { PipelineTabSearchOptions };
