import React from 'react';
import {
  useState,
  useTableLabels,
  useCostsSourceTables,
  useBqBillingData,
  useEffect,
  useRef,
} from 'hooks/hooks.js';
import clsx from 'clsx';
import { TableCell, TableRow } from '@mui/material';
import { Chip, CHIP_COLOR, TableLabelsChips } from 'Components/components.js';
import {
  CostLabel,
  DestinationTableCell,
  LoadingTableRaw,
} from 'Pages/Pipelines/components/components';
import { ResourceTableSourceRaw } from './ResourceTableSourceRaw.js';
import { usePipelinePageContext } from 'context/context.js';
import {
  getAlertColorType,
  getAlertLabel,
  getPipelineMetaData,
} from 'utils/helpers/helpers.js';
import { BQ_BILLING_TYPE } from 'utils/constants.js';

const ResourceTableRows = ({ destinationTable, selectedTag }) => {
  const scrollToRef = useRef(null);
  const [isScrollRefRender, setIsScrollRefRender] = useState(false);
  const [sourcesPage, setSourcesPage] = useState(0);
  const [sourcesData, setSourcesData] = useState([]);
  const { currentBqBillingData } = useBqBillingData();
  const { defaultJobHash } = usePipelinePageContext();
  const isExistingReservationCell =
    currentBqBillingData?.paymentType !== BQ_BILLING_TYPE.ON_DEMANDS;
  const [isOpenTableRaw, setIsOpenTableRaw] = useState(
    destinationTable?.isSelected
  );

  const { sources, isFetchingSources, sourcesCount } = useCostsSourceTables({
    dataset: destinationTable.dataset,
    table: destinationTable.table,
    jobTagNames: [selectedTag],
    pipelineType: destinationTable?.pipelineType,
    project: destinationTable?.project,
    page: sourcesPage,
    jobHash: defaultJobHash,
    enabled: isOpenTableRaw,
  });

  const { tableLabels } = useTableLabels(
    destinationTable?.table,
    destinationTable?.dataset
  );

  useEffect(() => {
    setSourcesData((prevState) => {
      const newSources = sources.filter(
        (source) =>
          !prevState.some(
            (existingSource) => existingSource.jobHash === source.jobHash
          )
      );
      return [...prevState, ...newSources];
    });
  }, [sources]);

  useEffect(() => {
    if (scrollToRef.current && isScrollRefRender && defaultJobHash) {
      scrollToRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [isScrollRefRender, defaultJobHash]);

  const handleChangeOpen = () => {
    setIsOpenTableRaw((prevState) => !prevState);
  };

  const handleSetNextPage = () => {
    setSourcesPage((prevState) => prevState + 1);
  };

  const handleSetIsScrollRefRender = (value) => {
    setIsScrollRefRender(value);
  };

  return (
    <>
      <TableRow
        onClick={handleChangeOpen}
        className={clsx(
          isOpenTableRaw && 'isHiddenBottomBorder',
          'styledTableRow'
        )}
      >
        <DestinationTableCell
          isOpen={isOpenTableRaw}
          tableInfo={destinationTable}
          icon={getPipelineMetaData(destinationTable?.pipelineType).icon}
        />
        <TableCell
          align='left'
          sx={{ alignContent: 'baseline' }}
          colSpan={isExistingReservationCell ? 4 : 3}
        >
          <div className='cellContentWithIcon withLargeGap'>
            <Chip
              label={getAlertLabel(destinationTable?.alertType)}
              color={getAlertColorType(destinationTable?.alertType)}
            />

            {destinationTable?.isDeadEnd && (
              <Chip label='Dead-end' color={CHIP_COLOR.error} />
            )}

            {tableLabels.length > 0 && (
              <TableLabelsChips tableLabels={tableLabels} />
            )}
          </div>
        </TableCell>
        <TableCell align='right'>
          <div className='txt-mainDark-13-700'>
            <CostLabel value={destinationTable?.costs} />
          </div>
        </TableCell>
      </TableRow>

      {isOpenTableRaw && (
        <>
          {sourcesData.map((source) => (
            <ResourceTableSourceRaw
              key={source.jobHash}
              sourceData={source}
              destinationTable={destinationTable}
              scrollToRef={
                defaultJobHash === source.jobHash ? scrollToRef : null
              }
              onChangeIsScrollRefRender={handleSetIsScrollRefRender}
            />
          ))}
          {isFetchingSources && <LoadingTableRaw />}
          {sourcesCount > sourcesData.length && (
            <TableRow onClick={handleSetNextPage}>
              <TableCell colSpan={7}>
                <div className='text-center txt-blue-13-700'>Show more</div>
              </TableCell>
            </TableRow>
          )}
        </>
      )}
    </>
  );
};

export { ResourceTableRows };
