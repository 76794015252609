import React from 'react';
import { useAutoChangeProject, useEffect } from 'hooks/hooks.js';
import { PageHeader, Pagination, Tabs } from 'Components/components.js';
import { CostsTab, PipelineTab, ResourceTab } from './components/components.js';
import {
  PipelinePageContextProvider,
  usePipelinePageContext,
  useUserInfo,
} from 'context/context.js';
import {
  DAYS_LIMIT,
  DOCUMENT_TITLE,
  PipelineTabs,
} from 'constants/constants.js';

const DAYS_LIMIT_FOR_YALO = 90;
const YALO_TENANT = 'yalo-n8gj5';

const PipelinesContainer = () => {
  return (
    <PipelinePageContextProvider>
      <Pipelines />
    </PipelinePageContextProvider>
  );
};

const Pipelines = () => {
  const {
    currentTab,
    handleChangeTab,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    paginationTotal,
  } = usePipelinePageContext();

  const { user } = useUserInfo();
  const isYaloTenant =
    user.info?.tenant === YALO_TENANT ||
    user.info?.adminData?.currentTenant === YALO_TENANT;

  useAutoChangeProject();

  useEffect(() => {
    document.title = DOCUMENT_TITLE.pipelines;
  }, []);

  const hasDateRangePicker = currentTab !== PipelineTabs.PIPELINE_INFO;
  const rangePickerDaysLimit = isYaloTenant
    ? DAYS_LIMIT_FOR_YALO
    : DAYS_LIMIT.month;

  const renderPage = () => {
    switch (currentTab) {
      case PipelineTabs.PIPELINE_INFO:
        return <PipelineTab />;
      case PipelineTabs.RESOURCE_ALLOCATION:
        return <ResourceTab />;
      default:
        return <CostsTab />;
    }
  };

  return (
    <>
      <PageHeader
        hasDateRangePicker={hasDateRangePicker}
        daysLimit={rangePickerDaysLimit}
      >
        Pipelines
      </PageHeader>

      <Tabs
        tabs={PipelineTabs}
        currentTab={currentTab}
        onTabClick={handleChangeTab}
      />

      {renderPage()}

      <Pagination
        count={paginationTotal}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};

export default PipelinesContainer;
