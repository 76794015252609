import { TABLE_TYPES, ROUTINE_TYPE } from 'constants/constants.js';
import { ReactComponent as ExternalIcon } from 'assets/img/icons/lineageIcons/node-icons/external-table.svg';
import { ReactComponent as ViewIcon } from 'assets/img/icons/lineageIcons/node-icons/view.svg';
import { ReactComponent as DbTableIcon } from 'assets/img/icons/lineageIcons/node-icons/table.svg';
import { ReactComponent as RoutineIcon } from 'assets/img/icons/lineageIcons/node-icons/routine.svg';
import { ReactComponent as PartitionIcon } from 'assets/img/icons/projectTreeIcons/partition.svg';

const getReferencedType = (type) => {
  switch (type) {
    case TABLE_TYPES.external:
      return { icon: ExternalIcon };
    case TABLE_TYPES.view:
      return { icon: ViewIcon };
    case TABLE_TYPES.dbTable:
    case TABLE_TYPES.bqTable:
    case TABLE_TYPES.sharded:
      return { icon: DbTableIcon };
    case TABLE_TYPES.partition:
      return { icon: PartitionIcon };
    case ROUTINE_TYPE.routine:
      return { icon: RoutineIcon };
    default:
      return { icon: DbTableIcon };
  }
};

export { getReferencedType };
