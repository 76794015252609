import React from 'react';
import {
  useCallback,
  useEffect,
  useHistory,
  useState,
  useMutation,
  useQueryClient,
  useSyncTableToSearchParam,
  useMemo,
  useAutoChangeProject,
  useLocation,
  useBqBillingData,
} from 'hooks/hooks.js';
import { Grid } from '@mui/material';
import { useStyles } from './Monitors.styles.js';
import {
  PageHeader,
  AnomaliesWidget,
  ErrorsWidget,
  DataScanWidget,
  ReportsWidget,
  TableInfoWidget,
  SchemaWidget,
  LineageWidget,
  ViewQueryWidget,
} from './components/components.js';
import {
  AMPL_PAGE_EVENT,
  TABLE_TYPES,
  QUERY_TYPES,
  ANOMALY_TYPE,
  INCIDENT_TYPE_GROUP,
  INCIDENT_TYPE,
  ALERT_SEVERITY,
  DOCUMENT_TITLE,
} from 'constants/constants.js';
import { EMPTY_DATA_INFO, EXISTING_DATA_TYPES } from './libs/constants.js';
import { fetcherPost } from 'utils/utils.js';
import {
  LatestTable,
  SourcesButton,
  EmptyBlock,
  TableDatasetSearch,
} from 'Components/components.js';
import { getFullTableName, getWidgetLabel } from 'utils/helpers/helpers.js';
import { useMessages, useProjectTree } from 'context/context.js';
import { amplEvent } from 'service/services.js';
import { BQ_BILLING_TYPE } from 'utils/constants.js';

const DEFAULT_EXISTING_DATA = {
  [EXISTING_DATA_TYPES.volume]: true,
  [EXISTING_DATA_TYPES.freshness]: true,
  [EXISTING_DATA_TYPES.pipelineErrors]: true,
  [EXISTING_DATA_TYPES.reports]: true,
  [EXISTING_DATA_TYPES.lineage]: true,
  [EXISTING_DATA_TYPES.pipelineIncidents]: true,
};

export default function Monitors() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { setMessage, updatePosition } = useMessages();
  const queryClient = useQueryClient();
  const [tableInfo, setTableInfo] = useState(null);
  const { tableFromURL, tableInfoFromUrl, clearSearchParams } =
    useSyncTableToSearchParam(tableInfo?.table, tableInfo?.dataset, null);
  const [startPage, setStartPage] = useState(tableFromURL === null);
  const { setIsOpen, setSelectTable, setOnSelectTable, setSelectDataset } =
    useProjectTree();
  const { volume, freshness, pipelineErrors, pipelineIncidents } =
    getWidgetLabel(tableInfo?.type);
  const { currentBqBillingData } = useBqBillingData();

  const pipelineIncidentGroupTypes =
    currentBqBillingData?.paymentType !== undefined
      ? currentBqBillingData?.paymentType === BQ_BILLING_TYPE.ON_DEMANDS
        ? [
            INCIDENT_TYPE_GROUP.PIPELINE_FRESHNESS_BILLED_BYTES,
            INCIDENT_TYPE_GROUP.PIPELINE_VOLUME_BILLED_BYTES,
          ]
        : [
            INCIDENT_TYPE_GROUP.PIPELINE_FRESHNESS_SLOTS_MS,
            INCIDENT_TYPE_GROUP.PIPELINE_VOLUME_SLOTS_MS,
          ]
      : null;

  const [existingData, setExistingData] = useState(DEFAULT_EXISTING_DATA);

  useAutoChangeProject();

  const isVisibleWidget = useMemo(() => {
    return {
      volume: tableInfo !== null && tableInfo?.type !== TABLE_TYPES.view,
      freshness: tableInfo !== null && tableInfo?.type !== TABLE_TYPES.view,
      viewQuery: tableInfo !== null && tableInfo?.type === TABLE_TYPES.view,
      pipelineIncidents:
        tableInfo !== null && tableInfo?.type !== TABLE_TYPES.view,
    };
  }, [tableInfo]);

  const handleChangeExistingData = useCallback((key, value) => {
    setExistingData((prev) => ({ ...prev, [key]: value }));
  }, []);

  const isEmptyTableData = useMemo(() => {
    return (
      !existingData[EXISTING_DATA_TYPES.lineage] &&
      !existingData[EXISTING_DATA_TYPES.freshness] &&
      !existingData[EXISTING_DATA_TYPES.volume] &&
      !existingData[EXISTING_DATA_TYPES.pipelineErrors] &&
      !existingData[EXISTING_DATA_TYPES.reports] &&
      !existingData[EXISTING_DATA_TYPES.pipelineIncidents]
    );
  }, [existingData]);

  const isInactiveTables = useMemo(() => {
    return (
      existingData[EXISTING_DATA_TYPES.lineage] &&
      !existingData[EXISTING_DATA_TYPES.freshness] &&
      !existingData[EXISTING_DATA_TYPES.volume] &&
      !existingData[EXISTING_DATA_TYPES.pipelineErrors] &&
      !existingData[EXISTING_DATA_TYPES.reports] &&
      !existingData[EXISTING_DATA_TYPES.pipelineIncidents]
    );
  }, [existingData]);

  const isEmptyLineage = useMemo(() => {
    return (
      !existingData[EXISTING_DATA_TYPES.lineage] &&
      (existingData[EXISTING_DATA_TYPES.freshness] ||
        existingData[EXISTING_DATA_TYPES.volume] ||
        existingData[EXISTING_DATA_TYPES.pipelineErrors])
    );
  }, [existingData]);

  useEffect(() => {
    document.title = DOCUMENT_TITLE.monitors;
    amplEvent(AMPL_PAGE_EVENT.monitors);
  }, []);

  const { mutateAsync: updateLastSeenTables } = useMutation(
    (params) => {
      return fetcherPost('/api/v1/last-tables', params);
    },
    {
      onSuccess: (data) => {
        if (data) {
          queryClient.invalidateQueries(QUERY_TYPES.lastSeenPages);
        }
      },
    }
  );

  useEffect(() => {
    if (location?.state?.navigateFromEmptyIncident) {
      updatePosition({ horizontal: 'center', vertical: 'bottom' });
      setMessage(
        'The incident was recalculated and no longer actual. The process is running well!',
        ALERT_SEVERITY.success
      );

      delete location.state.navigateFromEmptyIncident;
      history.replace({ ...history.location, state: location.state });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state]);

  useEffect(() => {
    if (tableFromURL === null) {
      setStartPage(true);
      setTableInfo(null);
      setSelectTable(null);
      setSelectDataset(null);
      setIsOpen(true);
    } else {
      setStartPage(false);
      setIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFromURL]);

  useEffect(() => {
    return () => setIsOpen(false);
  }, [setIsOpen]);

  useEffect(() => {
    if (tableInfo?.table && tableInfo?.dataset) {
      updateLastSeenTables({
        name: tableInfo?.table,
        dataset: tableInfo?.dataset,
        category: 'MONITORS',
      });

      amplEvent(
        `${AMPL_PAGE_EVENT.monitors} -> selected table -> ${getFullTableName(
          tableInfo.table,
          tableInfo.dataset
        )}`
      );
    }
  }, [tableInfo?.table, tableInfo?.dataset, updateLastSeenTables]);

  const handleChange = useCallback((value) => {
    setTableInfo(value);
    setExistingData(DEFAULT_EXISTING_DATA);
    setSelectTable(value);
    setStartPage(!value);
    if (value === null) clearSearchParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (handleChange !== undefined) {
      setOnSelectTable({ onChange: handleChange });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleChange]);

  const handleProjectTree = () => setIsOpen((prev) => !prev);

  const getLinkPath = (pathname, searchParams = {}) => {
    const baseParams = {
      dataset: tableInfo?.dataset,
      table: tableInfo?.table,
    };
    const queryParams = { ...baseParams, ...searchParams };

    return {
      pathname,
      search: new URLSearchParams(queryParams).toString(),
    };
  };

  return (
    <>
      <div className={classes.searchContainer}>
        <SourcesButton onClick={handleProjectTree} />
        <TableDatasetSearch
          optionLabel='Monitors for:'
          onTableChange={handleChange}
          defaultDataset={tableInfoFromUrl?.dataset}
          defaultTable={tableInfoFromUrl?.table}
          className='tableSearch'
          disableDatasets={true}
          withSearch={false}
        />
      </div>
      {startPage ? (
        <LatestTable onChange={handleChange} seenCategory='MONITORS' />
      ) : (
        <>
          <PageHeader tableInfo={tableInfo} onChangeTableInfo={handleChange} />

          <main className={classes.content}>
            {isEmptyTableData && (
              <EmptyBlock
                children={EMPTY_DATA_INFO.emptyTableData}
                className={classes.emptyBlock}
              />
            )}

            {isInactiveTables && (
              <EmptyBlock
                children={EMPTY_DATA_INFO.emptyTableAnomalies}
                className={classes.emptyBlock}
              />
            )}

            <Grid
              container
              direction='row'
              justifyContent='flex-start'
              alignItems='flex-start'
              spacing={4}
            >
              <Grid container item md={8} spacing={4}>
                {isVisibleWidget.viewQuery && (
                  <ViewQueryWidget tableInfo={tableInfo} />
                )}

                {isVisibleWidget.volume && (
                  <AnomaliesWidget
                    anomalyType={ANOMALY_TYPE.VOLUME}
                    label={volume.title}
                    secondaryLabel={volume.subtitle}
                    tableInfo={tableInfo}
                    incidentGroupsType={
                      INCIDENT_TYPE_GROUP.BQ_VOLUME_INSERTED_ROWS
                    }
                    isExisting={existingData[EXISTING_DATA_TYPES.volume]}
                    onChangeExistingData={handleChangeExistingData}
                    getLinkPath={getLinkPath}
                  />
                )}

                {isVisibleWidget.freshness && (
                  <AnomaliesWidget
                    anomalyType={ANOMALY_TYPE.FRESHNESS}
                    label={freshness.title}
                    secondaryLabel={freshness.subtitle}
                    tableInfo={tableInfo}
                    incidentGroupsType={
                      INCIDENT_TYPE_GROUP.BQ_FRESHNESS_INSERTED_ROWS
                    }
                    isExisting={existingData[EXISTING_DATA_TYPES.freshness]}
                    onChangeExistingData={handleChangeExistingData}
                    getLinkPath={getLinkPath}
                  />
                )}

                <ErrorsWidget
                  incidentType={INCIDENT_TYPE.LOG}
                  label={pipelineErrors.title}
                  secondaryLabel={pipelineErrors.subtitle}
                  tableInfo={tableInfo}
                  incidentGroupsTypes={[INCIDENT_TYPE_GROUP.LOG_PIPELINE_ERROR]}
                  isExisting={existingData[EXISTING_DATA_TYPES.pipelineErrors]}
                  onChangeExistingData={handleChangeExistingData}
                  getLinkPath={getLinkPath}
                />

                {isVisibleWidget.pipelineIncidents && (
                  <ErrorsWidget
                    incidentType={INCIDENT_TYPE.PIPELINE}
                    label={pipelineIncidents.title}
                    secondaryLabel={pipelineIncidents.subtitle}
                    tableInfo={tableInfo}
                    incidentGroupsTypes={pipelineIncidentGroupTypes}
                    isExisting={
                      existingData[EXISTING_DATA_TYPES.pipelineIncidents]
                    }
                    onChangeExistingData={handleChangeExistingData}
                    getLinkPath={getLinkPath}
                  />
                )}

                <DataScanWidget tableInfo={tableInfo} />
              </Grid>

              <Grid container item md={4} rowSpacing={4}>
                <ReportsWidget
                  table={tableInfo?.table}
                  dataset={tableInfo?.dataset}
                  getLinkPath={getLinkPath}
                  onChangeExistingData={handleChangeExistingData}
                />

                <LineageWidget
                  table={tableInfo?.table}
                  dataset={tableInfo?.dataset}
                  getLinkPath={getLinkPath}
                  onChangeExistingData={handleChangeExistingData}
                  isEmptyLineage={isEmptyLineage}
                />

                <TableInfoWidget tableInfo={tableInfo} />
                <SchemaWidget tableInfo={tableInfo} />
              </Grid>
            </Grid>
          </main>
        </>
      )}
    </>
  );
}
