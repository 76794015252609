import {
  useGetProjects,
  useQueries,
  useState,
  useEffect,
  useMemo,
} from 'hooks/hooks.js';
import { useDateContext } from 'context/context.js';
import { fetcherGet } from 'utils/utils.js';
import { formatSearchValue, getIsValidUUID } from 'utils/helpers/helpers.js';
import { QUERY_TYPES } from 'constants/constants.js';

const LIMIT = 500;

const useAllCostsSourceTables = ({
  dataset,
  table,
  sourceTable,
  sourceDataset,
  searchValue,
  principalEmail,
  searchPrincipalEmail,
  jobTagNames,
  powerbiReportName,
  powerbiWorkspaceName,
  pipelineType,
  pipelineSubtypes,
  page,
  jobHash,
  limit = LIMIT,
  isAlternativePlanSelected = false,
  enabled = true,
}) => {
  const { projects, isLoadingProjects } = useGetProjects();
  const { defaultStartDateUtc, defaultEndDateUtc } = useDateContext();
  const isValidJobHash = getIsValidUUID(jobHash);
  const [isFetchingSources, setIsFetchingSources] = useState(true);
  const [sources, setSources] = useState([]);

  const enabledQuery = useMemo(() => {
    return (
      enabled &&
      Boolean(
        table && dataset && pipelineType !== undefined && !isLoadingProjects
      )
    );
  }, [dataset, enabled, isLoadingProjects, pipelineType, table]);

  const queries = projects.map((project) => {
    return {
      queryKey: [
        QUERY_TYPES.jobSourcesCost,
        dataset,
        table,
        defaultStartDateUtc,
        defaultEndDateUtc,
        sourceTable,
        sourceDataset,
        searchValue,
        principalEmail,
        searchPrincipalEmail,
        jobTagNames,
        powerbiReportName,
        powerbiWorkspaceName,
        pipelineType,
        pipelineSubtypes,
        project,
        page,
        limit,
        isAlternativePlanSelected,
      ],
      queryFn: ({ queryKey }) => {
        const [url, dataset, table, start, end] = queryKey;
        const { dataset: newSearchSourceDataset, value: newSearchSourceValue } =
          formatSearchValue(searchValue);
        const params = new URLSearchParams();

        params.append('dataset', dataset);
        params.append('table', table);
        params.append('from', start);
        params.append('to', end);
        params.append('sourceTable', sourceTable || '');
        params.append(
          'sourceDataset',
          newSearchSourceDataset || sourceDataset || ''
        );
        params.append(
          'sourceSearch',
          newSearchSourceValue || searchValue || ''
        );
        params.append('principalEmail', principalEmail || '');
        params.append('searchPrincipalEmail', searchPrincipalEmail || '');
        powerbiReportName?.length > 0 &&
          params.append('powerbiReportName', powerbiReportName);
        powerbiWorkspaceName?.length > 0 &&
          params.append('powerbiWorkspaceName', powerbiWorkspaceName);
        params.append('pipelineType', pipelineType);
        params.append('onlyAltPlanSavings', isAlternativePlanSelected);
        params.append('page', page + 1);
        params.append('limit', limit.toString());

        project && params.append('project', project);
        isValidJobHash && params.append('jobHash', jobHash);

        pipelineSubtypes?.forEach((pipelineSubtype) =>
          params.append('pipelineSubtypes', pipelineSubtype)
        );
        jobTagNames?.forEach((tag) => params.append('jobTagNames', tag));

        return fetcherGet(url, params);
      },
      enabled: enabledQuery,
    };
  });

  const queriesResult = useQueries(queries);
  const isQueriesSuccess =
    queriesResult.every((query) => query.isSuccess) && queriesResult.length > 0;

  useEffect(() => {
    if (isQueriesSuccess) {
      setSources(
        queriesResult.reduce((acc, curr) => {
          if (!curr?.data?.values) {
            return acc;
          }
          return [...acc, ...curr.data.values];
        }, [])
      );
      setIsFetchingSources(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isQueriesSuccess]);

  return { sources, isFetchingSources };
};

export { useAllCostsSourceTables };
