import { getFullTableName } from 'utils/helpers/helpers.js';

const Highlight = ({ searchTable, searchValue, value }) => {
  if (!searchValue && !searchTable) {
    return value;
  }

  if (searchTable) {
    const tableSearchValue = getFullTableName(
      searchTable.table,
      searchTable.dataset
    ).toLowerCase();
    if (tableSearchValue === value.toLowerCase()) {
      return <span className='highlight'>{value}</span>;
    }
    return value;
  }
  const escapedSearchValue = searchValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const regExp = new RegExp(escapedSearchValue, 'ig');
  const matchValues = value.match(regExp);

  if (matchValues) {
    return value.split(regExp).map((subString, index, array) => {
      if (index < array.length - 1) {
        const result = matchValues.shift();
        return (
          <span key={subString + index}>
            {subString}
            <span className='highlight'>{result}</span>
          </span>
        );
      }

      return subString;
    });
  }
  return value;
};

export { Highlight };
