import React from 'react';
import { useMemo, useQueryClient } from 'hooks/hooks.js';
import { Image } from 'react-bootstrap';
import { makeStyles } from '@mui/styles';
import { Button, BUTTON_VARIANT } from 'Components/components.js';
import {
  connectDataPlex,
  connectDataPlexPost,
} from 'Pages/Integrations/libs/helpers/helpers.js';
import { getGcpDataplexApiLink } from 'utils/helpers/helpers.js';
import { useUserInfo, useMessages } from 'context/context.js';
import { ReactComponent as DataplexLogo } from 'assets/img/dataplex-logo.svg';
import ExampleImage from 'assets/img/page-examples/dataplex-example.png';

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    position: 'relative',
    display: 'flex',
    height: '100%',
    borderRadius: 12,
  },
  largeBlock: {
    display: 'flex',
    alignItems: 'center',
    padding: '32px 24px',
    backgroundColor: theme.palette.common.white,
    flexGrow: 1,
    borderTopLeftRadius: 12,
    borderBottomLeftRadius: 12,
  },
  contentContainer: {
    width: 572,
  },
  smallBlock: {
    width: '38%',
    backgroundColor: theme.palette.secondary.main,
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
  },
  image: {
    position: 'absolute',
    top: '50%',
    right: 0,
    width: '46%',
    transform: 'translateY(-50%)',
  },
  link: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

const StartDataplex = ({ onLoadingChange, isDataplexApiDisabled }) => {
  const classes = useStyles();
  const { user, currentProject } = useUserInfo();
  const { setMessage } = useMessages();
  const queryClient = useQueryClient();

  const gcpDataplexApiLink = useMemo(() => {
    return getGcpDataplexApiLink(currentProject);
  }, [currentProject]);

  const content = useMemo(() => {
    if (isDataplexApiDisabled) {
      return {
        title: 'Additional Setup Required for Dataplex',
        text: (
          <>
            Please ensure you have the necessary permissions and that Dataplex
            API is enabled in {currentProject}.{' '}
            <a
              href={gcpDataplexApiLink}
              target='_blank'
              rel='noreferrer'
              className={classes.link}
            >
              Check Dataplex settings
            </a>{' '}
            and then, click 'Reconnect' to re-establish the connection.
          </>
        ),
        buttonText: 'Reconnect with OAuth2',
        onClickButton: () =>
          connectDataPlex(user, queryClient, setMessage, onLoadingChange),
        extraButtons: [
          {
            buttonText: 'Reconnect',
            onClickButton: () => connectDataPlexPost(queryClient, setMessage),
          },
        ],
      };
    }

    return {
      title: 'Welcome to Data Quality',
      text: (
        <>
          Automate data quality checks across distributed data by connecting
          Dataplex. Ensure you have the necessary GCP permissions. If Dataplex
          hasn't been used in {currentProject} before, enable{' '}
          <a
            href={gcpDataplexApiLink}
            target='_blank'
            rel='noreferrer'
            className={classes.link}
          >
            the Dataplex API
          </a>{' '}
          before connecting to Masthead to set up Data Quality checks.
        </>
      ),
      buttonText: 'Create and connect with OAuth2',
      onClickButton: () =>
        connectDataPlex(user, queryClient, setMessage, onLoadingChange),
      extraButtons: [
        {
          buttonText: 'Connect to project',
          onClickButton: () => connectDataPlexPost(queryClient, setMessage),
        },
      ],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject, gcpDataplexApiLink, isDataplexApiDisabled]);

  return (
    <div className={classes.mainContainer}>
      <div className={classes.largeBlock}>
        <div className={classes.contentContainer}>
          <DataplexLogo />
          <h1 className='txt-blue-24-700 mt-2 mb-2'>{content.title}</h1>
          <div className='txt-grey-13-500 mb-4'>{content.text}</div>
          <Button
            text={content.buttonText}
            fullWidth={false}
            onClick={content.onClickButton}
          />
          {content.extraButtons?.map((button, index) => (
            <Button
              key={index}
              text={button.buttonText}
              variant={BUTTON_VARIANT.text}
              onClick={button.onClickButton}
              fullWidth={false}
              className='ms-3'
            />
          ))}
        </div>
      </div>

      <div className={classes.smallBlock}>
        <Image className={classes.image} src={ExampleImage} />
      </div>
    </div>
  );
};

export { StartDataplex };
