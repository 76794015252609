const RUNNING_ACTION_STATUS = 'RUNNING';
const RUNNING_ACTION_LABEL = 'Running';
const FINISHED_ACTION_STATUS = 'FINISHED';
const FINISHED_ACTION_LABEL = 'Finished';

const PIPELINE_STATUS_FILTER_OPTIONS = [
  { value: '', label: 'All statuses' },
  { value: RUNNING_ACTION_STATUS, label: RUNNING_ACTION_LABEL },
  { value: FINISHED_ACTION_STATUS, label: FINISHED_ACTION_LABEL },
];

const LAST_1_HOUR = 'last_1_hour';
const LAST_12_HOURS = 'last_12_hours';
const LAST_DAY = 'last_day';
const LAST_7_DAYS = 'last_7_days';

const PIPELINE_TIME_PERIOD_OPTIONS = [
  {
    value: LAST_1_HOUR,
    label: 'Last 1 hour',
  },
  {
    value: LAST_12_HOURS,
    label: 'Last 12 hours',
  },
  {
    value: LAST_DAY,
    label: 'Last 1 day',
  },
  {
    value: LAST_7_DAYS,
    label: 'Last 7 days',
  },
];

const BILLED_BYTES_METRIC = 'BILLED_BYTES';
const SLOTS_MS_METRIC = 'SLOTS_MS';
const DURATION_METRIC = 'DURATION';

const JOB_METRIC_TYPES = {
  bytes: BILLED_BYTES_METRIC,
  slots: SLOTS_MS_METRIC,
  duration: DURATION_METRIC,
};

const DEAD_END_TABLES = 'DEAD_END';
const ALTERNATIVE_PLAN_OPTION = 'ALTERNATIVE_PLAN';

const COST_EFFICIENCY_FILTER_OPTIONS = [
  { value: '', label: 'All cost efficiency' },
  { value: DEAD_END_TABLES, label: 'Dead-end tables' },
];

export {
  PIPELINE_STATUS_FILTER_OPTIONS,
  PIPELINE_TIME_PERIOD_OPTIONS,
  RUNNING_ACTION_STATUS,
  FINISHED_ACTION_STATUS,
  RUNNING_ACTION_LABEL,
  FINISHED_ACTION_LABEL,
  JOB_METRIC_TYPES,
  COST_EFFICIENCY_FILTER_OPTIONS,
  DEAD_END_TABLES,
  ALTERNATIVE_PLAN_OPTION,
  LAST_1_HOUR,
  LAST_12_HOURS,
  LAST_DAY,
  LAST_7_DAYS,
};
