import { useQuery, useState, useEffect, useMemo } from 'hooks/hooks.js';
import moment from 'moment';
import { useDateContext } from 'context/context.js';
import { convertLocalToUTCDate } from 'utils/helpers/helpers.js';
import { fetcherGet } from 'utils/utils.js';
import { QUERY_TYPES } from 'constants/constants.js';

const usePipelineStats = (startDate, endDate) => {
  const [isLoadingStats, setIsLoadingStats] = useState(false);
  const [pipelineStats, setPipelineStats] = useState([]);

  const {
    isFetching,
    data,
    refetch: refetchPipelineStats,
  } = useQuery(
    [QUERY_TYPES.jobProjectPipeline, startDate, endDate],
    ({ queryKey }) => {
      const [url, start, end] = queryKey;
      return fetcherGet(url, {
        from: moment(start).utc().format(),
        to: moment(convertLocalToUTCDate(end)).utc().format(),
      });
    },
    { enabled: Boolean(startDate && endDate) }
  );

  useEffect(() => {
    setIsLoadingStats(isFetching);

    if (data?.values.length > 0) {
      setPipelineStats(data.values);
    }
  }, [data?.values, isFetching]);

  return { isLoadingStats, pipelineStats, refetchPipelineStats };
};

const usePipelineTableStats = ({ dataset, table }) => {
  const { startDateUtc, endDateUtc } = useDateContext();

  const { isFetching, data } = useQuery(
    [QUERY_TYPES.pipelineTableStats, dataset, table, startDateUtc, endDateUtc],
    ({ queryKey }) => {
      const [url, dataset, table, start, end] = queryKey;
      return fetcherGet(url, {
        dataset,
        table,
        from: start,
        to: end,
      });
    },
    { enabled: Boolean(table && dataset && startDateUtc && endDateUtc) }
  );

  const pipelineTableStats = useMemo(() => {
    return data?.value?.pipelines || 0;
  }, [data?.value]);

  return { isFetching, pipelineTableStats };
};

export { usePipelineStats, usePipelineTableStats };
