import { TableRow } from '@mui/material';
import { styled } from '@mui/styles';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&.MuiTableRow-root': {
    verticalAlign: 'top',
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&.noBorder': {
      borderBottom: 'none',
    },
  },
  '&:nth-of-type(2n)': {
    backgroundColor: theme.palette.secondary.light,
  },
}));

export { StyledTableRow };
