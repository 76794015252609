import React from 'react';
import moment from 'moment';
import { Table, TableBody, TableContainer, TableHead } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  OverflownText,
  Pagination,
  PositionLoader,
  StyledTableCell,
  StyledTableRow,
} from 'Components/components.js';
import {
  CreatedBy,
  CreatedDate,
  Description,
} from 'Components/PipelineDetails/libs/constants/constants.js';
import { DATE_FORMAT } from 'constants/constants.js';

const HEAD_CELLS = [CreatedBy, CreatedDate, Description];
const DEFAULT_ROWS_PER_PAGE = 5;
const REPORT_NAME_WIDTH = 640;

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    '& .MuiTableCell-root': {
      padding: theme.spacing(2.5),
      '&.withLeftPadding': {
        paddingLeft: theme.spacing(8),
      },
      '&.withWidth': {
        width: '30%',
      },
    },
  },
}));

const PowerBIReportsTable = ({
  reports,
  isFetching,
  reportsCount,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  const classes = useStyles();

  return (
    <TableContainer className={classes.tableContainer}>
      <Table>
        <ReportsTableHead headCells={HEAD_CELLS} />
        <TableBody className='position-relative'>
          {isFetching ? (
            <StyledTableRow>
              <StyledTableCell colSpan={HEAD_CELLS.length}>
                <div style={{ minHeight: 275 }}>
                  <PositionLoader />
                </div>
              </StyledTableCell>
            </StyledTableRow>
          ) : (
            reports?.map((report) => (
              <ReportsTableBody
                key={report.id}
                report={report}
                colSpan={HEAD_CELLS.length}
              />
            ))
          )}
        </TableBody>
      </Table>

      <Pagination
        count={reportsCount}
        rowsPerPage={rowsPerPage}
        page={page}
        defaultRowsPerPage={DEFAULT_ROWS_PER_PAGE}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};

const ReportsTableHead = ({ headCells }) => {
  return (
    <TableHead>
      <StyledTableRow>
        {headCells.map(
          (headCell) =>
            headCell.IS_SHOW && (
              <StyledTableCell key={headCell.ID} align={headCell.ALIGN}>
                <span className='txt-grey-13-500 nowrapText'>
                  {headCell.LABEL}
                </span>
              </StyledTableCell>
            )
        )}
      </StyledTableRow>
    </TableHead>
  );
};

const ReportsTableBody = ({ report, colSpan }) => {
  const { name, workspace, createdBy, createdDate, description } = report;

  return (
    <>
      <StyledTableRow className='noBorder'>
        <StyledTableCell colSpan={colSpan} className='withLeftPadding'>
          <OverflownText
            title={`${workspace.name} : ${name}`}
            maxWidth={REPORT_NAME_WIDTH}
          >
            <span className='txt-mainDark-13-700'>
              {workspace?.name || ''} : {name}
            </span>
          </OverflownText>
        </StyledTableCell>
      </StyledTableRow>

      <StyledTableRow>
        <StyledTableCell className='nowrapText withWidth'>
          {!createdBy ? (
            <span className='txt-grey-13-500'>N/A</span>
          ) : (
            createdBy
          )}
        </StyledTableCell>

        <StyledTableCell className='nowrapText withWidth'>
          {!createdDate ? (
            <span className='txt-grey-13-500'>N/A</span>
          ) : (
            moment(createdDate)
              .utc()
              .format(DATE_FORMAT.shortMonthYearTimeSecond)
          )}
        </StyledTableCell>

        <StyledTableCell>
          {!description ? (
            <span className='txt-grey-13-500'>N/A</span>
          ) : (
            description
          )}
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
};

export { PowerBIReportsTable };
