import React from 'react';
import {
  useRef,
  useEffect,
  useLocation,
  usePagination,
  useMemo,
  useState,
  useOnClickOutside,
} from 'hooks/hooks.js';
import clsx from 'clsx';
import { useStyles } from './RightSideModal.style.js';
import { useModal } from 'context/context.js';
import { Pagination } from 'Components/components.js';
import { ReactComponent as CloseButton } from 'assets/img/icons/closeIconBgd.svg';

const RightSideModal = ({
  modalWidth = 800,
  title,
  subtitle = '',
  totalCount = 0,
  ModalBodyComponent,
  SubtitleComponent = null,
  onClose = () => {},
  mainBlockMargin = 24,
  ...props
}) => {
  const classes = useStyles({ mainBlockMargin });
  const [isClosing, setIsClosing] = useState(false);
  const { setModal } = useModal();
  const ref = useRef();
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination(false);
  const location = useLocation();
  const currentModalPath = useMemo(() => location.pathname, []);

  useOnClickOutside(ref, () => onClose());

  useEffect(() => {
    if (currentModalPath !== location.pathname) {
      setModal(null);
    }
  }, [currentModalPath, location.pathname, setModal]);

  const handleCloseModal = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
      setModal(null);
    }, 300);
  };

  return (
    <div
      ref={ref}
      className={clsx(
        classes.modalContainer,
        isClosing ? 'slide-out' : 'slide-in'
      )}
      style={{ width: modalWidth }}
    >
      <div className={classes.headerContainer}>
        <div className={classes.header}>
          <div>
            <div className='txt-mainDark-24-700'>{title}</div>
            <div className='txt-grey-13-500'>{subtitle}</div>
            {SubtitleComponent && <SubtitleComponent {...props} />}
          </div>
          <CloseButton
            className={classes.cancelButton}
            onClick={handleCloseModal}
          />
        </div>
      </div>

      <div className={classes.mainBlock}>
        <ModalBodyComponent
          {...props}
          page={page}
          rowsPerPage={rowsPerPage}
          closeModal={handleCloseModal}
        />
      </div>

      <Pagination
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        className='paginationContainer'
      />
    </div>
  );
};

export { RightSideModal };
