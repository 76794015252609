import {
  useBqBillingData,
  useEffect,
  useQuery,
  useState,
  useMemo,
} from 'hooks/hooks.js';
import { useDateContext } from 'context/context.js';
import { fetcherGet } from 'utils/utils.js';
import { BQ_BILLING_TYPE } from 'utils/constants.js';
import { JOB_METRIC_TYPES, QUERY_TYPES } from 'constants/constants.js';

const useJobVolume = (jobHash, enabled = true) => {
  const { startDateUtc, endDateUtc } = useDateContext();
  const [volumeData, setVolumeData] = useState([]);
  const { currentBqBillingData } = useBqBillingData();
  const { paymentType } = currentBqBillingData || {};

  const metricType = useMemo(() => {
    if (paymentType) {
      return paymentType === BQ_BILLING_TYPE.ON_DEMANDS
        ? JOB_METRIC_TYPES.bytes
        : JOB_METRIC_TYPES.slots;
    }

    return null;
  }, [paymentType]);

  const { data, isFetching: isLoadingVolumeData } = useQuery(
    [QUERY_TYPES.jobVolume, jobHash, metricType, startDateUtc, endDateUtc],
    ({ queryKey }) => {
      const [url, jobHash, metricType] = queryKey;
      return fetcherGet(url, {
        jobHash,
        metricType,
        from: startDateUtc,
        to: endDateUtc,
      });
    },
    {
      enabled: Boolean(
        jobHash && metricType && startDateUtc && endDateUtc && enabled
      ),
      cacheTime: 0,
    }
  );

  useEffect(() => {
    if (data?.values) {
      setVolumeData(data.values);
    }
  }, [data?.values]);

  return { volumeData, isLoadingVolumeData };
};

export { useJobVolume };
