import React from 'react';
import clsx from 'clsx';
import { useState } from 'hooks/hooks.js';
import { makeStyles } from '@mui/styles';
import {
  AsyncGeneralSearch,
  AsyncMultiSearch,
  SearchDropdown,
  TableDatasetSearch,
} from 'Components/components.js';
import { usePipelinePageContext } from 'context/context.js';
import { PipelineTabSearchOptions } from 'Pages/Pipelines/libs/enums/enums.js';
import { amplEvent } from 'service/services.js';
import { getFullTableName } from 'utils/helpers/helpers.js';
import { AMPL_PAGE_EVENT, QUERY_TYPES } from 'constants/constants.js';
import {
  SEARCH_EMAIL,
  SELECTED_TABLE,
  SEARCH_TABLE,
  SELECTED_EMAIL,
  SELECTED_TAGS,
  SELECTED_LABEL,
  SEARCH_LABEL,
  SELECTED_DESTINATION,
  SEARCH_DESTINATION_TABLE,
  SELECTED_POWER_BI_REPORT,
  SELECTED_POWER_BI_WORKSPACE,
} from 'Pages/Pipelines/libs/constants/constants.js';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    '& .searchInput': {
      flexGrow: 1,
    },
    '& .input': {
      flexGrow: 1,
    },
  },
}));

const PAGE_EVENT = `${AMPL_PAGE_EVENT.pipeline} ->`;

const getCurrentSearchType = (values) => {
  for (const searchKey in values) {
    const searchValue = values[searchKey];
    if (
      (searchValue && !Array.isArray(searchValue)) ||
      (Array.isArray(searchValue) && searchValue.length > 0)
    ) {
      switch (searchKey) {
        case SELECTED_EMAIL:
        case SEARCH_EMAIL:
          return PipelineTabSearchOptions.EMAIL;
        case SELECTED_TAGS:
          return PipelineTabSearchOptions.TAGS;
        case SELECTED_LABEL:
        case SEARCH_LABEL:
          return PipelineTabSearchOptions.LABELS;
        case SELECTED_DESTINATION:
        case SEARCH_DESTINATION_TABLE:
          return PipelineTabSearchOptions.DESTINATION;
        case SELECTED_POWER_BI_REPORT:
          return PipelineTabSearchOptions.POWER_BI_REPORT;
        case SELECTED_POWER_BI_WORKSPACE:
          return PipelineTabSearchOptions.POWER_BI_WORKSPACE;
        default:
          return PipelineTabSearchOptions.TABLE;
      }
    }
  }

  return PipelineTabSearchOptions.TABLE;
};

const Search = () => {
  const classes = useStyles();
  const searchOptions = Object.values(PipelineTabSearchOptions);
  const { searchValues, handleChangeSearchValues } = usePipelinePageContext();
  const [selectedSearch, setSelectedSearch] = useState(
    getCurrentSearchType(searchValues)
  );

  const handleChangeSelectedSearch = (value) => {
    setSelectedSearch(value);
    handleChangeSearchValues(null, null);
  };

  const handleSearchStringValue = (searchKey, value) => {
    const newSearchValue =
      searchKey === SELECTED_DESTINATION ? { dataset: value } : value;
    handleChangeSearchValues(searchKey, newSearchValue || '');

    if (value.length) {
      amplEvent(`${PAGE_EVENT} ${searchKey} -> ${value}`);
    }
  };

  const handleSearchMultiValue = (searchKey, value) => {
    handleChangeSearchValues(searchKey, value);

    if (value.length) {
      amplEvent(`${PAGE_EVENT} ${searchKey} -> multi search`);
    }
  };

  const handleSelectObjectValue = (searchKey, value) => {
    handleChangeSearchValues(searchKey, value);

    if (value) {
      amplEvent(
        `${PAGE_EVENT} ${searchKey} -> ${getFullTableName(
          value?.table,
          value?.dataset
        )}`
      );
    }
  };

  const handleAsyncLabelSearchGetLabelFromResponseItem = (value) => {
    return `${value.key} : ${value.value}`;
  };

  const handleAsyncLabelSearchGetValueFromResponseItem = (value) => {
    return value;
  };
  const handleAsyncLabelSearchGetLabelFromRequestItem =
    handleAsyncLabelSearchGetLabelFromResponseItem;
  const handleAsyncLabelSearchGetValueFromRequestItem =
    handleAsyncLabelSearchGetValueFromResponseItem;

  const handleAsyncPowerBISelectGetLabelFromRequestItem = (value) => {
    return value.name;
  };

  const handleAsyncPowerBISelectGetValueFromRequestItem = (value) => {
    return value.name;
  };

  const searchConfig = {
    [PipelineTabSearchOptions.EMAIL]: (
      <AsyncGeneralSearch
        onSearch={(value) => handleSearchStringValue(SEARCH_EMAIL, value)}
        onSelect={(value) => handleSearchStringValue(SELECTED_EMAIL, value)}
        defaultSelectValue={searchValues[SELECTED_EMAIL]}
        defaultSearchValue={searchValues[SEARCH_EMAIL]}
        searchUrl={QUERY_TYPES.jobProjectPrincipalEmails}
        searchQueryParam='searchPrincipalEmail'
        className='input'
        placeholderName='Start typing principal email...'
        searchWithDropdown={true}
        searchKey={PipelineTabSearchOptions.EMAIL}
      />
    ),
    [PipelineTabSearchOptions.TAGS]: (
      <AsyncMultiSearch
        onSelect={(value) => handleSearchMultiValue(SELECTED_TAGS, value)}
        defaultSelectValue={searchValues[SELECTED_TAGS]}
        searchUrl={QUERY_TYPES.jobProjectTagName}
        searchQueryParam='searchJobTagName'
        className='input'
        placeholderName='Start typing pipeline tag / label...'
        searchWithDropdown={true}
        searchKey={PipelineTabSearchOptions.TAGS}
      />
    ),
    [PipelineTabSearchOptions.LABELS]: (
      <AsyncGeneralSearch
        onSearch={(value) => handleSearchStringValue(SEARCH_LABEL, value)}
        onSelect={(value) => handleSearchStringValue(SELECTED_LABEL, value)}
        defaultSelectValue={searchValues[SELECTED_LABEL]}
        defaultSearchValue={searchValues[SEARCH_LABEL]}
        searchUrl={QUERY_TYPES.projectLabels}
        searchQueryParam='searchTableLabel'
        className='input'
        placeholderName='Start typing label...'
        searchWithDropdown={true}
        searchKey={PipelineTabSearchOptions.LABELS}
        getLabelFromResponseItem={
          handleAsyncLabelSearchGetLabelFromResponseItem
        }
        getValueFromResponseItem={
          handleAsyncLabelSearchGetValueFromResponseItem
        }
        getLabelFromRequestItem={handleAsyncLabelSearchGetLabelFromRequestItem}
        getValueFromRequestItem={handleAsyncLabelSearchGetValueFromRequestItem}
      />
    ),
    [PipelineTabSearchOptions.DESTINATION]: (
      <TableDatasetSearch
        onTableChange={(value) =>
          handleSelectObjectValue(SELECTED_DESTINATION, value)
        }
        onDatasetChange={(value) =>
          handleSearchStringValue(SELECTED_DESTINATION, value)
        }
        onSearch={(value) =>
          handleSearchStringValue(SEARCH_DESTINATION_TABLE, value)
        }
        defaultDataset={searchValues[SELECTED_DESTINATION]?.dataset || ''}
        defaultTable={searchValues[SELECTED_DESTINATION]?.table || ''}
        defaultSearchValue={searchValues[SEARCH_DESTINATION_TABLE]}
        className='input'
        placeholderName='Start typing destination name...'
        searchWithDropdown={true}
        searchKey={PipelineTabSearchOptions.DESTINATION}
      />
    ),
    [PipelineTabSearchOptions.POWER_BI_REPORT]: (
      <AsyncGeneralSearch
        onSearch={null}
        onSelect={(value) =>
          handleSearchStringValue(SELECTED_POWER_BI_REPORT, value)
        }
        defaultSelectValue={searchValues[SELECTED_POWER_BI_REPORT]}
        searchUrl={QUERY_TYPES.powerBIReports}
        searchQueryParam='search'
        queryParams={{ forList: true }}
        className='input'
        placeholderName='Start typing Power BI report name...'
        searchWithDropdown={true}
        searchKey={PipelineTabSearchOptions.POWER_BI_REPORT}
        getLabelFromRequestItem={
          handleAsyncPowerBISelectGetLabelFromRequestItem
        }
        getValueFromRequestItem={
          handleAsyncPowerBISelectGetValueFromRequestItem
        }
      />
    ),
    [PipelineTabSearchOptions.POWER_BI_WORKSPACE]: (
      <AsyncGeneralSearch
        onSearch={null}
        onSelect={(value) =>
          handleSearchStringValue(SELECTED_POWER_BI_WORKSPACE, value)
        }
        defaultSelectValue={searchValues[SELECTED_POWER_BI_WORKSPACE]}
        searchUrl={QUERY_TYPES.powerBIWorkspaces}
        searchQueryParam='search'
        className='input'
        placeholderName='Start typing Power BI workspace name...'
        searchWithDropdown={true}
        searchKey={PipelineTabSearchOptions.POWER_BI_WORKSPACE}
        getLabelFromRequestItem={
          handleAsyncPowerBISelectGetLabelFromRequestItem
        }
        getValueFromRequestItem={
          handleAsyncPowerBISelectGetValueFromRequestItem
        }
      />
    ),
    default: (
      <TableDatasetSearch
        onTableChange={(value) =>
          handleSelectObjectValue(SELECTED_TABLE, value)
        }
        onSearch={(value) => handleSearchStringValue(SEARCH_TABLE, value)}
        defaultDataset={searchValues[SELECTED_TABLE]?.dataset || ''}
        defaultTable={searchValues[SELECTED_TABLE]?.table || ''}
        defaultSearchValue={searchValues[SEARCH_TABLE]}
        className='input'
        placeholderName='Start typing table name...'
        searchWithDropdown={true}
        disableDatasets={true}
        searchKey={PipelineTabSearchOptions.TABLE}
      />
    ),
  };

  const renderSearchField = () =>
    searchConfig[selectedSearch] || searchConfig.default;

  return (
    <div className={clsx(classes.container, 'searchInput')}>
      <SearchDropdown
        options={searchOptions}
        selectedOption={selectedSearch}
        onOptionClick={handleChangeSelectedSearch}
      />
      {renderSearchField()}
    </div>
  );
};

export { Search };
