import { SEARCH_PARAMS } from 'constants/constants.js';
import {
  ACTION_STATUS_FILTER,
  COST_EFFICIENCY_FILTER,
  PIPELINE_SUBTYPES_FILTER,
  TABLE_ALERT_STATUS_FILTER,
  TECHNOLOGY_FILTER,
  TIME_PERIOD_FILTER,
} from '../constants/constants.js';

const getKeyForURL = (type) => {
  switch (type) {
    case TABLE_ALERT_STATUS_FILTER:
      return SEARCH_PARAMS.TABLE_ALERT_TYPE;
    case ACTION_STATUS_FILTER:
      return SEARCH_PARAMS.PIPELINE_STATUS;
    case TIME_PERIOD_FILTER:
      return SEARCH_PARAMS.TIME_PERIOD;
    case TECHNOLOGY_FILTER:
      return SEARCH_PARAMS.PIPELINE_TYPE;
    case COST_EFFICIENCY_FILTER:
      return SEARCH_PARAMS.COST_EFFICIENCY;
    case PIPELINE_SUBTYPES_FILTER:
      return SEARCH_PARAMS.PIPELINE_SUBTYPES;
    default:
      return null;
  }
};

export { getKeyForURL };
