export const sourceId = 'source';
export const pipelineSubtype = 'pipelineSubtype';
export const emailId = 'email';
export const tagsId = 'TAGS';
export const frequencyId = 'frequency';
export const activityId = 'lastActivity';
export const totalCostId = 'totalCost';
export const reservationId = 'RESERVATION';
export const currentPlanId = 'CURRENT_PLAN';
export const alternativePlanId = 'ALTERNATIVE_PLAN';

export const headSource = {
  id: sourceId,
  label: 'Source table',
  sort: false,
  align: 'left',
  isShow: true,
};
export const headJobType = {
  id: pipelineSubtype,
  label: 'Job type',
  sort: false,
  align: 'left',
  isShow: true,
};
export const headEmail = {
  id: emailId,
  label: 'Principal email',
  sort: false,
  align: 'left',
  isShow: true,
};
export const headTags = {
  id: tagsId,
  label: 'Tags / labels / reports',
  sort: false,
  align: 'left',
  isShow: true,
};
export const headFrequency = {
  id: frequencyId,
  label: 'Update frequency',
  sort: false,
  align: 'right',
  isShow: true,
};
export const headActivity = {
  id: activityId,
  label: 'Last executed',
  sort: false,
  align: 'right',
  isShow: true,
};
export const headTotalCost = {
  id: totalCostId,
  label: (
    <>
      Total cost/
      <br />
      possible costs
    </>
  ),
  sort: false,
  align: 'right',
  isShow: true,
};

export const headReservation = {
  id: reservationId,
  label: 'Reservation',
  sort: false,
  align: 'left',
  isShow: true,
};

export const headCurrentPlan = {
  id: currentPlanId,
  label: 'Current plan',
  sort: false,
  align: 'right',
  isShow: true,
};

export const headAlternativePlan = {
  id: alternativePlanId,
  label: 'Alternative plan',
  sort: false,
  align: 'right',
  isShow: true,
};
