import React from 'react';
import { useCallback, useHistory } from 'hooks/hooks.js';
import { Image } from 'react-bootstrap';
import { useStyles } from './CostsBanner.style.js';
import { Button, BUTTON_VARIANT } from 'Components/components.js';
import { AppRoutes } from 'app-routes.js';
import { useModal } from 'context/context.js';
import CostsImg from 'assets/img/banners/costs-image.png';

const CostsBanner = ({ onClose }) => {
  const classes = useStyles();
  const history = useHistory();
  const { setModal } = useModal();

  const closeModal = useCallback(() => {
    onClose();
    setModal(null);
  }, [onClose, setModal]);

  const navigateCostsPage = useCallback(() => {
    onClose();
    history.push(AppRoutes.Pipelines.path);
    closeModal();
  }, [closeModal, history, onClose]);

  return (
    <div className={classes.bannerContainer}>
      <div className={classes.rightSide}>
        <div className={classes.textWrapper}>
          <div className='txt-mainDark-24-700 mb-4'>
            Introducing Costs Insights
          </div>
          <div className='txt-mainDark-13-500'>
            <div className='mb-3'>
              Try new proactive data FinOps for tools running in your GCP right
              now.
            </div>
            <ul>
              <li className='txt-mainDark-13-500 mb-3'>
                Know precisely how much it costs to run a data model and deliver
                data to a specific destination.
              </li>
              <li className='txt-mainDark-13-500'>
                Identify flows that could be optimized for excellent cloud costs
                management.
              </li>
            </ul>
          </div>
        </div>

        <div className={classes.buttonContainer}>
          <Button
            text='Skip'
            variant={BUTTON_VARIANT.text}
            onClick={closeModal}
            fullWidth={false}
          />
          <Button
            text='Try now'
            onClick={navigateCostsPage}
            fullWidth={false}
            className={classes.containedButton}
          />
        </div>
      </div>

      <div className={classes.leftSide}>
        <Image src={CostsImg} alt='Costs page' />
      </div>
    </div>
  );
};

export { CostsBanner };
