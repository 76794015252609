import React from 'react';
import { useStyles } from 'Pages/Integrations/Integrations.styles.js';

const GoogleChatItem = ({ spaces = [] }) => {
  const classes = useStyles();
  const spaceNames = spaces.map((space) => space.name).join(', ');

  if (!spaceNames) {
    return null;
  }

  return (
    <div className={classes.cardItem}>
      <div className={classes.cardItemInfo}>
        <span className='txt-grey-13-500'>Space name:</span>
        <span className='txt-mainDark-13-500'>{spaceNames}</span>
      </div>
    </div>
  );
};

export { GoogleChatItem };
