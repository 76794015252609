import React from 'react';
import { useBqBillingData, useMemo, useEffect } from 'hooks/hooks.js';
import clsx from 'clsx';
import moment from 'moment/moment';
import { makeStyles } from '@mui/styles';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { ALTERNATIVE_PLAN_CELL, DATE_FORMAT } from 'constants/constants.js';
import { ComparisonValues, OverflownText } from 'Components/components.js';
import {
  CostLabel,
  JobTagsTableCell,
  PipelineDetailsModal,
  SourceTableCell,
} from '../components.js';
import { useModal, usePipelinePageContext } from 'context/context.js';
import { bytesToSize, millisecondsToTimeSlots } from 'utils/helpers/helpers.js';
import { BQ_BILLING_TYPE, PIPELINE_SUBTYPE_LABELS } from 'utils/constants.js';
import {
  SEARCH_TABLE,
  SELECTED_TABLE,
} from 'Pages/Pipelines/libs/constants/constants.js';
import { PipelineTableCellStyles } from 'Pages/Pipelines/libs/enums/enums.js';

const {
  PRINCIPAL_EMAIL_WIDTH,
  PRINCIPAL_EMAIL_FULL_WIDTH,
  RESERVATION_WIDTH,
  LAST_ACTIVITY_CELL_WIDTH,
} = PipelineTableCellStyles;
const BASE_COL_SPAN = 3;

const useStyles = makeStyles((theme) => ({
  insideTable: {
    '& .MuiTableCell-root': {
      paddingTop: 0,
      paddingRight: 0,
      '&:first-of-type': {
        paddingLeft: 0,
      },
    },
  },
}));

const SourceTableRow = ({
  source,
  destinationTable,
  isJobTypeCellExists,
  scrollToRef,
  onChangeIsScrollRefRender,
}) => {
  const {
    jobHash,
    tables,
    sourceUri,
    principalEmail,
    jobTags,
    frequency,
    costs,
    lastActivity,
    pipelineSubtype,
    sourceProject,
    reservation,
    routines,
    totalBytes,
    totalSlotsMs,
    possibleCosts,
  } = source;
  const { setModal } = useModal();
  const { bqBillingData, currentBqBillingData } = useBqBillingData();
  const isExistingReservationCell =
    currentBqBillingData?.paymentType !== BQ_BILLING_TYPE.ON_DEMANDS;

  const {
    searchValues,
    selectedJobHash,
    handleChangeJobHash,
    pipelineDetailsLink,
    onClickCopyLink,
  } = usePipelinePageContext();

  const sourceData = useMemo(() => {
    return [...tables, ...routines];
  }, [routines, tables]);

  const sourcePaymentType = bqBillingData?.values?.find(
    (item) => item.project === sourceProject
  )?.paymentType;
  const isOnDemand = sourcePaymentType === BQ_BILLING_TYPE.ON_DEMANDS;

  const currentPlanValue = isOnDemand
    ? bytesToSize(totalBytes)
    : millisecondsToTimeSlots(totalSlotsMs);

  const alternativePlanValue = isOnDemand
    ? millisecondsToTimeSlots(totalSlotsMs)
    : bytesToSize(totalBytes);

  useEffect(() => {
    if (selectedJobHash && jobHash === selectedJobHash) {
      setModal(() => (
        <PipelineDetailsModal
          destinationTable={destinationTable}
          sourceData={source}
          pipelineDetailsLink={pipelineDetailsLink}
          onClickCopyLink={onClickCopyLink}
          onClose={() => handleChangeJobHash(null)}
        />
      ));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedJobHash, jobHash]);

  useEffect(() => {
    if (scrollToRef) {
      onChangeIsScrollRefRender(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSourceRawClick = () => {
    handleChangeJobHash(jobHash);
  };

  const colSpan = useMemo(() => {
    if (isExistingReservationCell && isJobTypeCellExists) {
      return BASE_COL_SPAN + 2;
    }

    if (isExistingReservationCell || isJobTypeCellExists) {
      return BASE_COL_SPAN + 1;
    }

    return BASE_COL_SPAN;
  }, [isExistingReservationCell, isJobTypeCellExists]);

  return (
    <TableRow
      ref={scrollToRef}
      onClick={onSourceRawClick}
      className={clsx(jobHash === selectedJobHash && 'withBackground')}
    >
      <SourceTableCell
        sourceData={sourceData}
        sourceUri={sourceUri}
        selectedTable={searchValues[SELECTED_TABLE]}
        searchValue={searchValues[SEARCH_TABLE]}
      />

      <TableCell colSpan={colSpan}>
        <InsideTable
          principalEmail={principalEmail}
          reservation={reservation}
          pipelineSubtype={pipelineSubtype}
          frequency={frequency}
          lastActivity={lastActivity}
          jobTags={jobTags}
          colSpan={colSpan}
          isExistingReservationCell={isExistingReservationCell}
          isJobTypeCellExists={isJobTypeCellExists}
        />
      </TableCell>

      <TableCell align='right' className='nowrapText withBackground'>
        {currentPlanValue || <span className='txt-grey-13-500'>N/A</span>}
      </TableCell>
      <TableCell align='right' className='nowrapText'>
        {alternativePlanValue || <span className='txt-grey-13-500'>N/A</span>}
      </TableCell>
      <TableCell align='right' className='nowrapText'>
        <CostLabel value={costs} />
        {possibleCosts > 0 && (
          <ComparisonValues
            primaryValue={costs}
            secondaryValue={possibleCosts}
            tip={ALTERNATIVE_PLAN_CELL}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

const InsideTable = ({
  principalEmail,
  reservation,
  pipelineSubtype,
  frequency,
  lastActivity,
  jobTags,
  colSpan,
  isExistingReservationCell,
  isJobTypeCellExists,
}) => {
  const classes = useStyles();
  const principalEmailCellWidth =
    !isExistingReservationCell && !isJobTypeCellExists
      ? PRINCIPAL_EMAIL_FULL_WIDTH
      : PRINCIPAL_EMAIL_WIDTH;

  return (
    <Table className={classes.insideTable}>
      <TableBody>
        <TableRow className='noBorder'>
          <TableCell style={{ width: principalEmailCellWidth }}>
            <OverflownText
              title={principalEmail}
              maxWidth={principalEmailCellWidth}
            >
              {principalEmail}
            </OverflownText>
          </TableCell>

          {isExistingReservationCell && (
            <TableCell align='left' className='nowrapText'>
              {reservation?.length ? (
                <OverflownText title={reservation} maxWidth={RESERVATION_WIDTH}>
                  {reservation}
                </OverflownText>
              ) : (
                <span className='txt-grey-13-500'>N/A</span>
              )}
            </TableCell>
          )}

          {isJobTypeCellExists && (
            <TableCell className='nowrapText'>
              {PIPELINE_SUBTYPE_LABELS[pipelineSubtype] || 'N/A'}
            </TableCell>
          )}

          <TableCell align='right' className='nowrapText'>
            {frequency}
          </TableCell>

          <TableCell
            align='right'
            className='nowrapText'
            style={{ width: LAST_ACTIVITY_CELL_WIDTH }}
          >
            {lastActivity ? (
              moment(lastActivity).utc().format(DATE_FORMAT.monthYearTime)
            ) : (
              <span className='txt-grey-13-500'>N/A</span>
            )}
          </TableCell>
        </TableRow>

        {jobTags.length > 0 && (
          <TableRow className='noBorder'>
            <JobTagsTableCell jobTags={jobTags} colSpan={colSpan} />
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default SourceTableRow;
