import React from 'react';
import { useState, useCallback, useMemo, useEffect } from 'hooks/hooks.js';
import { PositionLoader } from 'Components/components.js';
import { StartDataplex } from '../components/components.js';
import { useConfiguration } from 'context/context.js';
import { DOCUMENT_TITLE } from 'constants/constants.js';

const DataQualityRoute = ({ subComponent: Component, ...props }) => {
  const { integrations, isDataplexConnected, isDataplexApiDisabled } =
    useConfiguration();
  const [isLoadingConnection, setIsLoadingConnection] = useState(false);

  useEffect(() => {
    document.title = DOCUMENT_TITLE.dataQuality;
  }, []);

  const handleChangeIsLoading = useCallback((value) => {
    setIsLoadingConnection(value);
  }, []);

  const isLoadingDataplexConnection = useMemo(() => {
    return integrations.dataplex === undefined;
  }, [integrations.dataplex]);

  return (
    <>
      {isLoadingConnection || isLoadingDataplexConnection ? (
        <PositionLoader />
      ) : (
        <>
          {!isDataplexConnected || isDataplexApiDisabled ? (
            <StartDataplex
              onLoadingChange={handleChangeIsLoading}
              isDataplexApiDisabled={isDataplexApiDisabled}
            />
          ) : (
            <Component {...props} />
          )}
        </>
      )}
    </>
  );
};

export { DataQualityRoute };
