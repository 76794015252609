import React from 'react';
import {
  useRef,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'hooks/hooks.js';
import clsx from 'clsx';
import { Chart } from '@antv/g2';
import { useStyles } from './Volume.style.js';
import {
  Typography,
  TYPOGRAPHY_COLOR,
  TYPOGRAPHY_VARIANT,
  PositionLoader,
} from 'Components/components.js';
import { getDatetimeFormat } from './utils.js';
import { numberFormat } from 'utils/helpers/helpers.js';
import {
  BRUSH_EVENT_TYPE,
  GRAPH_NAME,
  BRUSH_ACTION,
  MARK_TYPES,
} from './constants.js';
import theme from 'theme.js';

const ScatterGraph = ({ scatterData = [], isFetchingScatterData = false }) => {
  const classes = useStyles();
  const scatterContainerRef = useRef(null);
  const scatterRef = useRef(null);
  const [brushActionInfo, setBrushActionInfo] = useState(BRUSH_ACTION.zoomIn);

  useEffect(() => {
    return () => {
      if (scatterRef.current) {
        scatterRef.current.destroy();
        scatterRef.current = null;
      }
    };
  }, []);

  const updateBrushActionText = useCallback((isBrushEventType) => {
    const actionText = isBrushEventType
      ? BRUSH_ACTION.zoomIn
      : BRUSH_ACTION.zoomOut;

    setBrushActionInfo(actionText);
  }, []);

  const isReadyChartForRender = useMemo(() => {
    return scatterData.length > 0 && !scatterRef.current;
  }, [scatterData.length]);

  // First render
  useEffect(() => {
    if (!isReadyChartForRender) {
      return;
    }

    scatterRef.current = new Chart({
      container: scatterContainerRef.current,
      autoFit: true,
      paddingTop: 0,
      paddingBottom: 6,
      theme: {
        color: theme.palette.divider,
      },
    });

    scatterRef.current
      .data(scatterData)
      .encode('x', ({ datetime }) => new Date(datetime).getTime())
      .encode('y', 'value')
      .axis('x', {
        labelAutoRotate: false,
        labelFormatter: (data) => getDatetimeFormat(data),
        line: true,
        grid: null,
      })
      .axis('y', {
        title: false,
        labelFormatter: '~s',
        gridLineDash: null,
        gridStrokeWidth: 1,
        gridStroke: theme.palette.divider,
        gridStrokeOpacity: 0.75,
      })
      .scale('y', { nice: true });

    scatterRef.current
      .line()
      .encode('color', theme.palette.primary.main)
      .style({ lineWidth: 0 })
      .tooltip({
        title: ({ datetime }) => getDatetimeFormat(datetime),
        items: [
          ({ value }) => ({
            name: 'raw value',
            value: numberFormat(value),
          }),
        ],
      });

    scatterRef.current
      .point()
      .encode('size', 2)
      .encode('shape', MARK_TYPES.point)
      .tooltip(false);

    scatterRef.current.interaction({
      brushXFilter: true,
    });

    scatterRef.current.on('brush:filter', (event) => {
      const { selection } = event.data;
      const zoomScatterDatetime = selection.at(0);

      if (!zoomScatterDatetime.length) {
        return;
      }

      updateBrushActionText(event.type !== BRUSH_EVENT_TYPE);
    });

    scatterRef.current.render();
  }, [isReadyChartForRender, scatterData, updateBrushActionText]);

  return (
    <>
      {isFetchingScatterData && <PositionLoader />}

      <Typography color={TYPOGRAPHY_COLOR.textSecondary} sx={{ ml: 4 }}>
        {GRAPH_NAME.scatter}
      </Typography>
      <div
        ref={scatterContainerRef}
        className={clsx(classes.graph, 'scatter')}
      />
      <Typography
        variant={TYPOGRAPHY_VARIANT.body2}
        color={TYPOGRAPHY_COLOR.textSecondary}
        className={classes.actionInfo}
        sx={{ mt: 1 }}
      >
        {brushActionInfo}
      </Typography>
    </>
  );
};

export { ScatterGraph };
