import React from 'react';
import { useEffect, useRef, useMemo } from 'hooks/hooks.js';
import { Chart } from '@antv/g2';
import moment from 'moment';
import { useStyles } from './Freshness.style.js';
import { PositionLoader } from 'Components/components.js';
import { EXISTING_DATA_TYPES } from 'Pages/Monitors/libs/constants.js';
import theme from 'theme.js';
import { GRAPH_HEIGHT } from 'constants/constants.js';

const BASE_BLUE_COLOR = '#2F50FF';

const getDatetimeFormat = (datetime) => {
  return moment(datetime).utc().format('MMM DD, hh:mm a');
};

const Freshness = ({
  data = [],
  isFetchingData = false,
  onChangeExistingData,
  isDestroyingGraph = false,
  freshnessHeight = GRAPH_HEIGHT.large,
}) => {
  const classes = useStyles({ freshnessHeight });
  const containerRef = useRef(null);
  const graphRef = useRef(null);
  const resizeObserverRef = useRef(null);
  const intersectionObserverRef = useRef(null);

  const lastItemDatetime = useMemo(() => {
    if (!data.length) {
      return null;
    }

    return data.at(-1).datetime;
  }, [data]);

  const periodInProcess = useMemo(() => {
    if (!data.length) {
      return null;
    }

    return data.at(-1).value === 0 && !data.at(-1).isAnomaly;
  }, [data]);

  const freshnessData = useMemo(() => {
    return data.map((item) => {
      const copyItem = item;

      if (copyItem.datetime === lastItemDatetime && periodInProcess) {
        copyItem.value = 1;
      }

      return { ...copyItem, values: copyItem.value };
    });
  }, [data, lastItemDatetime, periodInProcess]);

  // Update graph size
  useEffect(() => {
    const handleResize = () => {
      if (graphRef.current) {
        graphRef.current.forceFit();
        graphRef.current.render();
      }
    };

    resizeObserverRef.current = new ResizeObserver(() => {
      handleResize();
    });

    intersectionObserverRef.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (containerRef.current) {
            if (entry.isIntersecting) {
              resizeObserverRef.current.observe(containerRef.current);
            } else {
              resizeObserverRef.current.unobserve(containerRef.current);
            }
          }
        });
      },
      { threshold: 0.1 }
    );

    intersectionObserverRef.current.observe(containerRef.current);

    return () => {
      if (resizeObserverRef.current && containerRef.current) {
        resizeObserverRef.current.unobserve(containerRef.current);
      }
      if (intersectionObserverRef.current && containerRef.current) {
        intersectionObserverRef.current.unobserve(containerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    return () => {
      if (graphRef.current) {
        graphRef.current.destroy();
        graphRef.current = null;
      }
    };
  }, []);

  // Destroy after removing table
  useEffect(() => {
    if (graphRef.current && (isDestroyingGraph || !freshnessData.length)) {
      graphRef.current.destroy();
      graphRef.current = null;
    }
  }, [freshnessData, isDestroyingGraph]);

  useEffect(() => {
    if (onChangeExistingData) {
      if (freshnessData) {
        onChangeExistingData(
          EXISTING_DATA_TYPES.freshness,
          freshnessData.length > 0
        );
      } else if (isFetchingData) {
        onChangeExistingData(EXISTING_DATA_TYPES.freshness, true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [freshnessData, isFetchingData]);

  // First render
  useEffect(() => {
    if (freshnessData?.length > 0 && !graphRef.current) {
      graphRef.current = new Chart({
        container: containerRef.current,
        height: freshnessHeight,
        autoFit: true,
        paddingBottom: 6,
        theme: {
          color: BASE_BLUE_COLOR,
        },
      });

      graphRef.current
        .data(freshnessData)
        .encode('x', ({ datetime }) => getDatetimeFormat(datetime))
        .encode('y', 'value')
        .axis('x', {
          labelAutoRotate: false,
          line: true,
          lineExtension: [5, 0],
          tickLength: 5,
          tickFilter: (_, i) => i !== 0 && i % 5 === 0,
        })
        .axis('y', false)
        .scale('x', { nice: true });

      graphRef.current
        .interval()
        .style('strokeOpacity', 1)
        .style('maxWidth', 0)
        .style('lineWidth', ({ value }) => (value === 1 ? 2 : 0))
        .style('lineDash', ({ datetime }) =>
          datetime === lastItemDatetime && periodInProcess ? [10, 5] : [0, 0]
        )
        .state({
          active: { strokeOpacity: 1 },
          inactive: { strokeOpacity: 0.5 },
        });

      graphRef.current
        .interval()
        .encode('y', ({ isAnomaly }) => (isAnomaly ? 1 : NaN))
        .style({
          fill: theme.palette.error.main,
          fillOpacity: 0.1,
          inset: -1,
        })
        .tooltip({
          title: ({ datetime }) => getDatetimeFormat(datetime),
          items: [
            () => ({
              name: 'value',
              value: 0,
            }),
          ],
        })
        .state({
          active: { strokeOpacity: 1 },
          inactive: { strokeOpacity: 0.5, fillOpacity: 0.05 },
        });

      graphRef.current
        .point()
        .encode('size', 4)
        .encode('shape', 'hollowSquare')
        .style('lineWidth', ({ value }) => (value === 1 ? 2 : 0))
        .style('transform', 'translate(4, -6) rotate(45deg)')
        .style('cursor', 'pointer')
        .tooltip({
          title: ({ datetime }) => getDatetimeFormat(datetime),
          items: [
            ({ value }) => ({
              name: 'value',
              value,
              color: value === 1 ? BASE_BLUE_COLOR : theme.palette.error.main,
            }),
          ],
        })
        .state({
          active: { strokeOpacity: 1 },
          inactive: { strokeOpacity: 0.5 },
        });

      graphRef.current
        .point()
        .encode('shape', 'point')
        .style('opacity', ({ value }) => (value === 1 ? 0 : 1))
        .style('stroke', theme.palette.common.white)
        .tooltip(false);

      graphRef.current.interaction('elementHighlightByX', true);

      graphRef.current.render();
    }
  }, [periodInProcess, lastItemDatetime, freshnessData]);

  return (
    <div ref={containerRef} className={classes.graphContainer}>
      {isFetchingData && <PositionLoader />}
    </div>
  );
};

export { Freshness };
