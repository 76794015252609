import React, { createContext } from 'react';
import { useCallback, useContext, useState } from 'hooks/hooks.js';
import { Snackbar } from '@mui/material';
import { Alert } from 'Components/components.js';
import { ALERT_SEVERITY } from 'constants/constants.js';

const SnackbarContext = createContext({});

const SnackbarContextProvider = ({ children }) => {
  const [messages, setSnackbarMessage] = useState([]);
  const [anchorPosition, setAnchorPosition] = useState({
    horizontal: 'left',
    vertical: 'bottom',
  });

  const handleClose = (id) => {
    setSnackbarMessage((prev) => prev.filter((i) => i.id !== id));
  };

  const updatePosition = (newPosition) => {
    setAnchorPosition(newPosition);
  };

  const setMessage = useCallback((er, severity = ALERT_SEVERITY.error) => {
    setSnackbarMessage((p) => [
      ...p,
      { id: Date.now(), message: er, severity: severity },
    ]);
  }, []);

  return (
    <SnackbarContext.Provider value={{ setMessage, updatePosition }}>
      {messages.map(({ id, message, severity }) => (
        <Snackbar
          key={id}
          open={true}
          autoHideDuration={6000}
          onClose={() => handleClose(id)}
          anchorOrigin={anchorPosition}
        >
          <div>
            <Alert
              severity={severity}
              alertComponent={message}
              withTopMargin={false}
            />
          </div>
        </Snackbar>
      ))}
      {children}
    </SnackbarContext.Provider>
  );
};

const useMessages = () => useContext(SnackbarContext);

export { SnackbarContextProvider, useMessages };
