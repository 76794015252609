const BRUSH_EVENT_TYPE = 'dragend';

const GRAPH_NAME = {
  volume: 'Aggregated data by step',
  scatter: 'Raw data',
};
const BRUSH_ACTION = {
  zoomIn: '*Select section to zoom in',
  zoomOut: '*Double-click to zoom out',
};
const MARK_TYPES = {
  line: 'line',
  point: 'point',
  rangeX: 'rangeX',
};

const SHAPE_TYPES = {
  circle: 'circle',
  point: 'point',
};

const MARK_KEYS = {
  lineDash: 'lineDash',
  filteredMinValues: 'filteredMinValues',
  filteredMaxValues: 'filteredMaxValues',
};

export {
  BRUSH_EVENT_TYPE,
  GRAPH_NAME,
  BRUSH_ACTION,
  MARK_TYPES,
  MARK_KEYS,
  SHAPE_TYPES,
};
