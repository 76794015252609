import React from 'react';
import { useEffect } from 'hooks/hooks.js';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Row, Col } from 'react-bootstrap';
import { makeStyles } from '@mui/styles';
import { IncidentsTable } from 'Components/components.js';
import { AppRoutes } from 'app-routes.js';
import { INCIDENT_TYPE, IncidentTabs } from 'constants/constants.js';
import { EXISTING_DATA_TYPES } from '../../libs/constants.js';
import { ReactComponent as ArrowIcon } from 'assets/img/icons/arrows/arrow-right.svg';

const COLUMNS = [
  { id: 'datetime', label: 'Date', style: { minWidth: 200 } },
  { id: 'details', label: 'Details', style: { minWidth: 300 } },
  { id: 'count', label: 'Count', style: { minWidth: 40 } },
  { id: 'incident_link', label: '', style: { minWidth: 100 } },
];

const DEFAULT_ROWS_PER_PAGE = 5;

const useStyles = makeStyles((theme) => ({
  container: {
    color: theme.palette.text.primary,
    '& .MuiTableRow-root.MuiTableRow-hover:hover': {
      backgroundColor: theme.palette.common.white,
    },
  },
  element: {
    display: 'inline-block',
    maxWidth: 480,
    color: theme.palette.text.primary,
    wordWrap: 'break-word',
  },
  iconContainer: {
    width: 32,
    height: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.light,
    borderRadius: 12,
    cursor: 'pointer',
  },
}));

const QueryErrors = ({
  rows,
  incidentType = INCIDENT_TYPE.LOG,
  rowsTotal = rows.length,
  rowsPage,
  rowsPerPage = DEFAULT_ROWS_PER_PAGE,
  isFetchingRows = false,
  onPageChange = () => {},
  onChangeExistingQueryErrors,
}) => {
  const classes = useStyles();

  useEffect(() => {
    const dataType =
      incidentType === INCIDENT_TYPE.PIPELINE
        ? EXISTING_DATA_TYPES.pipelineIncidents
        : EXISTING_DATA_TYPES.pipelineErrors;

    if (rows?.length > 0) {
      onChangeExistingQueryErrors(dataType, true);
    } else if (isFetchingRows) {
      onChangeExistingQueryErrors(dataType, true);
    } else {
      onChangeExistingQueryErrors(dataType, false);
    }
  }, [rows?.length, isFetchingRows, onChangeExistingQueryErrors, incidentType]);

  if (rows.length === 0) {
    return '';
  }

  return (
    <Row className={classes.container}>
      <Col>
        <IncidentsTable
          headerBorder
          columns={COLUMNS}
          rowsTotal={rowsTotal}
          rowsPage={rowsPage}
          rowsPerPage={rowsPerPage}
          isFetchingRows={isFetchingRows}
          onPageChange={onPageChange}
          pagination={rowsTotal > rowsPerPage}
          paginationFetch={rowsPage !== undefined}
          rows={rows.map((it) => {
            return {
              id: `${it.incidentId}/${it.incidentType}`,
              datetime: (
                <span className={classes.element}>
                  {moment(it.datetime).utc().format('MMM Do YYYY HH:mm A')}
                </span>
              ),
              details: <span className={classes.element}>{it.details}</span>,
              count: (
                <span
                  className={classes.element}
                  style={{ display: 'flex', justifyContent: 'end' }}
                >
                  {it.total}
                </span>
              ),
              incident_link: (
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  <Link
                    className={classes.iconContainer}
                    to={{
                      pathname: AppRoutes.Incidents.path,
                      search: new URLSearchParams({
                        tab: IncidentTabs.PIPELINE,
                        id: it.incidentId,
                        type: it.incidentType,
                      }).toString(),
                    }}
                  >
                    <ArrowIcon />
                  </Link>
                </div>
              ),
            };
          })}
        />
      </Col>
    </Row>
  );
};

export { QueryErrors };
