import { makeStyles } from '@mui/styles';

const CHECKBOX_SIZE = 22;
const TABLE_NAME_WIDTH = 280;
const DEPENDING_TABLE_WIDTH = 268;

const useStyles = makeStyles((theme) => ({
  dropdownsContainer: {
    display: 'flex',
    margin: '20px 0 24px',
  },
  contentContainer: {
    flexGrow: 3,
    padding: 24,
    backgroundColor: theme.palette.common.white,
    borderRadius: 12,
    position: 'relative',
  },
  tableContainer: {
    overflowX: 'scroll',
  },
  tableBody: {
    position: 'relative',
    '& .checkboxContainer': {
      width: CHECKBOX_SIZE,
      height: CHECKBOX_SIZE,
      paddingRight: 0,
      '& .checkbox': {
        width: CHECKBOX_SIZE,
        height: CHECKBOX_SIZE,
        padding: 0,
      },
    },
    '& .streamContainer': {
      display: 'flex',
      flexDirection: 'column',
      gap: 24,
    },

    '& .cellWithIcon': {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
    },
  },
  searchContainer: {
    marginBottom: 24,
    display: 'flex',
    gap: 12,
    width: '100%',
    '& .tableSearch': {
      flexGrow: 1,
    },
  },
  verticalDivider: {
    width: 1,
    backgroundColor: theme.palette.divider,
    marginRight: theme.spacing(4),
  },
}));

export { useStyles, TABLE_NAME_WIDTH, DEPENDING_TABLE_WIDTH };
