import { useQuery, useMemo } from 'hooks/hooks.js';
import moment from 'moment';
import {
  convertLocalToUTCDate,
  formatSearchValue,
  getIsValidUUID,
} from 'utils/helpers/helpers.js';
import { fetcherGet } from 'utils/utils.js';
import { QUERY_TYPES } from 'constants/constants.js';

const LIMIT = 50;

const usePipelineSourceTables = ({
  dataset,
  table,
  startDate,
  endDate,
  sourceTable,
  sourceDataset,
  searchValue,
  principalEmail,
  searchPrincipalEmail,
  jobTagNames,
  powerbiReportName,
  powerbiWorkspaceName,
  pipelineType,
  page,
  jobHash,
  enabled = true,
}) => {
  const isValidJobHash = getIsValidUUID(jobHash);

  const { isFetching: isFetchingSources, data } = useQuery(
    [
      QUERY_TYPES.pipelineSourceTables,
      dataset,
      table,
      startDate,
      endDate,
      sourceTable,
      sourceDataset,
      searchValue,
      principalEmail,
      searchPrincipalEmail,
      jobTagNames,
      powerbiReportName,
      powerbiWorkspaceName,
      pipelineType,
      page,
    ],
    ({ queryKey }) => {
      const [url, dataset, table, start, end] = queryKey;
      const { dataset: newSearchSourceDataset, value: newSearchSourceValue } =
        formatSearchValue(searchValue);

      const params = new URLSearchParams();
      params.append('dataset', dataset);
      params.append('table', table);
      params.append('from', moment(start).utc().format());
      params.append('to', moment(convertLocalToUTCDate(end)).utc().format());
      params.append('sourceTable', sourceTable || '');
      params.append(
        'sourceDataset',
        newSearchSourceDataset || sourceDataset || ''
      );
      params.append('sourceSearch', newSearchSourceValue || searchValue || '');
      params.append('principalEmail', principalEmail || '');
      params.append('searchPrincipalEmail', searchPrincipalEmail || '');
      powerbiReportName?.length > 0 &&
        params.append('powerbiReportName', powerbiReportName);
      powerbiWorkspaceName?.length > 0 &&
        params.append('powerbiWorkspaceName', powerbiWorkspaceName);
      params.append('pipelineType', pipelineType);
      params.append('page', page + 1);
      params.append('limit', LIMIT.toString());
      isValidJobHash && params.append('jobHash', jobHash);
      for (const tag of jobTagNames) {
        params.append('jobTagNames', tag);
      }

      return fetcherGet(url, params);
    },
    {
      enabled:
        Boolean(table && dataset) && enabled && pipelineType !== undefined,
      cacheTime: 0,
    }
  );

  const sources = useMemo(() => {
    return data?.values || [];
  }, [data?.values]);

  const sourcesCount = useMemo(() => {
    return data?.pagination?.total || 0;
  }, [data?.pagination?.total]);

  return { sources, isFetchingSources, sourcesCount };
};

export { usePipelineSourceTables };
