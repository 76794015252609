import { useQuery, useMemo } from 'hooks/hooks.js';
import { fetcherGet } from 'utils/utils.js';
import { QUERY_TYPES } from 'constants/constants.js';

const usePowerBIReports = ({
  page,
  limit,
  jobHash,
  search,
  forList = false,
  enabled = true,
}) => {
  const { data, isFetching: isFetchingPowerBIReports } = useQuery(
    [QUERY_TYPES.powerBIReports, page, limit, jobHash, search, forList],
    ({ queryKey }) => {
      const [url, page, limit, jobHash, search, forList] = queryKey;

      const params = new URLSearchParams();

      params.append('page', (page + 1).toString());
      params.append('limit', limit.toString());
      jobHash?.length > 0 && params.append('jobHash', jobHash);
      search?.length > 0 && params.append('search', search);
      forList && params.append('forList', forList);

      return fetcherGet(url, params);
    },
    { enabled }
  );

  const powerBIReports = useMemo(() => {
    return data?.values || [];
  }, [data?.values]);

  const powerBIReportsTotal = useMemo(() => {
    return data?.pagination.total || 0;
  }, [data?.pagination.total]);

  return { powerBIReports, powerBIReportsTotal, isFetchingPowerBIReports };
};

export { usePowerBIReports };
