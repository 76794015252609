import React from 'react';
import { useMemo, useState } from 'hooks/hooks.js';
import clsx from 'clsx';
import { Row, Col } from 'react-bootstrap';
import { useStyles } from '../IncidentCard/IncidentCard.styles.js';
import {
  RadioDropdown,
  IncidentsTable,
  PercentStatsBlock,
} from 'Components/components.js';
import {
  ANOMALY_TYPE,
  INCIDENT_CHART_BY_TYPE,
  INCIDENT_STATUS,
  INCIDENT_TYPE,
  IncidentTabs,
  PIPELINE_INCIDENT_CHART_BY_TYPE,
} from 'constants/constants.js';
import { excludedIncidentOptionsByStatuses, showStatus } from './util.js';
import {
  frequencyBySeconds,
  getDatePeriod,
  calcDeviation,
  getIncidentFormatData,
} from 'utils/helpers/helpers.js';
import {
  DEFAULT_ROWS_PER_PAGE,
  FRESHNESS_COLUMNS,
  VOLUME_COLUMNS,
} from './constants.js';
import PointsIcon from 'assets/img/icons/pointsIcon.svg';

const AnomalyMeta = ({
  groupIncident,
  currentIncident,
  rows,
  rowsTotal = rows.length,
  rowsPage,
  rowsPerPage = DEFAULT_ROWS_PER_PAGE,
  isFetchingRows = false,
  onPageChange = () => {},
  onChooseStatus,
  currentTab,
}) => {
  const classes = useStyles();
  const [currentDatetime, setCurrentDatetime] = useState(
    groupIncident.datetime
  );

  const incidentOptionsVolume = excludedIncidentOptionsByStatuses([
    INCIDENT_STATUS.ACTIVE,
    currentTab !== IncidentTabs.GBQ && INCIDENT_STATUS.EXPECTED,
  ]);
  const incidentOptionsFreshness = excludedIncidentOptionsByStatuses([
    INCIDENT_STATUS.ACTIVE,
    INCIDENT_STATUS.EXPECTED,
  ]);

  const Chart = useMemo(
    () =>
      groupIncident.incidentType === INCIDENT_TYPE.PIPELINE
        ? PIPELINE_INCIDENT_CHART_BY_TYPE[groupIncident.anomalyType]
        : INCIDENT_CHART_BY_TYPE[groupIncident.anomalyType],
    [groupIncident.anomalyType, groupIncident.incidentType]
  );

  const handleCurrentAnomaly = (item) => setCurrentDatetime(item.datetimeValue);

  return (
    <>
      <Row>
        <Col xs={12}>
          <div className={classes.borderTop}>
            <div className={classes.chartWrapper}>
              <Chart
                table={groupIncident.table}
                dataset={groupIncident.dataset}
                currentDatetime={currentDatetime}
                jobHash={groupIncident.jobHash}
              />
            </div>
          </div>
        </Col>
      </Row>
      {groupIncident.anomalyType === ANOMALY_TYPE.FRESHNESS && (
        <Row>
          <Col>
            <IncidentsTable
              columns={FRESHNESS_COLUMNS}
              rowsTotal={rowsTotal}
              rowsPage={rowsPage}
              rowsPerPage={rowsPerPage}
              isFetchingRows={isFetchingRows}
              onPageChange={onPageChange}
              pagination={rowsTotal > rowsPerPage}
              paginationFetch={rowsPage !== undefined}
              rows={rows.map((it, index) => {
                return {
                  id: `${it.id}/${groupIncident.anomalyType}`,
                  isFirst: rowsPage === 0 && index === 0,
                  status:
                    rowsPage === 0 && index === 0 ? (
                      <span
                        className={clsx(
                          classes.statusContainer,
                          'newIncident txt-blue-13-500'
                        )}
                      >
                        Latest
                      </span>
                    ) : (
                      <span
                        className={clsx(
                          classes.statusContainer,
                          'txt-blue-13-500'
                        )}
                      >
                        Related
                      </span>
                    ),
                  datetime: getDatePeriod(
                    it.datetime,
                    currentIncident.frequency
                  ),
                  frequency: frequencyBySeconds(currentIncident.frequency),
                  statusIncident: (
                    <div style={{ width: 40, whiteSpace: 'nowrap' }}>
                      {showStatus(incidentOptionsFreshness, it.status)}
                    </div>
                  ),
                  icon: (
                    <RadioDropdown
                      key={`${it.id}/${groupIncident.anomalyType}`}
                      options={incidentOptionsFreshness}
                      current={{ value: it.status, icon: PointsIcon }}
                      onSelect={(selected) =>
                        onChooseStatus(it.id, selected.value)
                      }
                      bottom={-12}
                      right={10}
                    />
                  ),
                };
              })}
            />
          </Col>
        </Row>
      )}
      {groupIncident.anomalyType === ANOMALY_TYPE.VOLUME && (
        <Row>
          <Col>
            <IncidentsTable
              columns={VOLUME_COLUMNS}
              rowsTotal={rowsTotal}
              rowsPage={rowsPage}
              rowsPerPage={rowsPerPage}
              isFetchingRows={isFetchingRows}
              onPageChange={onPageChange}
              pagination={rowsTotal > rowsPerPage}
              paginationFetch={rowsPage !== undefined}
              onRowClick={handleCurrentAnomaly}
              rows={rows.map((it, index) => {
                const currentValue = getIncidentFormatData(
                  groupIncident?.metricType,
                  it.current
                );
                const minValue = getIncidentFormatData(
                  groupIncident?.metricType,
                  it.min < 0 ? 1 : it.min
                );
                const maxValue = getIncidentFormatData(
                  groupIncident?.metricType,
                  it.max
                );
                const medianValue = getIncidentFormatData(
                  groupIncident?.metricType,
                  it.median
                );
                const deviationData = calcDeviation(it.current, it.min, it.max);

                return {
                  id: `${it.id}/${groupIncident.anomalyType}`,
                  isFirst: rowsPage === 0 && index === 0,
                  isSelected: currentDatetime === it.datetime,
                  status:
                    rowsPage === 0 && index === 0 ? (
                      <span
                        className={clsx(
                          classes.statusContainer,
                          'newIncident txt-blue-13-500'
                        )}
                      >
                        Latest
                      </span>
                    ) : (
                      <span
                        className={clsx(
                          classes.statusContainer,
                          'txt-blue-13-500'
                        )}
                      >
                        Related
                      </span>
                    ),
                  datetime: getDatePeriod(
                    it.datetime,
                    currentIncident.frequency
                  ),
                  frequency: frequencyBySeconds(currentIncident.frequency),
                  datetimeValue: it.datetime,
                  value: currentValue,
                  min: minValue,
                  max: maxValue,
                  median: medianValue,
                  deviation:
                    deviationData !== 0 ? (
                      <PercentStatsBlock data={deviationData} />
                    ) : null,
                  statusIncident: (
                    <div style={{ width: 40, whiteSpace: 'nowrap' }}>
                      {showStatus(incidentOptionsVolume, it.status)}
                    </div>
                  ),
                  icon: (
                    <RadioDropdown
                      key={`${it.id}/${groupIncident.anomalyType}`}
                      options={incidentOptionsVolume}
                      current={{ value: it.status, icon: PointsIcon }}
                      onSelect={(selected) =>
                        onChooseStatus(it.id, selected.value)
                      }
                      bottom={-12}
                      right={10}
                    />
                  ),
                };
              })}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export { AnomalyMeta };
