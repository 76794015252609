import { usePagination, usePowerBIReports } from 'hooks/hooks.js';
import Widget from 'Components/Widget/Widget.js';
import { PowerBIReportsTable } from './components/PowerBIReportsTable';

const DEFAULT_ROWS_PER_PAGE = 5;

const PowerBIReportsWidget = ({ jobHash }) => {
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination(false, 0, DEFAULT_ROWS_PER_PAGE);

  const { powerBIReports, powerBIReportsTotal, isFetchingPowerBIReports } =
    usePowerBIReports({
      page,
      limit: rowsPerPage,
      jobHash,
      enabled: jobHash?.length > 0,
    });

  if (!isFetchingPowerBIReports && powerBIReportsTotal === 0) {
    return null;
  }

  return (
    <Widget
      label='Power BI Reports'
      initialSettings={{ size: 'L', height: 'auto' }}
      hidden={true}
    >
      <PowerBIReportsTable
        reports={powerBIReports}
        isFetching={isFetchingPowerBIReports}
        reportsCount={powerBIReportsTotal}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Widget>
  );
};

export { PowerBIReportsWidget };
