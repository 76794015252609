const DATE_FORMAT = {
  yearMonthDay: 'YYYY-MM-DD',
  monthYear: 'MMM Do YYYY',
  dayMonthYear: 'DD.MM.YYYY',
  monthYearTime: 'MMM Do YYYY hh:mm A',
  monthYearTimeSecond: 'MMMM DD, YYYY, HH:mm:ss',
  shortMonthYearTimeSecond: 'MMM Do YYYY, HH:mm:ss',
  timeSecond: 'hh:mm A',
};

export { DATE_FORMAT };
