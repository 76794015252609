import React from 'react';
import theme from 'theme.js';
import { INPUT_LABEL } from './constants.js';

const INPUT_VALUE_ERRORS = {
  limitCharacters: 'You have reached your maximum limit of characters allowed',
  nameCompanyRequired: `The fields "${INPUT_LABEL.name}" and "${INPUT_LABEL.company}" are required`,
  nameRequired: `The field "${INPUT_LABEL.name}" is required`,
  companyRequired: `The field "${INPUT_LABEL.company}" is required`,
  addGcp: `Add ${INPUT_LABEL.projectId}`,
  chooseGcp: 'Choose GCP Project',
  notEnoughPermissions: (
    <>
      <div>Missing resources or insufficient permissions.</div>
      <span>Please refer to&nbsp;</span>
      <a
        href='https://docs.mastheadata.com/masthead-agent/saas-deployment/saas-manual-resource-creation-google-cloud'
        target='_blank'
        rel='noreferrer'
        className='link'
        style={{ color: theme.palette.error.main }}
      >
        Deployment Guidelines
      </a>
      <span>
        &nbsp;for instructions on creating the required resources or adjusting
        Service Account permissions.
      </span>
    </>
  ),
  subscriptionStatus: (
    <>
      <span>
        No PubSub subscription found. Please create one by following&nbsp;
      </span>
      <a
        href='https://docs.mastheadata.com/saas-manual-resource-creation-google-cloud-+-bigquery'
        target='_blank'
        rel='noreferrer'
        className='link'
        style={{ color: theme.palette.error.main }}
      >
        resource creation guide
      </a>
      <span>.</span>
    </>
  ),
  schemaStatus: (projectId) =>
    `Please enable BigQuery API for the project ${projectId}.`,
  combineError: (projectId) => (
    <>
      {INPUT_VALUE_ERRORS.schemaStatus(projectId)}
      <br />
      {INPUT_VALUE_ERRORS.subscriptionStatus}
    </>
  ),
  retroStatus: (projectId) =>
    `We recommend to enable retrospective parsing for the project ${projectId} to speed up the analysis of the project, otherwise it can take up to 14 days to train ML models.`,
};

export { INPUT_VALUE_ERRORS };
