import React from 'react';
import { Table, TableBody, TableContainer } from '@mui/material';
import { useStyles } from 'Pages/Pipelines/GlobalPipeline.styles.js';
import { EmptySearchData } from 'Pages/Pipelines/components/components.js';
import { ResourceTableHead } from './ResourceTableHead.js';
import { ResourceTableRows } from './ResourceTableRows.js';
import { getFullTableName } from 'utils/helpers/helpers.js';
import { DEFAULT_SEARCH_MESSAGE } from 'Pages/Pipelines/libs/constants/constants.js';

const ResourceTable = ({
  destinationTables = [],
  isFetchingDestinationTables,
  selectedTag,
}) => {
  const classes = useStyles();
  const isEmptySearch =
    selectedTag.length &&
    !destinationTables.length &&
    !isFetchingDestinationTables;

  return (
    <TableContainer sx={{ padding: 0 }}>
      <Table>
        <ResourceTableHead />
        <TableBody className={classes.tableBody}>
          {isEmptySearch ? (
            <EmptySearchData
              text={`${DEFAULT_SEARCH_MESSAGE} label/tag`}
              colSpan={6}
            />
          ) : (
            destinationTables.length > 0 &&
            destinationTables.map((destinationTable) => {
              const { table, dataset, pipelineType, project } =
                destinationTable;
              const key = `${getFullTableName(
                table,
                dataset
              )}/${pipelineType}/${project}`;

              return (
                <ResourceTableRows
                  key={key}
                  destinationTable={destinationTable}
                  selectedTag={selectedTag}
                />
              );
            })
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export { ResourceTable };
