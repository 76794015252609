import { PipelineTableCellStyles } from 'Pages/Pipelines/libs/enums/enums.js';

const sourceTableId = 'SOURCE_TABLE';
const tableStatusId = 'TABLE_STATUS';
const lastExecutedId = 'LAST_EXECUTED';
const emailId = 'EMAIL';
const tagsId = 'TAGS';
const lastDurationId = 'LAST_DURATION';
const aveSlotsHoursId = 'AVE_SLOTS_HOURS';
const slotsHoursId = 'SLOTS_HOURS';
const aveBytesId = 'AVE_BYTES';
const bytesId = 'BYTES';
const frequencyId = 'FREQUENCY';

const { JOB_NAME_WIDTH, PRINCIPAL_EMAIL_WIDTH, LAST_ACTIVITY_CELL_WIDTH } =
  PipelineTableCellStyles;

const SourceTable = {
  ID: sourceTableId,
  LABEL: 'Source table',
  SORT: false,
  ALIGN: 'left',
  IS_SHOW: true,
};
const TableStatus = {
  ID: tableStatusId,
  LABEL: 'Status',
  SORT: false,
  ALIGN: 'left',
  IS_SHOW: true,
  WIDTH: JOB_NAME_WIDTH,
};
const LastExecuted = {
  ID: lastExecutedId,
  LABEL: 'Last executed',
  SORT: false,
  ALIGN: 'left',
  IS_SHOW: true,
  WIDTH: LAST_ACTIVITY_CELL_WIDTH,
};
const Email = {
  ID: emailId,
  LABEL: 'Principal email',
  SORT: false,
  ALIGN: 'left',
  IS_SHOW: true,
  WIDTH: PRINCIPAL_EMAIL_WIDTH,
};
const Tags = {
  ID: tagsId,
  LABEL: 'Tags / labels / reports',
  SORT: false,
  ALIGN: 'left',
  IS_SHOW: true,
};
const Frequency = {
  ID: frequencyId,
  LABEL: 'Update frequency',
  SORT: false,
  ALIGN: 'right',
  IS_SHOW: true,
};
const LastDuration = {
  ID: lastDurationId,
  LABEL: 'Last duration',
  SORT: false,
  ALIGN: 'right',
  IS_SHOW: true,
};

const AveSlotsHours = {
  ID: aveSlotsHoursId,
  LABEL: 'Ave., Slots hours',
  SORT: false,
  ALIGN: 'right',
  IS_SHOW: true,
};

const SlotsHours = {
  ID: slotsHoursId,
  LABEL: 'Slots hours',
  SORT: false,
  ALIGN: 'right',
  IS_SHOW: true,
};
const AveBytes = {
  ID: aveBytesId,
  LABEL: 'Ave., Bytes',
  SORT: false,
  ALIGN: 'right',
  IS_SHOW: true,
};

const Bytes = {
  ID: bytesId,
  LABEL: 'Bytes',
  SORT: false,
  ALIGN: 'right',
  IS_SHOW: true,
};

export {
  SourceTable,
  TableStatus,
  LastExecuted,
  Email,
  Tags,
  Frequency,
  LastDuration,
  AveSlotsHours,
  SlotsHours,
  AveBytes,
  Bytes,
};
