import React from 'react';
import {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useCostsDestinationTables,
  useCostsStats,
  useBqBillingData,
} from 'hooks/hooks.js';
import { useStyles } from 'Pages/Pipelines/GlobalPipeline.styles.js';
import {
  AsyncGeneralSearch,
  Backdrop,
  ResourceStats,
} from 'Components/components.js';
import { ResourceTable } from './components/components.js';
import { Dropdowns } from '../components.js';
import { useDateContext, usePipelinePageContext } from 'context/context.js';
import { amplEvent } from 'service/services.js';
import {
  AMPL_PAGE_EVENT,
  DEAD_END_TABLES,
  PipelineTabs,
  QUERY_TYPES,
} from 'constants/constants.js';
import { PipelineTabSearchOptions } from '../../libs/enums/enums.js';
import {
  COST_EFFICIENCY_FILTER,
  ResourceStatsDropdowns,
  TABLE_ALERT_STATUS_FILTER,
  TECHNOLOGY_FILTER,
} from 'Pages/Pipelines/libs/constants/constants.js';

const PAGE_EVENT = `${AMPL_PAGE_EVENT.pipeline} -> tab -> ${PipelineTabs.RESOURCE_ALLOCATION} -> `;

const ResourceTab = () => {
  const classes = useStyles();
  const { startDate, endDate } = useDateContext();
  const { isWelcome } = useBqBillingData();
  const [selectedTag, setSelectedTag] = useState('');
  const isAllowedStatsRequest = !isWelcome;

  const {
    defaultJobHash,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangePaginationTotal,
    handleChangeIsJobHashFound,
  } = usePipelinePageContext();

  const [dropdownsValue, setDropdownsValue] = useState({
    [COST_EFFICIENCY_FILTER]: null,
    [TABLE_ALERT_STATUS_FILTER]: null,
    [TECHNOLOGY_FILTER]: null,
  });

  const isAllowedCostsDestinationRequest = useMemo(() => {
    if (!isAllowedStatsRequest) {
      return false;
    }

    return !Object.values(dropdownsValue).some((value) => value === null);
  }, [dropdownsValue, isAllowedStatsRequest]);

  const isDeadEndTablesSelected = useMemo(() => {
    return (
      dropdownsValue[COST_EFFICIENCY_FILTER]?.[0]?.value === DEAD_END_TABLES
    );
  }, [dropdownsValue]);

  const { costsStats, isCostsStatsFetching } = useCostsStats(
    isAllowedStatsRequest
  );

  const {
    destinationTables,
    destinationTablesTotal,
    isFetchingDestinationTables,
  } = useCostsDestinationTables({
    page,
    rowsPerPage,
    selectedTags: [selectedTag],
    alertTypes: dropdownsValue[TABLE_ALERT_STATUS_FILTER],
    pipelineType: dropdownsValue[TECHNOLOGY_FILTER]?.[0]?.value,
    jobHash: defaultJobHash,
    crossProject: true,
    isDeadEndTablesSelected,
    enabled: isAllowedCostsDestinationRequest,
  });

  useEffect(() => {
    handleChangePaginationTotal(destinationTablesTotal);
  }, [destinationTablesTotal, handleChangePaginationTotal]);

  useEffect(() => {
    handleChangePage({}, 0);
  }, [handleChangePage, startDate, endDate]);

  useEffect(() => {
    if (defaultJobHash && destinationTables.length > 0) {
      const isExistingPipeline = destinationTables.some(
        (item) => item.isSelected
      );
      handleChangeIsJobHashFound(isExistingPipeline);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultJobHash, destinationTables]);

  const handleChangeDropdownsValue = useCallback(
    (value) => {
      setDropdownsValue(value);
      handleChangePage({}, 0);
    },
    [handleChangePage]
  );

  const handleChangeSelectedTags = useCallback(
    (tag) => {
      handleChangePage({}, 0);
      setSelectedTag(tag);

      if (tag.length) {
        amplEvent(`${PAGE_EVENT} ${tag} search`);
      }
    },
    [handleChangePage]
  );

  return (
    <>
      <ResourceStats
        selectedTag={selectedTag}
        className={classes.statsContainer}
      />

      <div className={classes.mainContainer}>
        <Dropdowns
          pipelineStats={costsStats}
          onChangeDropdownsValue={handleChangeDropdownsValue}
          existingDropdown={ResourceStatsDropdowns}
        />

        <div className={classes.searchContainer}>
          <AsyncGeneralSearch
            onSelect={handleChangeSelectedTags}
            defaultSelectValue={selectedTag}
            searchUrl={QUERY_TYPES.jobProjectTagName}
            searchQueryParam='searchJobTagName'
            queryParams={{ crossProject: true }}
            className='searchInput'
            placeholderName='Start typing pipeline tag / label...'
            searchKey={PipelineTabSearchOptions.TAGS}
            onSearch={null}
          />
        </div>

        <ResourceTable
          destinationTables={destinationTables}
          isFetchingDestinationTables={isFetchingDestinationTables}
          selectedTag={selectedTag}
        />

        <Backdrop
          isOpen={isFetchingDestinationTables || isCostsStatsFetching}
        />
      </div>
    </>
  );
};

export { ResourceTab };
