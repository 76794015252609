import React from 'react';
import { useMemo, useQuery } from 'hooks/hooks.js';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Chip } from '@mui/material';

import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import {
  Button,
  BUTTON_VARIANT,
  PositionLoader,
  Query,
  RightSideModal,
  useQueryWidgetStyles,
} from 'Components/components.js';
import { amplEvent } from 'service/services.js';
import { fetcherGet } from 'utils/utils.js';
import {
  getFullTableName,
  getWidgetLabel,
  handleCopyText,
} from 'utils/helpers/helpers.js';
import { TABLE_TYPES, AMPL_PAGE_EVENT } from 'constants/constants.js';
import { useModal } from 'context/context.js';
import { ReactComponent as CopyText } from 'assets/img/icons/copyIcon.svg';

const QUERY_LINE_LIMIT = 10;
const MAX_DIGITS = 2;

const useStyles = makeStyles((theme) => ({
  queryModalContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  copyContainer: {
    marginBottom: 32,
    alignSelf: 'end',
  },
}));

const QueryModalBody = ({ query }) => {
  const classes = useStyles();

  return (
    <div className={classes.queryModalContainer}>
      <div className={classes.copyContainer}>
        <Chip
          icon={<CopyText />}
          label='Copy query'
          variant='outlined'
          onClick={() => handleCopyText(query)}
        />
      </div>

      <Query query={query} />
    </div>
  );
};

const ViewQuery = ({ table, dataset, type, uuid }) => {
  const classes = useQueryWidgetStyles({ maxDigits: MAX_DIGITS });
  const { setModal } = useModal();
  const { viewQuery } = getWidgetLabel();

  const { isFetching, data } = useQuery(
    [`/api/v1/views/${uuid}/query`],
    ({ queryKey }) => {
      const [url] = queryKey;
      return fetcherGet(url);
    },
    {
      enabled: uuid !== null && type === TABLE_TYPES.view,
    }
  );

  const query = useMemo(() => {
    return data?.value ? data.value : null;
  }, [data?.value]);

  const splittingQuery = useMemo(() => {
    return query?.split('\n');
  }, [query]);

  const shortQuery = useMemo(() => {
    return splittingQuery?.slice(0, QUERY_LINE_LIMIT) || [];
  }, [splittingQuery]);

  const isLargeQuery = splittingQuery?.length > QUERY_LINE_LIMIT;

  const openQueryModal = () => {
    setModal(() => (
      <RightSideModal
        modalWidth={820}
        title='View Query'
        ModalBodyComponent={QueryModalBody}
        query={query}
      />
    ));

    amplEvent(
      `${AMPL_PAGE_EVENT.monitors} -> ${getFullTableName(
        table,
        dataset
      )} -> Widget ${viewQuery.title} -> click -> Open query button`
    );
  };

  if (isFetching) {
    return <PositionLoader />;
  }

  return (
    <>
      {shortQuery.length ? (
        <div className={classes.queryContainer}>
          {shortQuery.map((line, index) => (
            <div key={index} className={classes.lineContainer}>
              <span className={classes.lineNumber}>{index + 1}</span>
              <SyntaxHighlighter
                language='sql'
                style={vs}
                className={classes.lineText}
                customStyle={{ display: 'inline' }}
              >
                {line}
              </SyntaxHighlighter>
            </div>
          ))}
          {!isLargeQuery && <br />}
          {isLargeQuery && (
            <Button
              text='Open full query'
              variant={BUTTON_VARIANT.text}
              fullWidth={false}
              onClick={openQueryModal}
            />
          )}
        </div>
      ) : (
        <div>No data</div>
      )}
    </>
  );
};

export { ViewQuery };
