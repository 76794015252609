import React from 'react';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import {
  Chip,
  Tooltip,
  Typography,
  TYPOGRAPHY_COLOR,
  TYPOGRAPHY_VARIANT,
} from 'Components/components.js';
import { amplEvent } from 'service/services.js';
import { AMPL_PAGE_EVENT } from 'constants/constants.js';
import { ReactComponent as InfoIcon } from 'assets/img/icons/ic-24-info.svg';
import { ReactComponent as ArrowRight } from 'assets/img/icons/arrows/arrow-right-16.svg';

const EVENT_CLICK = `${AMPL_PAGE_EVENT.costs} -> click ->`;

const useStyles = makeStyles((theme) => ({
  widget: {
    position: 'relative',
    minWidth: 338,
    padding: theme.spacing(6),
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(4),
  },

  mainInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },

  icon: {
    position: 'absolute',
    top: theme.spacing(6),
    right: theme.spacing(6),
    width: 16,
    height: 16,
    cursor: 'pointer',
    '& path': {
      fill: theme.palette.text.secondary,
    },
  },

  linkIcon: {
    '& path': {
      fill: theme.palette.primary.main,
    },
  },

  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },

  iconContainer: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.light,
    borderRadius: theme.spacing(1),
  },
}));

const StatsWidget = ({
  statsInfo,
  pathname,
  state = {},
  search = {},
  tooltipTip = '',
  className = '',
}) => {
  const classes = useStyles();
  const {
    name,
    mainInfo,
    chipLabel,
    chipColorType,
    info,
    linkText,
    InfoSectionIcon,
  } = statsInfo;

  return (
    <section className={clsx(classes.widget, className)}>
      <div className={classes.mainInfo}>
        {chipLabel && <Chip color={chipColorType} label={chipLabel} />}

        <Typography variant={TYPOGRAPHY_VARIANT.h1}>{mainInfo}</Typography>

        <Link
          to={{
            pathname,
            state,
            search: new URLSearchParams(search).toString(),
          }}
          onClick={() => amplEvent(`${EVENT_CLICK} ${name}`)}
        >
          <Typography color={TYPOGRAPHY_COLOR.primary} component='span'>
            {linkText}
          </Typography>
          <ArrowRight className={classes.linkIcon} />
        </Link>

        {info && (
          <section className={classes.flexContainer}>
            <div className={classes.iconContainer}>
              {InfoSectionIcon && <InfoSectionIcon />}
            </div>
            <Typography>{info}</Typography>
          </section>
        )}
      </div>

      <Tooltip title={tooltipTip} hasMaxWidthTooltip={true}>
        <InfoIcon className={classes.icon} />
      </Tooltip>
    </section>
  );
};

export { StatsWidget };
