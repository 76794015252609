import { useEffect, useState, useCallback } from 'hooks/hooks.js';
import { PipelineDataFreshness } from 'Components/components.js';
import Widget from 'Components/Widget/Widget.js';
import { GRAPH_HEIGHT } from 'constants/constants.js';
import { PipelineWidgets } from '../libs/constants/constants.js';

const FRESHNESS_DATA_LIMIT = 60;

const PipelineFreshnessWidget = ({ jobHash, onChangeLoadingWidget }) => {
  const [isLoadingFreshnessData, setIsLoadingFreshnessData] = useState(true);

  useEffect(() => {
    if (onChangeLoadingWidget) {
      onChangeLoadingWidget(PipelineWidgets.FRESHNESS, isLoadingFreshnessData);
    }
  }, [isLoadingFreshnessData, onChangeLoadingWidget]);

  const handleChangeLoadingFreshnessData = useCallback((value) => {
    setIsLoadingFreshnessData(value);
  }, []);

  return (
    <Widget
      label='Execution freshness'
      secondaryLabel='Frequency of pipeline execution'
      initialSettings={{ size: 'L', height: 'auto' }}
      hidden={true}
    >
      <PipelineDataFreshness
        jobHash={jobHash}
        onLoadingFreshnessDataChange={handleChangeLoadingFreshnessData}
        freshnessDataLimit={FRESHNESS_DATA_LIMIT}
        freshnessHeight={GRAPH_HEIGHT.medium}
      />
    </Widget>
  );
};

export { PipelineFreshnessWidget };
