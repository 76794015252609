import { splitWithRemainder } from './helpers.js';

const SEPARATOR = '.';

const formatSearchValue = (search) => {
  if (!search) return {};
  const [dataset, value] = splitWithRemainder(search, SEPARATOR, 2);
  return value ? { dataset, value } : { value: dataset };
};

export { formatSearchValue };
