import { useEffect, useHistory, useLocation } from 'hooks/hooks.js';
import { SEARCH_PARAMS } from 'constants/constants.js';
import { splitWithRemainder } from 'utils/helpers/helpers.js';

const TABLE_NAME_SEPARATOR = '.';

const useSyncTableToGetParam = (table, dataset, search, tab = null) => {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    if (
      table &&
      dataset &&
      (searchParams.get(SEARCH_PARAMS.DATASET) !== dataset ||
        searchParams.get(SEARCH_PARAMS.TABLE) !== table)
    ) {
      searchParams.delete(SEARCH_PARAMS.SEARCH);
      searchParams.set(SEARCH_PARAMS.TABLE, table);
      searchParams.set(SEARCH_PARAMS.DATASET, dataset);
      if (tab) searchParams.set(SEARCH_PARAMS.TAB, tab);
      history.replace({
        pathname: location.pathname,
        search: searchParams.toString(),
        state: history.location.state,
      });
    }
    if (
      !table &&
      dataset &&
      (searchParams.get(SEARCH_PARAMS.DATASET) !== dataset ||
        searchParams.get(SEARCH_PARAMS.TABLE) !== table)
    ) {
      searchParams.delete(SEARCH_PARAMS.TABLE);
      searchParams.delete(SEARCH_PARAMS.SEARCH);
      searchParams.set(SEARCH_PARAMS.DATASET, dataset);
      if (tab) searchParams.set(SEARCH_PARAMS.TAB, tab);
      history.replace({
        pathname: location.pathname,
        search: searchParams.toString(),
        state: history.location.state,
      });
    }
    if (
      !table &&
      !dataset &&
      search &&
      searchParams.get(SEARCH_PARAMS.SEARCH) !== search
    ) {
      searchParams.delete(SEARCH_PARAMS.TABLE);
      searchParams.delete(SEARCH_PARAMS.DATASET);
      searchParams.set(SEARCH_PARAMS.SEARCH, search);
      if (tab) searchParams.set(SEARCH_PARAMS.TAB, tab);
      history.replace({
        pathname: location.pathname,
        search: searchParams.toString(),
        state: history.location.state,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table, dataset, search]);

  const getDatasetTableFromUrl = () => {
    if (
      searchParams.get(SEARCH_PARAMS.SEARCH)?.includes(TABLE_NAME_SEPARATOR)
    ) {
      const [dataset, table] = splitWithRemainder(
        searchParams.get(SEARCH_PARAMS.SEARCH),
        TABLE_NAME_SEPARATOR,
        2
      );
      return { dataset, table };
    }

    return { dataset: null, table: null };
  };

  const getTableFromUrl = () => {
    return searchParams.get(SEARCH_PARAMS.TABLE);
  };

  const getDatasetFromUrl = () => {
    if (searchParams.get(SEARCH_PARAMS.DATASET)) {
      return searchParams.get(SEARCH_PARAMS.DATASET);
    }

    const { dataset } = getDatasetTableFromUrl();
    return dataset || null;
  };

  const getSearchValueFromUrl = () => {
    const { table } = getDatasetTableFromUrl();
    return table || searchParams.get(SEARCH_PARAMS.SEARCH);
  };

  return {
    tableFromUrl: getTableFromUrl(),
    datasetFromUrl: getDatasetFromUrl(),
    searchValueFromUrl: getSearchValueFromUrl(),
    defaultSearchValueFromUrl: searchParams.get(SEARCH_PARAMS.SEARCH),
    clearGetParams: () => {
      searchParams.delete(SEARCH_PARAMS.TABLE);
      searchParams.delete(SEARCH_PARAMS.DATASET);
      searchParams.delete(SEARCH_PARAMS.SEARCH);
      if (tab) searchParams.set(SEARCH_PARAMS.TAB, tab);
      history.replace({
        pathname: location.pathname,
        search: searchParams.toString(),
        state: history.location.state,
      });
    },
  };
};

export { useSyncTableToGetParam };
