import React from 'react';
import {
  useMemo,
  useOpenTableRawBySearch,
  useCostsSourceTables,
  useTableLabels,
  useBqBillingData,
  useState,
  useEffect,
  useRef,
} from 'hooks/hooks.js';
import clsx from 'clsx';
import { TableCell, TableRow } from '@mui/material';
import { Chip, CHIP_COLOR, TableLabelsChips } from 'Components/components.js';
import { usePipelinePageContext } from 'context/context.js';
import {
  getAlertColorType,
  getAlertLabel,
  getPipelineMetaData,
} from 'utils/helpers/helpers.js';
import {
  CostLabel,
  DestinationTableCell,
  LoadingTableRaw,
} from '../components.js';
import SourceTableRow from './SourceTableRow.js';
import { BQ_BILLING_TYPE, PIPELINE_TYPE } from 'utils/constants.js';
import {
  SEARCH_EMAIL,
  SEARCH_TABLE,
  SELECTED_EMAIL,
  SELECTED_POWER_BI_REPORT,
  SELECTED_POWER_BI_WORKSPACE,
  SELECTED_TABLE,
  SELECTED_TAGS,
} from 'Pages/Pipelines/libs/constants/constants.js';

const ColumnDetails = ({
  destinationTable,
  selectedPipelineType,
  pipelineSubtypes,
  isAlternativePlanSelected,
}) => {
  const scrollToRef = useRef(null);
  const [isScrollRefRender, setIsScrollRefRender] = useState(false);
  const [sourcesPage, setSourcesPage] = useState(0);
  const [sourcesData, setSourcesData] = useState([]);
  const { currentBqBillingData } = useBqBillingData();
  const { searchValues } = usePipelinePageContext();
  const isExistingReservationCell =
    currentBqBillingData?.paymentType !== BQ_BILLING_TYPE.ON_DEMANDS;
  const { isOpenTableRaw, handleChangeOpen } = useOpenTableRawBySearch(
    searchValues[SEARCH_TABLE],
    searchValues[SELECTED_TABLE],
    destinationTable,
    destinationTable?.isSelected
  );
  const { defaultJobHash } = usePipelinePageContext();

  const sourceSearchValues = useMemo(() => {
    const { dataset, table } = destinationTable;
    const selectedDataset = searchValues[SELECTED_TABLE]?.dataset;
    const selectedTable = searchValues[SELECTED_TABLE]?.table;
    const searchValue = searchValues[SEARCH_TABLE];

    const isDatasetMatch =
      dataset === selectedDataset ||
      (searchValue.length > 0 && dataset.includes(searchValue));
    const isTableMatch =
      table === selectedTable ||
      (searchValue.length > 0 && table.includes(searchValue));

    return {
      dataset: isDatasetMatch ? '' : selectedDataset,
      table: isTableMatch ? '' : selectedTable,
      searchValue: isDatasetMatch || isTableMatch ? '' : searchValue,
    };
  }, [destinationTable, searchValues]);

  const { sources, isFetchingSources, sourcesCount } = useCostsSourceTables({
    dataset: destinationTable.dataset,
    table: destinationTable.table,
    sourceTable: sourceSearchValues.table,
    sourceDataset: sourceSearchValues.dataset,
    searchValue: sourceSearchValues.searchValue,
    principalEmail: searchValues[SELECTED_EMAIL],
    searchPrincipalEmail: searchValues[SEARCH_EMAIL],
    jobTagNames: searchValues[SELECTED_TAGS],
    powerbiReportName: searchValues[SELECTED_POWER_BI_REPORT],
    powerbiWorkspaceName: searchValues[SELECTED_POWER_BI_WORKSPACE],
    pipelineType: destinationTable?.pipelineType,
    pipelineSubtypes,
    isAlternativePlanSelected,
    page: sourcesPage,
    jobHash: defaultJobHash,
    enabled: isOpenTableRaw,
  });

  const { tableLabels } = useTableLabels(
    destinationTable?.table,
    destinationTable?.dataset
  );

  useEffect(() => {
    setSourcesData((prevState) => {
      const newSources = sources.filter(
        (source) =>
          !prevState.some(
            (existingSource) => existingSource.jobHash === source.jobHash
          )
      );
      return [...prevState, ...newSources];
    });
  }, [sources]);

  useEffect(() => {
    if (scrollToRef.current && isScrollRefRender && defaultJobHash) {
      scrollToRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [isScrollRefRender, defaultJobHash]);

  const isJobTypeCellExists = useMemo(() => {
    return selectedPipelineType === PIPELINE_TYPE.BQ_DTS;
  }, [selectedPipelineType]);

  const handleSetNextPage = () => {
    setSourcesPage((prevState) => prevState + 1);
  };

  const handleSetIsScrollRefRender = (value) => {
    setIsScrollRefRender(value);
  };

  return (
    <>
      <TableRow
        onClick={handleChangeOpen}
        className={clsx(
          isOpenTableRaw && 'isHiddenBottomBorder',
          'styledTableRow'
        )}
      >
        <DestinationTableCell
          isOpen={isOpenTableRaw}
          tableInfo={destinationTable}
          searchTable={searchValues[SELECTED_TABLE]}
          searchValue={searchValues[SEARCH_TABLE]}
          icon={getPipelineMetaData(destinationTable?.pipelineType).icon}
        />
        <TableCell
          align='left'
          sx={{ alignContent: 'baseline' }}
          colSpan={isExistingReservationCell ? 6 : 5}
        >
          <div className='cellContentWithIcon withLargeGap'>
            <Chip
              label={getAlertLabel(destinationTable?.alertType)}
              color={getAlertColorType(destinationTable?.alertType)}
            />

            {destinationTable?.isDeadEnd && (
              <Chip label='Dead-end' color={CHIP_COLOR.error} />
            )}

            {destinationTable?.isAltPlanSavings && (
              <Chip
                label='Alternative plan option'
                color={CHIP_COLOR.success}
              />
            )}

            {tableLabels.length > 0 && (
              <TableLabelsChips tableLabels={tableLabels} />
            )}
          </div>
        </TableCell>

        {isJobTypeCellExists && <TableCell />}

        <TableCell align='right'>
          <div className='txt-mainDark-13-700'>
            <CostLabel value={destinationTable?.costs} />
          </div>
        </TableCell>
      </TableRow>

      {isOpenTableRaw && (
        <>
          {sourcesData.map((source) => (
            <SourceTableRow
              key={source.jobHash}
              source={source}
              destinationTable={destinationTable}
              isJobTypeCellExists={isJobTypeCellExists}
              scrollToRef={
                defaultJobHash === source.jobHash ? scrollToRef : null
              }
              onChangeIsScrollRefRender={handleSetIsScrollRefRender}
            />
          ))}
          {isFetchingSources && <LoadingTableRaw />}
          {sourcesCount > sourcesData.length && (
            <TableRow onClick={handleSetNextPage}>
              <TableCell colSpan={8}>
                <div className='text-center txt-blue-13-700'>Show more</div>
              </TableCell>
            </TableRow>
          )}
        </>
      )}
    </>
  );
};

export default ColumnDetails;
