import { INTEGRATIONS } from './constants.js';
import Slack from 'assets/img/slack.svg';
import Jira from 'assets/img/jira.svg';
import Looker from 'assets/img/looker.svg';
import Dataplex from 'assets/img/dataplex-logo.svg';
import Dataform from 'assets/img/icons/dashboard-icons/dataform.svg';
import Pagerduty from 'assets/img/pagerduty.svg';
import GoogleChat from 'assets/img/integrations/google-chat.png';

const DEFAULT_INTEGRATIONS = [
  {
    isConnected: false,
    unavailable: false,
    name: INTEGRATIONS.slack,
    title: 'Slack',
    Icon: Slack,
    desc: 'Connect your Slack and get notifications about new incidents on the channel in real-time. Keep your whole team in the loop! Slack is connected to all projects.',
    isConnectedAllProjects: true,
  },
  {
    isConnected: false,
    unavailable: false,
    name: INTEGRATIONS.jira,
    title: 'Jira',
    Icon: Jira,
    desc: 'Connect your Jira account and create tasks for new incidents within the Masthead UI. Jira connects to all projects simultaneously.',
    isConnectedAllProjects: true,
  },
  {
    isConnected: false,
    unavailable: false,
    name: INTEGRATIONS.pagerduty,
    title: 'PagerDuty',
    Icon: Pagerduty,
    desc: 'Connect PagerDuty to get notified by service in case of business-impacting incidents. PagerDuty connects to all projects simultaneously.',
  },
  {
    isConnected: false,
    unavailable: false,
    name: INTEGRATIONS.looker,
    title: 'Looker',
    Icon: Looker,
    desc: 'Connect Looker to easily track and receive notifications of any issues with your data-powered dashboards. Each project needs its own Looker connection.',
  },
  {
    isConnected: false,
    unavailable: false,
    name: INTEGRATIONS.dataplex,
    title: 'Dataplex',
    Icon: Dataplex,
    desc: "Increases trust in companies' data through machine learning automation. Each project needs its own Dataplex connection.",
  },
  {
    isConnected: false,
    unavailable: false,
    name: INTEGRATIONS.dataform,
    title: 'Dataform',
    Icon: Dataform,
    desc: 'Connect Dataform to map execution tags and transformation metadata to pipelines. Each project needs its own Dataform connection.',
  },
  {
    isConnected: false,
    unavailable: false,
    name: INTEGRATIONS.googleChat,
    title: 'Google Chat',
    Icon: GoogleChat,
    desc: 'Connect Google Chat to receive real-time notifications for data anomalies, pipeline errors, or compute cost spikes. To connect: Open Google Chat, find Masthead Data app and add it to you space. Google Chat connects to all projects simultaneously.',
  },
];

export { DEFAULT_INTEGRATIONS };
