import React from 'react';
import { useMemo } from 'hooks/hooks.js';
import { useStyles } from '../../Dashboard.styles.js';
import { UnconnectedLooker } from 'Components/components.js';
import {
  IncidentsStatsCard,
  IncidentsStatsItem,
} from './components/components.js';
import { useConfiguration } from 'context/context.js';
import {
  DictionaryTabs,
  IncidentTabs,
  PipelineTabs,
  SEARCH_PARAMS,
} from 'constants/constants.js';
import {
  CRITICAL_ALERT_TYPE,
  PRIORITY_ALERT_TYPE,
  REGULAR_ALERT_TYPE,
} from 'utils/constants.js';
import { AppRoutes } from 'app-routes.js';
import BigQueryLogo from 'assets/img/technologies/bigquery-logo.svg';
import LookerLogo from 'assets/img/technologies/looker/looker-logo-gray.svg';
import PipelineIcon from 'assets/img/icons/pipelines-icons/custom-pipeline-icon.svg';

const IncidentsStats = ({ dashboardData }) => {
  const classes = useStyles();
  const { isLookerConnected } = useConfiguration();

  const incidentsStats = useMemo(() => {
    if (dashboardData) {
      return [
        {
          title: 'BigQuery tables observed',
          icon: BigQueryLogo,
          count: dashboardData.bigQueryDashboard.tables,
          path: {
            pathname: AppRoutes.Dictionary.path,
            search: new URLSearchParams({
              tab: DictionaryTabs.TABLES,
            }).toString(),
          },
          issues: [
            {
              name: 'Critical table issues',
              value: dashboardData.bigQueryDashboard.criticalIssues,
              path: {
                pathname: AppRoutes.Incidents.path,
                search: new URLSearchParams({
                  [SEARCH_PARAMS.TAB]: IncidentTabs.GBQ,
                  [SEARCH_PARAMS.TABLE_ALERT_TYPE]: CRITICAL_ALERT_TYPE,
                }).toString(),
              },
            },
            {
              name: 'Priority tables issues',
              value: dashboardData.bigQueryDashboard.priorityIssues,
              path: {
                pathname: AppRoutes.Incidents.path,
                search: new URLSearchParams({
                  [SEARCH_PARAMS.TAB]: IncidentTabs.GBQ,
                  [SEARCH_PARAMS.TABLE_ALERT_TYPE]: PRIORITY_ALERT_TYPE,
                }).toString(),
              },
            },
            {
              name: 'Unspecified tables issues',
              value: dashboardData.bigQueryDashboard.unspecifiedIssues,
              path: {
                pathname: AppRoutes.Incidents.path,
                search: new URLSearchParams({
                  [SEARCH_PARAMS.TAB]: IncidentTabs.GBQ,
                  [SEARCH_PARAMS.TABLE_ALERT_TYPE]: REGULAR_ALERT_TYPE,
                }).toString(),
              },
            },
          ],
        },
        {
          title: 'Pipelines observed',
          icon: PipelineIcon,
          count: dashboardData.pipelineDashboard.pipelines,
          path: {
            pathname: AppRoutes.Pipelines.path,
            search: new URLSearchParams({
              [SEARCH_PARAMS.TAB]: PipelineTabs.PIPELINE_INFO,
            }).toString(),
          },
          issues: [
            {
              name: 'Critical pipeline issues',
              value: dashboardData.pipelineDashboard.criticalIssues,
              path: {
                pathname: AppRoutes.Incidents.path,
                search: new URLSearchParams({
                  [SEARCH_PARAMS.TAB]: IncidentTabs.PIPELINE,
                  [SEARCH_PARAMS.TABLE_ALERT_TYPE]: CRITICAL_ALERT_TYPE,
                }).toString(),
              },
            },
            {
              name: 'Priority pipeline issues',
              value: dashboardData.pipelineDashboard.priorityIssues,
              path: {
                pathname: AppRoutes.Incidents.path,
                search: new URLSearchParams({
                  [SEARCH_PARAMS.TAB]: IncidentTabs.PIPELINE,
                  [SEARCH_PARAMS.TABLE_ALERT_TYPE]: PRIORITY_ALERT_TYPE,
                }).toString(),
              },
            },
            {
              name: 'Unspecified pipeline issues',
              value: dashboardData.pipelineDashboard.unspecifiedIssues,
              path: {
                pathname: AppRoutes.Incidents.path,
                search: new URLSearchParams({
                  [SEARCH_PARAMS.TAB]: IncidentTabs.PIPELINE,
                  [SEARCH_PARAMS.TABLE_ALERT_TYPE]: REGULAR_ALERT_TYPE,
                }).toString(),
              },
            },
          ],
        },
        ...(isLookerConnected
          ? [
              {
                title: 'Looker dashboards and looks connected',
                icon: LookerLogo,
                count: dashboardData.lookerDashboard.dashboards,
                path: {
                  pathname: AppRoutes.Dictionary.path,
                  search: new URLSearchParams({
                    [SEARCH_PARAMS.TAB]: DictionaryTabs.LOOKER,
                  }).toString(),
                },
                issues: [
                  {
                    name: 'Critical dashboard issues',
                    value: dashboardData.lookerDashboard.criticalIssues,
                    path: {
                      pathname: AppRoutes.Incidents.path,
                      search: new URLSearchParams({
                        [SEARCH_PARAMS.TAB]: IncidentTabs.LOOKER,
                        [SEARCH_PARAMS.TABLE_ALERT_TYPE]: CRITICAL_ALERT_TYPE,
                      }).toString(),
                    },
                  },
                  {
                    name: 'Priority dashboard issues',
                    value: dashboardData.lookerDashboard.priorityIssues,
                    path: {
                      pathname: AppRoutes.Incidents.path,
                      search: new URLSearchParams({
                        [SEARCH_PARAMS.TAB]: IncidentTabs.LOOKER,
                        [SEARCH_PARAMS.TABLE_ALERT_TYPE]: PRIORITY_ALERT_TYPE,
                      }).toString(),
                    },
                  },
                  {
                    name: 'Unspecified dashboard issues',
                    value: dashboardData.lookerDashboard.unspecifiedIssues,
                    path: {
                      pathname: AppRoutes.Incidents.path,
                      search: new URLSearchParams({
                        [SEARCH_PARAMS.TAB]: IncidentTabs.LOOKER,
                        [SEARCH_PARAMS.TABLE_ALERT_TYPE]: REGULAR_ALERT_TYPE,
                      }).toString(),
                    },
                  },
                ],
              },
            ]
          : []),
      ];
    }

    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardData]);

  return (
    <>
      {incidentsStats.length > 0 && (
        <section className={classes.widgetsContainer}>
          {incidentsStats.map((item) => (
            <IncidentsStatsCard key={item.title}>
              <IncidentsStatsItem cardInfo={item} />
            </IncidentsStatsCard>
          ))}

          {!isLookerConnected && (
            <IncidentsStatsCard>
              <UnconnectedLooker />
            </IncidentsStatsCard>
          )}
        </section>
      )}
    </>
  );
};

export { IncidentsStats };
