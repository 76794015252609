import { NodeTypes } from './enums/enums.js';
import { PIPELINE_TYPE } from 'utils/constants.js';

const EdgeNodeTypeSort = {};
EdgeNodeTypeSort[NodeTypes.PIPELINE] = 1;
EdgeNodeTypeSort[NodeTypes.DATASET] = 2;
EdgeNodeTypeSort[NodeTypes.ROUTINE] = 2;
EdgeNodeTypeSort[NodeTypes.LOOKER_DASHBOARD_ELEMENT] = 3;
EdgeNodeTypeSort[NodeTypes.LOOKER_LOOK] = 3;
EdgeNodeTypeSort[NodeTypes.SOURCE_URI] = 4;
EdgeNodeTypeSort[NodeTypes.DESTINATION_URI] = 4;
EdgeNodeTypeSort[NodeTypes.SERVICE_ACCOUNT] = 5;

export const dataTransform = (
  newNodes,
  mainNodeId,
  upstream = [],
  downstreamOld = [], // rename for tableau nodes
  edgeLimit = 5,
  colLimit = 10,
  isEdgesSorted = false,
  tableauPipelines = [] // add for tableau nodes
) => {
  const anomalyTables = newNodes.reduce((acc, curr) => {
    if (
      curr.nodeType === NodeTypes.DATASET &&
      curr.nodeTypeData.isAnomaly !== null
    ) {
      acc[curr.id] = curr.nodeTypeData.isAnomaly;
    }

    return acc;
  }, {});
  const anomalyPipelines = newNodes.reduce((acc, curr) => {
    if (curr.nodeType === NodeTypes.PIPELINE && curr.upEdges.length) {
      acc[curr.id] = curr.upEdges.some((edge) => {
        return !!anomalyTables[edge.source];
      });
    }
    if (
      curr.nodeType === NodeTypes.DATASET &&
      curr.nodeTypeData.isAnomaly &&
      curr.downEdges.length
    ) {
      const pipelines = curr.downEdges
        .filter((edge) => edge.targetNodeType === NodeTypes.PIPELINE)
        .map((edge) => edge.target);
      for (const pipeline of pipelines) {
        acc[pipeline] = true;
      }
    }

    return acc;
  }, {});

  /** add for tableau nodes */
  const tableauTablePipelines = {};
  const tableauJobHashes = tableauPipelines.map((pipeline) => pipeline.jobHash);
  for (const tableauPipeline of tableauPipelines) {
    for (const table of tableauPipeline.tables) {
      const tableId = `${table.project}.${table.dataset}.${table.name}`;
      if (!tableauTablePipelines[tableId]) {
        tableauTablePipelines[tableId] = new Set();
      }
      tableauTablePipelines[tableId].add(tableauPipeline.jobHash);
    }
  }
  const downstream = [...downstreamOld].filter(
    (item) => !tableauJobHashes.includes(item)
  );
  /** --- */

  const nodes = [
    ...newNodes.map((it) => {
      /** add for tableau nodes */
      if (tableauTablePipelines[it.id]) {
        const tableauTablePipelinesArr = [...tableauTablePipelines[it.id]];
        const newDownEdges = tableauTablePipelinesArr.map((pipelineId) => ({
          keys: { '': '' },
          source: it.id,
          target: pipelineId,
          targetNodeType: NodeTypes.PIPELINE,
          tableauEdgeId: it.id + pipelineId + NodeTypes.PIPELINE,
        }));
        for (const newDownEdge of newDownEdges) {
          const exists = it.downEdges.some(
            (edge) =>
              edge.tableauEdgeId &&
              edge.tableauEdgeId === newDownEdge.tableauEdgeId
          );
          if (!exists && it.total !== undefined) {
            it.downEdges.push(newDownEdge);
            it.total.downstreamEdges += 1;
          }
        }
      }
      /** --- */
      if (!it.nodeTypeData?.attrs?.length) {
        return { ...it, attrs: [], totalAttrs: 0 };
      }
      const from = it.collOffset;
      const up = from + colLimit;
      const columns = it.nodeTypeData.attrs.slice(from, up);
      return {
        ...it,
        attrs: columns,
        totalAttrs: it.nodeTypeData.attrs.length,
      };
    }),
  ]; // This is required to crate new instance
  const edges = newNodes
    .map((it) => {
      const upFrom = it.upEdgeOffset;
      const upTo = it.upEdgeOffset + edgeLimit;
      const upEdges = it.upEdges.slice(upFrom, upTo);

      const downFrom = it.downEdgeOffset;
      const downTo = it.downEdgeOffset + edgeLimit;

      if (isEdgesSorted) {
        it.downEdges.sort((a, b) => {
          return (
            EdgeNodeTypeSort[a.targetNodeType] -
            EdgeNodeTypeSort[b.targetNodeType]
          );
        });
      }
      const downEdges = it.downEdges.slice(downFrom, downTo);
      return upEdges
        .concat(downEdges)
        .map((it) => {
          const finalEdges = [];
          for (const key in it.keys) {
            finalEdges.push({
              source: it.source,
              target: it.target,
              targetNodeType: it.targetNodeType,
              sourceKey: key,
              targetKey: it.keys[key],
              isAnomaly: !!anomalyTables[it.source],
            });
          }
          return finalEdges;
        })
        .flat();
    })
    .flat(); // This is required to crate new instance

  /** add for tableau nodes */
  const tables = newNodes.reduce((acc, curr) => {
    if (curr.nodeType === NodeTypes.DATASET) {
      acc.push(curr.id);
    }

    return acc;
  }, []);
  for (const tableauPipeline of tableauPipelines) {
    const sourceTables = [];
    for (const table of tableauPipeline.tables) {
      const tableId = `${table.project}.${table.dataset}.${table.name}`;
      if (!tables.includes(tableId)) {
        continue;
      }
      sourceTables.push(tableId);

      const isAnomalyTable = !!anomalyTables[tableId];
      if (isAnomalyTable) {
        anomalyPipelines[tableauPipeline.jobHash] = true; // set tableau as anomaly pipeline
      }
    }
    if (!sourceTables.length) {
      continue;
    }
    nodes.push({
      attrs: [],
      collOffset: 0,
      totalAttrs: 0,
      nodeType: NodeTypes.PIPELINE,
      nodeTypeData: {
        frequency: tableauPipeline.frequencySec,
        jobTags: tableauPipeline.jobTags,
        lastStartTime: tableauPipeline.lastActivity,
        pipelineType: PIPELINE_TYPE.TABLEAU,
        principalEmail: tableauPipeline.principalEmail,
      },
      parents: sourceTables,
      label: 'Pipeline',
      id: tableauPipeline.jobHash,
      downEdges: [],
      upEdges: [],
      upEdgeOffset: 0,
      downEdgeOffset: 0,
      total: {
        downstream: {
          destinationUris: 0,
          reports: 0,
          tables: 0,
        },
        downstreamEdges: 0,
        upstream: {
          routines: 0,
          sourceUris: 0,
          tables: 0,
        },
        upstreamEdges: 0,
      },
      project: tableauPipeline.sourceProject,
    });
  }
  /** --- */

  const allowedDownstream = [
    ...new Set([
      ...downstream,
      ...edges
        .filter(({ source }) => downstream.includes(source))
        .map(({ target }) => target),
    ]),
  ];
  const allowedUpstream = [
    ...new Set([
      ...upstream,
      ...edges
        .filter(({ target }) => upstream.includes(target))
        .map(({ source }) => source),
    ]),
  ];

  const filteredNodes = nodes.filter(
    ({ id }) =>
      id === mainNodeId ||
      allowedUpstream.includes(id) ||
      allowedDownstream.includes(id)
  );
  const filteredNodesIds = filteredNodes.map((it) => it.id);

  const withEdges = filteredNodes.map((item) => {
    const inEdge = edges.filter(({ target }) => target === item.id);
    const outEdge = edges.filter(({ source }) => source === item.id);

    const openedDownEdgesNumber = [
      ...new Set(outEdge.map((it) => it.target)),
    ].filter((it) => {
      return filteredNodesIds.includes(it);
    }).length;
    const openedUpEdgesNumber = [
      ...new Set(inEdge.map((it) => it.source)),
    ].filter((it) => {
      return filteredNodesIds.includes(it);
    }).length;

    const hidedDownEdgesNumber = getHidedDownEdgesNumber(
      item.total,
      openedDownEdgesNumber
    );
    const hidedUpEdgesNumber = getHidedUpEdgesNumber(
      item.total,
      openedUpEdgesNumber
    );

    const inEdgeLength = inEdge.length;
    const outEdgeLength = outEdge.length;

    item.attrs.forEach((attr) => {
      attr.hasConnection = !!inEdge.find(
        (upEdge) => upEdge.targetKey === attr.key
      );
      if (!attr.hasConnection) {
        attr.hasConnection = !!outEdge.find(
          (upEdge) => upEdge.sourceKey === attr.key
        );
      }
    });

    const isUpstream = allowedUpstream.includes(item.id);
    const isDownstream = allowedDownstream.includes(item.id);
    const isCurrentTable = item.id === mainNodeId;
    const isAllTargetsOpen = item.downEdges.every((item) =>
      allowedDownstream.includes(item.target)
    );
    const isAllSourcesOpen = item.upEdges.every((item) =>
      allowedUpstream.includes(item.source)
    );
    const isNextUpstreamHidden =
      !upstream.includes(item.id) && !isAllSourcesOpen;
    const isNextDownstreamHidden =
      !downstream.includes(item.id) && !isAllTargetsOpen;
    const isAnomalyTable = anomalyTables[item.id];
    const isAnomalySource = inEdge.length
      ? inEdge.some(
          (item) => anomalyTables[item.source] || anomalyPipelines[item.source]
        )
      : anomalyTables[item.parents[0]] && allowedDownstream.includes(item.id);
    const isGrayTable = isAnomalySource && !isAnomalyTable;
    const totalUpstreamEdges = item.upEdges.length || 0;
    const totalDownstreamEdges = item.downEdges.length || 0;
    const limitEdges = item.limitEdges || 0;
    const upEdgeOffset = item.upEdgeOffset || 0;
    const downEdgeOffset = item.downEdgeOffset || 0;
    const offsetEdges = item.offsetEdges || 0;
    const mainNodeProject = mainNodeId.split('.')[0] || '';

    return {
      ...item,
      visibilityProps: {
        isUpstream,
        isDownstream,
        isCurrentTable,
        isNextUpstreamHidden,
        isNextDownstreamHidden,
        inEdgeLength,
        outEdgeLength,
        isAnomalyTable,
        isGrayTable,
        total: item.total,
        totalUpstreamEdges,
        totalDownstreamEdges,
        limitEdges,
        offsetEdges,
        upEdgeOffset,
        downEdgeOffset,
        upEdges: item.upEdges,
        downEdges: item.downEdges,
        hidedDownEdgesNumber,
        hidedUpEdgesNumber,
        mainNodeProject,
      },
    };
  });

  const filteredEdges = edges.filter(
    ({ source, target }) =>
      withEdges.some(({ id }) => id === source) &&
      withEdges.some(({ id }) => id === target)
  );

  return {
    nodes: withEdges,
    edges: filteredEdges,
  };
};

const getHidedDownEdgesNumber = (total, openedDownEdgesNumber) => {
  if (!total?.downstreamEdges) {
    return 0;
  }

  return total.downstreamEdges - openedDownEdgesNumber;
};

const getHidedUpEdgesNumber = (total, openedUpEdgesNumber) => {
  if (!total?.upstreamEdges) {
    return 0;
  }

  return total.upstreamEdges - openedUpEdgesNumber;
};
