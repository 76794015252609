import { useMemo, useQuery, useEffect, useState } from 'hooks/hooks.js';
import { endOfDay, startOfDay } from 'date-fns';
import moment from 'moment/moment';
import { useDateContext } from 'context/context.js';
import { fetcherGet } from 'utils/utils.js';
import { convertLocalToUTCDate } from 'utils/helpers/helpers.js';
import { QUERY_TYPES } from 'constants/constants.js';

const useJobCostsBilling = ({
  startDate = null,
  endDate = null,
  jobTagName = '',
  crossProject = false,
  enabled = true,
}) => {
  const { startDate: from, endDate: to } = useDateContext();
  const start = startDate || from;
  const end = endDate || to;

  const { isFetching, data } = useQuery(
    [QUERY_TYPES.jobCostsBilling, start, end, jobTagName, crossProject],
    ({ queryKey }) => {
      const [url] = queryKey;
      const params = new URLSearchParams();

      params.append(
        'from',
        moment(convertLocalToUTCDate(start)).utc().format()
      );
      params.append('to', moment(convertLocalToUTCDate(end)).utc().format());
      jobTagName?.length && params.append('jobTagName', jobTagName);
      crossProject && params.append('crossProject', crossProject);

      return fetcherGet(url, params);
    },
    { enabled: Boolean(start && end) && enabled }
  );

  const billingJobCostsData = useMemo(() => {
    return data?.values || [];
  }, [data?.values]);

  return { billingJobCostsData, isFetching };
};

const useJobCostsBillingPrevious = ({
  jobTagName = '',
  crossProject = false,
  enabled = true,
}) => {
  const { startDate, endDate } = useDateContext();
  const [startDateBefore, setStartDateBefore] = useState();
  const [endDateBefore, setEndDateBefore] = useState();

  useEffect(() => {
    if (startDate && endDate) {
      const startMoment = moment(startDate).utc();
      const endMoment = moment(endDate).utc();
      const diffMomentDays = Math.floor(
        (endMoment.diff(startMoment, 's') + 1) / 86400
      );
      const startMomentBefore = moment(startMoment).subtract(
        diffMomentDays,
        'd'
      );
      const endMomentBefore = moment(startMomentBefore).add(
        diffMomentDays - 1,
        'd'
      );

      setStartDateBefore(startOfDay(startMomentBefore.toDate()));
      setEndDateBefore(endOfDay(endMomentBefore.toDate()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  const { isFetching: isFetchingDataPrevious, billingJobCostsData } =
    useJobCostsBilling({
      startDate: startDateBefore,
      endDate: endDateBefore,
      jobTagName,
      crossProject,
      enabled:
        startDateBefore !== undefined && endDateBefore !== undefined && enabled,
    });

  const billingJobCostsDataPrevious = useMemo(() => {
    return billingJobCostsData || [];
  }, [billingJobCostsData]);

  return { billingJobCostsDataPrevious, isFetchingDataPrevious };
};

export { useJobCostsBilling, useJobCostsBillingPrevious };
