const reportNameId = 'REPORT_NAME';
const createdById = 'CREATED_BY';
const createdDateId = 'CREATED_DATE';
const descriptionId = 'DESCRIPTION';

const ReportName = {
  ID: reportNameId,
  LABEL: 'Workspace : Report',
  SORT: false,
  ALIGN: 'left',
  IS_SHOW: true,
};
const CreatedBy = {
  ID: createdById,
  LABEL: 'Created by',
  SORT: false,
  ALIGN: 'left',
  IS_SHOW: true,
};
const CreatedDate = {
  ID: createdDateId,
  LABEL: 'Created date',
  SORT: false,
  ALIGN: 'left',
  IS_SHOW: true,
};
const Description = {
  ID: descriptionId,
  LABEL: 'Description',
  SORT: false,
  ALIGN: 'left',
  IS_SHOW: true,
};

export { ReportName, CreatedBy, CreatedDate, Description };
