const StartTimeId = 'START_TIME';
const jobNameId = 'JOB_NAME';

const StartTime = {
  ID: StartTimeId,
  LABEL: 'Start time (UTC)',
  SORT: false,
  ALIGN: 'left',
  IS_SHOW: true,
};
const JobName = {
  ID: jobNameId,
  LABEL: 'Job name',
  SORT: false,
  ALIGN: 'left',
  IS_SHOW: true,
};

export { StartTime, JobName };
