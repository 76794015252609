import React from 'react';
import { useQueryParams } from 'hooks/hooks.js';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { Card, Col, Image, Row } from 'react-bootstrap';
import { useStyles } from 'Components/Widget/Widget.styles.js';
import { AppRoutes } from 'app-routes.js';
import { numberFormat } from 'utils/helpers/helpers.js';
import { SEARCH_PARAMS } from 'constants/constants.js';

const StatsCard = ({ statsCard }) => {
  const classes = useStyles();
  const { queryParams } = useQueryParams();
  const { count, iconPath, title, pipelineType } = statsCard;

  const updatedSearchParams = queryParams;
  updatedSearchParams.set(SEARCH_PARAMS.PIPELINE_TYPE, pipelineType);

  return (
    <Card border='white' style={{ borderRadius: 12 }}>
      <Card.Body className={classes.card}>
        <Row className='p-4'>
          <Col>
            <Link
              to={{
                pathname: AppRoutes.Pipelines.path,
                search: updatedSearchParams.toString(),
              }}
            >
              <span
                className={clsx(
                  !count ? 'txt-lightGrey-24-700' : 'txt-mainDark-24-700'
                )}
              >
                {numberFormat(count)}
              </span>
            </Link>
            <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
              {iconPath.length > 0 && <Image src={iconPath} height={16} />}
              <div className='txt-mainDark-13-500 my-1'>{title}</div>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export { StatsCard };
