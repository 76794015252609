import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetcherDelete,
  fetcherGet,
  fetcherPatch,
  fetcherPost,
} from 'utils/utils.js';
import { formatSearchValue } from 'utils/helpers/helpers.js';
import { QUERY_TYPES, SLICE_NAME } from 'constants/constants.js';
import { JOBS_LIMIT } from 'Pages/DataQuality/libs/constants/constants.js';

const getDataScans = createAsyncThunk(
  `${SLICE_NAME.dataScan}/getDataScans`,
  async (parameters, { rejectWithValue }) => {
    const { limit, page, sortBy, order, dataset, table, search, dataPlexId } =
      parameters;
    const { dataset: newSearchDataset, value: newSearchValue } =
      formatSearchValue(search);

    const response = await fetcherGet(`${QUERY_TYPES.dataPlex}/dataScans`, {
      limit,
      page,
      sortBy,
      order,
      dataset: newSearchDataset || dataset,
      table,
      search: newSearchValue || search,
      dataPlexId,
    });

    if (response.values) {
      return response;
    }

    return rejectWithValue();
  }
);

const getCurrentDataScan = createAsyncThunk(
  `${SLICE_NAME.dataScan}/getCurrentDataScan`,
  async (parameters, { rejectWithValue }) => {
    const { scanId } = parameters;

    const response = await fetcherGet(
      `${QUERY_TYPES.dataPlex}/dataScans/${scanId}`
    );

    if (response.value) {
      return response;
    }

    return rejectWithValue();
  }
);

const getDataScanJobs = createAsyncThunk(
  `${SLICE_NAME.dataScan}/getDataScanJobs`,
  async (parameters, { rejectWithValue }) => {
    const { location, dataPlexId } = parameters;

    const response = await fetcherGet(
      `${QUERY_TYPES.dataPlex}/locations/${location}/dataScans/${dataPlexId}/jobs`,
      { limit: JOBS_LIMIT }
    );

    if (response.values) {
      return response;
    }

    return rejectWithValue();
  }
);

const runDataScan = createAsyncThunk(
  `${SLICE_NAME.dataScan}/runDataScan`,
  async (parameters, { rejectWithValue }) => {
    const { location, dataPlexId } = parameters;

    const response = await fetcherPost(
      `${QUERY_TYPES.dataPlex}/locations/${location}/dataScans/${dataPlexId}/jobs`
    );

    if (response.value) {
      return response;
    }

    return rejectWithValue();
  }
);

const getDataScanRules = createAsyncThunk(
  `${SLICE_NAME.dataScan}/getDataScanRules`,
  async (parameters, { rejectWithValue }) => {
    const { location, dataPlexId, limit, page } = parameters;

    const response = await fetcherGet(
      `${QUERY_TYPES.dataPlex}/locations/${location}/dataScans/${dataPlexId}/rules`,
      { limit, page }
    );

    if (response.values) {
      return response;
    }

    return rejectWithValue();
  }
);

const removeDataScan = createAsyncThunk(
  `${SLICE_NAME.dataScan}/removeDataScan`,
  async (parameters, { rejectWithValue }) => {
    const { location, dataPlexId } = parameters;

    const response = await fetcherDelete(
      `${QUERY_TYPES.dataPlex}/locations/${location}/dataScans/${dataPlexId}`
    );

    if (response.success) {
      return parameters;
    }

    return rejectWithValue();
  }
);

const createDataScan = createAsyncThunk(
  `${SLICE_NAME.dataScan}/createDataScan`,
  async (parameters, { rejectWithValue }) => {
    const { location, dataScan, isRun = false } = parameters;

    const response = await fetcherPost(
      `${QUERY_TYPES.dataPlex}/locations/${location}/dataScans?isRun=${isRun}`,
      dataScan
    );

    if (response.value) {
      return response;
    }

    return rejectWithValue();
  }
);

const updateDataScan = createAsyncThunk(
  `${SLICE_NAME.dataScan}/updateDataScan`,
  async (parameters, { rejectWithValue }) => {
    const { location, dataPlexId, updateDataScanRequest } = parameters;

    const response = await fetcherPatch(
      `${QUERY_TYPES.dataPlex}/locations/${location}/dataScans/${dataPlexId}`,
      updateDataScanRequest
    );

    if (response.value) {
      return response;
    }

    return rejectWithValue();
  }
);

const updateDataScanRules = createAsyncThunk(
  `${SLICE_NAME.dataScan}/updateDataScanRules`,
  async (parameters, { rejectWithValue }) => {
    const { location, dataPlexId, updateDataScanRulesRequest } = parameters;

    const response = await fetcherPatch(
      `${QUERY_TYPES.dataPlex}/locations/${location}/dataScans/${dataPlexId}/rules`,
      updateDataScanRulesRequest
    );

    if (response.value) {
      return response;
    }

    return rejectWithValue();
  }
);

const clearUpdateDataScanStatus = createAsyncThunk(
  `${SLICE_NAME.dataScan}/clearUpdateDataScanStatus`,
  async (_) => {
    return undefined;
  }
);

const clearUpdateDataScanRulesStatus = createAsyncThunk(
  `${SLICE_NAME.dataScan}/clearUpdateDataScanRulesStatus`,
  async (_) => {
    return undefined;
  }
);

const clearCreateDataScanStatus = createAsyncThunk(
  `${SLICE_NAME.dataScan}/clearCreateDataScanStatus`,
  async (_) => {
    return undefined;
  }
);

const clearRemoveDataScanStatus = createAsyncThunk(
  `${SLICE_NAME.dataScan}/clearRemoveDataScanStatus`,
  async (_) => {
    return undefined;
  }
);

export {
  getDataScans,
  getCurrentDataScan,
  getDataScanJobs,
  getDataScanRules,
  removeDataScan,
  clearRemoveDataScanStatus,
  runDataScan,
  createDataScan,
  updateDataScan,
  updateDataScanRules,
  clearUpdateDataScanRulesStatus,
  clearUpdateDataScanStatus,
  clearCreateDataScanStatus,
};
