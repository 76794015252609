import React from 'react';
import { Redirect } from 'react-router-dom';
import { useEffect, useState, useHistory, useLocation } from 'hooks/hooks.js';
import clsx from 'clsx';
import { signInWithPopup } from 'firebase/auth';
import { makeStyles } from '@mui/styles';
import {
  Alert,
  Button,
  BUTTON_VARIANT,
  PositionLoader,
} from 'Components/components.js';
import { auth, googleAuthProvider, amplEvent } from 'service/services.js';
import { AppRoutes } from 'app-routes.js';
import { useUserInfo } from 'context/context.js';
import { DOCUMENT_TITLE } from 'constants/constants.js';
import { ReactComponent as Logo } from 'assets/img/full-white-logo.svg';
import { ReactComponent as GoogleLogo } from 'assets/img/googleIconBgd.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    gap: 32,
    borderRadius: 16,
    '&.headerContainer': {
      borderRadius: 16,
    },
  },
}));

export default function Login() {
  const classes = useStyles();
  const [error, setError] = useState(null);
  const { authError, setAuthError } = useUserInfo();
  const signInGoogle = () => signInWithPopup(auth, googleAuthProvider);
  const { user, userLoading } = useUserInfo();
  const location = useLocation();
  const prevPath = location?.state?.from?.pathname;
  const isExistsRoute = Object.values(AppRoutes).some(
    (route) => route.path === prevPath
  );

  useEffect(() => {
    document.title = DOCUMENT_TITLE.login;
  }, []);

  useEffect(() => {
    if (authError) {
      setError(authError);
      setAuthError(null);
    }
  }, [authError, setAuthError, setError]);

  const history = useHistory();
  const navigateToSignUp = () => history.push(AppRoutes.SignUp.path);

  if (user) {
    let url = '/';
    if (prevPath && isExistsRoute) {
      url = prevPath + location.state.from.search;
    }
    amplEvent('Login');
    return <Redirect to={url} />;
  }

  if (userLoading) {
    return (
      <div style={{ height: 300 }}>
        <PositionLoader />
      </div>
    );
  }

  return (
    <div className={clsx(classes.container, 'headerContainer')}>
      <Logo className={classes.logo} />

      <div className={classes.mainContent}>
        <h2 className='txt-mainDark-32-700 textWhiteColor mb-1'>
          Hi! Welcome back
        </h2>

        <Alert alertComponent={error} className='mt-4 mb-4' />

        <div className='txt-mainDark-16-500 textWhiteColor'>
          To start observing your Google Cloud environment, please use your
          <br />
          email that has GCP project admin rights.
        </div>
      </div>

      <Button
        onClick={signInGoogle}
        text='Sign in with Google'
        variant={BUTTON_VARIANT.outlined}
        startIcon={<GoogleLogo width={24} height={24} />}
        colorfulIcon={true}
        fullWidth={false}
        className='align-self-center pe-6 ps-6'
      />

      <div className='bottomText txt-mainDark-13-700'>
        Don't have an account yet?
        <Button
          onClick={navigateToSignUp}
          text='Sign up'
          variant={BUTTON_VARIANT.text}
          className='p-0 ms-2'
          fullWidth={false}
        />
      </div>
    </div>
  );
}
