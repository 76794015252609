import React from 'react';
import {
  IconButton,
  PipelineDetails,
  RightSideModal,
} from 'Components/components.js';
import { ReactComponent as CopyLink } from 'assets/img/icons/copy-link.svg';

const PipelineDetailsModal = ({
  destinationTable,
  sourceData,
  pipelineDetailsLink,
  onClickCopyLink,
  onClose,
}) => {
  const {
    tables,
    principalEmail,
    jobTags,
    sourceUri,
    destinationUri,
    routines,
    sourceProject,
    jobHash,
  } = sourceData;
  const pipelineElements = [...tables, ...routines];

  return (
    <RightSideModal
      title={
        <div className='d-flex align-items-center gap-1'>
          Pipeline details
          {pipelineDetailsLink && (
            <IconButton icon={<CopyLink />} onClick={onClickCopyLink} />
          )}
        </div>
      }
      subtitle={`Pipeline execution email: ${principalEmail}`}
      ModalBodyComponent={PipelineDetails}
      modalWidth={824}
      jobHash={jobHash}
      jobTags={jobTags}
      lineageData={{
        destinationTable,
        tables: pipelineElements,
        sourceUri,
        destinationUri,
        pipeline: {
          pipelineType: destinationTable.pipelineType,
          sourceProject,
        },
      }}
      pipelineType={destinationTable.pipelineType}
      onClose={onClose}
    />
  );
};

export { PipelineDetailsModal };
