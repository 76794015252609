import { useMemo, useHistory, useLocation } from 'hooks/hooks.js';

const useQueryParams = () => {
  const history = useHistory();
  const location = useLocation();

  const searchParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  const updateQueryParams = (newParams) => {
    Object.keys(newParams).forEach((key) => {
      if (newParams[key] !== undefined && newParams[key] !== null) {
        searchParams.set(key, newParams[key]);
      } else {
        searchParams.delete(key);
      }
    });

    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  const clearQueryParams = () => {
    history.push({
      pathname: location.pathname,
      search: '',
    });
  };

  return {
    queryParams: searchParams,
    updateQueryParams,
    clearQueryParams,
  };
};

export { useQueryParams };
