import React, { createContext } from 'react';
import {
  useContext,
  useHistory,
  useLocation,
  useMemo,
  useQueryParams,
  useState,
  useEffect,
} from 'hooks/hooks.js';
import moment from 'moment';
import { endOfDay, startOfDay } from 'date-fns';
import {
  convertLocalToUTCDate,
  defaultEndDate,
  defaultStartDate,
} from 'utils/helpers/helpers.js';
import { AppRoutes } from 'app-routes.js';
import {
  DAYS_LIMIT,
  PipelineTabs,
  SEARCH_PARAMS,
} from 'constants/constants.js';

const ROUTES_WITH_DATE_PARAMS = [
  AppRoutes.Dashboard.path,
  AppRoutes.Monitors.path,
  AppRoutes.Incidents.path,
];

const getURLDateFormat = (date) => {
  return moment(date).format('YYYY-MM-DD');
};

const getDaysLabel = (daysSelected) => {
  if (daysSelected === DAYS_LIMIT.day) {
    return 'daily';
  } else if (daysSelected % DAYS_LIMIT.week === 0) {
    const weeksNumber = daysSelected % DAYS_LIMIT.week;
    return daysSelected === DAYS_LIMIT.week ? 'weekly' : `${weeksNumber}w`;
  } else if (daysSelected % DAYS_LIMIT.month === 0) {
    const monthsNumber = daysSelected % DAYS_LIMIT.month;
    return daysSelected === DAYS_LIMIT.month ? 'monthly' : `${monthsNumber}mo`;
  }
  return `${daysSelected}d`;
};

const DateContext = createContext(null);

const DateContextProvider = ({ children }) => {
  const location = useLocation();
  const history = useHistory();
  const { queryParams, updateQueryParams } = useQueryParams();
  const urlStartDate = queryParams.get(SEARCH_PARAMS.FROM);
  const urlEndDate = queryParams.get(SEARCH_PARAMS.TO);
  const pageTab = queryParams.get(SEARCH_PARAMS.TAB);

  const [startDate, setStartDate] = useState(
    urlStartDate
      ? startOfDay(moment(urlStartDate).toDate())
      : defaultStartDate()
  );
  const [endDate, setEndDate] = useState(
    urlEndDate ? endOfDay(moment(urlEndDate).toDate()) : defaultEndDate()
  );
  const startDateURLFormat = getURLDateFormat(startDate);
  const endDateURLFormat = getURLDateFormat(endDate);

  const startDateUtc = useMemo(() => {
    return moment(convertLocalToUTCDate(startDate)).utc().format();
  }, [startDate]);
  const endDateUtc = useMemo(() => {
    return moment(convertLocalToUTCDate(endDate)).utc().format();
  }, [endDate]);

  const defaultStartDateUtc = moment(convertLocalToUTCDate(defaultStartDate()))
    .utc()
    .format();
  const defaultEndDateUtc = moment(convertLocalToUTCDate(defaultEndDate()))
    .utc()
    .format();

  const daysSelected = useMemo(() => {
    if (startDate && endDate) {
      return moment(endDate).diff(moment(startDate), 'days') + 1;
    }
    return 0;
  }, [startDate, endDate]);

  const daysLabel = useMemo(() => {
    return getDaysLabel(daysSelected);
  }, [daysSelected]);

  const updateDateParams = () => {
    if (
      urlStartDate !== startDateURLFormat ||
      urlEndDate !== endDateURLFormat
    ) {
      updateQueryParams({
        [SEARCH_PARAMS.FROM]: getURLDateFormat(startDate),
        [SEARCH_PARAMS.TO]: getURLDateFormat(endDate),
      });
    }
  };

  useEffect(() => {
    if (ROUTES_WITH_DATE_PARAMS.includes(location.pathname)) {
      updateDateParams();
    }
    if (location.pathname === AppRoutes.Pipelines.path) {
      if (pageTab === PipelineTabs.PIPELINE_INFO) {
        if (urlStartDate || urlEndDate) {
          queryParams.delete(SEARCH_PARAMS.FROM);
          queryParams.delete(SEARCH_PARAMS.TO);
          history.replace({
            pathname: location.pathname,
            state: location.state,
            search: queryParams.toString(),
          });
        }
      } else {
        updateDateParams();
      }
    }
  }, [
    endDate,
    location.pathname,
    startDate,
    urlEndDate,
    urlStartDate,
    pageTab,
  ]);

  const onChooseDates = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    location.state = { ...location.state, startDate: start, endDate: end };

    history.replace({
      pathname: location.pathname,
      state: location.state,
      search: queryParams.toString(),
    });
  };

  return (
    <DateContext.Provider
      value={{
        startDate,
        endDate,
        startDateUtc,
        endDateUtc,
        defaultStartDateUtc,
        defaultEndDateUtc,
        daysSelected,
        daysLabel,
        onChooseDates,
      }}
    >
      {children}
    </DateContext.Provider>
  );
};

const useDateContext = () => {
  const context = useContext(DateContext);

  if (!context) {
    throw new Error('useDateContext must be used within a DateContextProvider');
  }

  return context;
};

export { DateContextProvider, useDateContext };
