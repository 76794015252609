import { endOfDay, startOfDay, startOfHour, subDays } from 'date-fns';
import {
  LAST_1_HOUR,
  LAST_12_HOURS,
  LAST_DAY,
  LAST_7_DAYS,
} from 'constants/constants.js';

const HOURS_TO_MILLISECONDS = 1000 * 60 * 60;

const getPipelineTimePeriodByFilter = (filterOptionValue) => {
  switch (filterOptionValue) {
    case LAST_1_HOUR:
      return {
        start: startOfHour(
          new Date(new Date().getTime() - HOURS_TO_MILLISECONDS)
        ),
        end: endOfDay(new Date()),
      };
    case LAST_12_HOURS:
      return {
        start: startOfHour(
          new Date(new Date().getTime() - 12 * HOURS_TO_MILLISECONDS)
        ),
        end: endOfDay(new Date()),
      };
    case LAST_DAY:
      return {
        start: startOfHour(
          new Date(new Date().getTime() - 24 * HOURS_TO_MILLISECONDS)
        ),
        end: endOfDay(new Date()),
      };
    case LAST_7_DAYS:
      return {
        start: startOfDay(subDays(new Date(), 6)),
        end: endOfDay(new Date()),
      };
    default:
      return null;
  }
};

export { getPipelineTimePeriodByFilter };
