import React from 'react';
import {
  useState,
  useHistory,
  useEffect,
  useLocation,
  useCallback,
} from 'hooks/hooks.js';
import { signInWithPopup } from 'firebase/auth';
import { FormControl, FormGroup } from '@mui/material';
import { AppRoutes } from 'app-routes.js';
import { auth, googleAuthProvider, signUpAmplEvent } from 'service/services.js';
import { Button, BUTTON_VARIANT } from 'Components/components.js';
import {
  UserProfileInfo,
  InputCompany,
  InputName,
  InputUseCase,
  PrivacyCheckField,
} from './components/components.js';
import { useSignUpInfo } from 'context/context.js';
import { AUTH_ERRORS, DOCUMENT_TITLE } from 'constants/constants.js';
import {
  INPUT_VALIDATION,
  INPUT_VALUE_ERRORS,
} from './libs/constants/constants.js';
import { ReactComponent as GoogleLogo } from 'assets/img/googleIconBgd.svg';

const CreateFormComponent = (props) => {
  const {
    checked,
    onChangeChecked,
    onNextStepClick,
    nameValue,
    onNameValueChange,
    companyValue,
    onCompanyValueChange,
    useCaseValue,
    onUseCaseValueChange,
  } = props;

  return (
    <div className='formBlock'>
      <InputName nameValue={nameValue} onNameValueChange={onNameValueChange} />
      <InputCompany
        companyValue={companyValue}
        onCompanyValueChange={onCompanyValueChange}
      />
      <InputUseCase
        useCaseValue={useCaseValue}
        onUseCaseValueChange={onUseCaseValueChange}
      />

      <form onSubmit={(e) => e.preventDefault()}>
        <FormGroup>
          <div className='mb-4'>
            <PrivacyCheckField
              checked={checked}
              onChangeChecked={onChangeChecked}
            />
          </div>
          <FormControl>
            <Button
              onClick={onNextStepClick}
              text='Sign up with Google'
              variant={BUTTON_VARIANT.outlined}
              startIcon={<GoogleLogo width={24} height={24} />}
              colorfulIcon={true}
              fullWidth={false}
              className='align-self-center pe-6 ps-6'
            />
          </FormControl>
        </FormGroup>
      </form>
    </div>
  );
};

const SignUp = () => {
  const history = useHistory();
  const { setSignUpInfo } = useSignUpInfo();
  const [privacyChecked, setPrivacyChecked] = useState(false);
  const [nameValue, setNameValue] = useState('');
  const [companyValue, setCompanyValue] = useState('');
  const [useCaseValue, setUseCaseValue] = useState('');
  const [error, setError] = useState(null);
  const eventButton = `Sign up (set up profile) -> Click -`;
  const location = useLocation();
  const [marketplaceAccountId, setMarketplaceAccountId] = useState(null);

  useEffect(() => {
    document.title = DOCUMENT_TITLE.signUp;
  }, []);

  const onChangePrivacyChecked = () => setPrivacyChecked((prev) => !prev);

  const onNameValueChange = useCallback((value) => {
    if (value.length === INPUT_VALIDATION.maxLength) {
      setError(INPUT_VALUE_ERRORS.limitCharacters);
    } else {
      setError(null);
    }

    setNameValue(value);
  }, []);

  const onCompanyValueChange = useCallback((value) => {
    if (value.length === INPUT_VALIDATION.maxLength) {
      setError(INPUT_VALUE_ERRORS.limitCharacters);
    } else {
      setError(null);
    }

    setCompanyValue(value);
  }, []);

  const onUseCaseValueChange = useCallback((value) => {
    if (value.length === INPUT_VALIDATION.maxLength) {
      setError(INPUT_VALUE_ERRORS.limitCharacters);
    } else {
      setError(null);
    }

    setUseCaseValue(value);
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const marketplaceId = searchParams.get('marketplaceId');

    if (marketplaceId) {
      searchParams.delete('marketplaceId');
      history.replace({ search: searchParams.toString() });
      setMarketplaceAccountId(marketplaceId);
    }
  }, [location.search, history]);
  const toNextStep = () => history.push(AppRoutes.Deployment.path);

  const saveValue = (e) => {
    if (!nameValue.length && !companyValue.length) {
      setError(INPUT_VALUE_ERRORS.nameCompanyRequired);
      return;
    }

    if (!nameValue.length) {
      setError(INPUT_VALUE_ERRORS.nameRequired);
      return;
    }

    if (!companyValue.length) {
      setError(INPUT_VALUE_ERRORS.companyRequired);
      return;
    }

    if (!privacyChecked) {
      return;
    }

    signInWithPopup(auth, googleAuthProvider)
      .then((result) => {
        setSignUpInfo({
          user: result.user,
          accessToken: result.user.accessToken,
          name: nameValue,
          company: companyValue,
          companyRole: '',
          companyUseCase: useCaseValue,
          marketplaceAccountId: marketplaceAccountId,
        });

        signUpAmplEvent(
          `${eventButton} ${e.target.textContent}`,
          result.user.accessToken,
          nameValue,
          companyValue,
          useCaseValue
        );
        toNextStep();
      })
      .catch(() => {
        setError(AUTH_ERRORS.STH_ELSE);
      });
  };

  return (
    <UserProfileInfo
      error={error}
      FormComponent={CreateFormComponent}
      checked={privacyChecked}
      onChangeChecked={onChangePrivacyChecked}
      onNextStepClick={saveValue}
      nameValue={nameValue}
      onNameValueChange={onNameValueChange}
      companyValue={companyValue}
      onCompanyValueChange={onCompanyValueChange}
      onUseCaseValueChange={onUseCaseValueChange}
      useCaseValue={useCaseValue}
    />
  );
};

export default SignUp;
