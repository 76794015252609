const INITIAL_TECHNOLOGY_OPTION = {
  value: '',
  label: 'All technologies',
};

const INITIAL_PIPELINE_SUBTYPES_OPTION = {
  value: '',
  label: 'All Job Types',
};

const INITIAL_COST_EFFICIENCY_OPTION = {
  value: '',
  label: 'All cost efficiency',
};

const TABLE_ALERT_STATUS_FILTER = 'tableAlertStatus';
const ACTION_STATUS_FILTER = 'pipelineActionStatus';
const PIPELINE_SUBTYPES_FILTER = 'pipelineSubtypes';
const TIME_PERIOD_FILTER = 'pipelineTimePeriod';
const TECHNOLOGY_FILTER = 'technology';
const COST_EFFICIENCY_FILTER = 'costEfficiency';

const PipelineDropdowns = [
  TABLE_ALERT_STATUS_FILTER,
  ACTION_STATUS_FILTER,
  TIME_PERIOD_FILTER,
  TECHNOLOGY_FILTER,
  COST_EFFICIENCY_FILTER,
];

const CostsDropdowns = [
  TABLE_ALERT_STATUS_FILTER,
  COST_EFFICIENCY_FILTER,
  TECHNOLOGY_FILTER,
  PIPELINE_SUBTYPES_FILTER,
];

const ResourceStatsDropdowns = [
  TABLE_ALERT_STATUS_FILTER,
  COST_EFFICIENCY_FILTER,
  TECHNOLOGY_FILTER,
];

export {
  INITIAL_TECHNOLOGY_OPTION,
  INITIAL_PIPELINE_SUBTYPES_OPTION,
  INITIAL_COST_EFFICIENCY_OPTION,
  TABLE_ALERT_STATUS_FILTER,
  ACTION_STATUS_FILTER,
  PIPELINE_SUBTYPES_FILTER,
  TIME_PERIOD_FILTER,
  TECHNOLOGY_FILTER,
  COST_EFFICIENCY_FILTER,
  PipelineDropdowns,
  CostsDropdowns,
  ResourceStatsDropdowns,
};
