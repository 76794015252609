import React, { createContext } from 'react';
import {
  useContext,
  useState,
  useLocation,
  useHistory,
  usePagination,
  useQueryParams,
  useEffect,
  useMemo,
} from 'hooks/hooks.js';
import { useMessages, useUserInfo } from 'context/context.js';
import { amplEvent } from 'service/services.js';
import {
  getIsValidUUID,
  getPipelineDetailsUrl,
} from 'utils/helpers/helpers.js';
import {
  SEARCH_PARAMS,
  PipelineTabs,
  AMPL_PAGE_EVENT,
  ALERT_SEVERITY,
} from 'constants/constants.js';
import {
  DefaultSearchValues,
  SELECTED_DESTINATION,
  SELECTED_LABEL,
  SELECTED_TABLE,
  SELECTED_TAGS,
} from 'Pages/Pipelines/libs/constants/constants.js';

const EVENT_CLICK = `${AMPL_PAGE_EVENT.pipeline} -> Tab:`;
const TABLE_NAME_SEPARATOR = '.';
const LABEL_SEPARATOR = ':';

const getCurrentTabFromUrl = (queryParams) => {
  const tab = queryParams.get(SEARCH_PARAMS.TAB);
  if (tab && Object.values(PipelineTabs).includes(tab)) {
    return tab;
  }
  return PipelineTabs.COSTS;
};

const getSearchValue = (queryParams) => {
  const value = { ...DefaultSearchValues };
  for (const searchKey in value) {
    const searchValue =
      searchKey === SELECTED_TAGS
        ? queryParams.getAll(searchKey)
        : queryParams.get(searchKey);

    if (searchValue?.length > 0) {
      if (searchKey === SELECTED_TABLE || searchKey === SELECTED_DESTINATION) {
        const [dataset, table] = searchValue.split(TABLE_NAME_SEPARATOR);
        value[searchKey] = { dataset, table };
        break;
      }

      if (searchKey === SELECTED_LABEL) {
        const [labelKey, labelValue] = searchValue.split(LABEL_SEPARATOR);
        value[searchKey] = { key: labelKey, value: labelValue };
        break;
      }

      if (searchKey === SELECTED_TAGS) {
        if (Array.isArray(searchValue)) {
          value[searchKey] = [...searchValue];
        } else {
          value[searchKey] = [searchValue];
        }
        break;
      }

      value[searchKey] = searchValue;
      break;
    }
  }

  return value;
};

const PipelinePageContext = createContext(null);

const PipelinePageContextProvider = ({ children }) => {
  const { setMessage, updatePosition } = useMessages();
  const location = useLocation();
  const history = useHistory();
  const { currentProject } = useUserInfo();
  const { queryParams, updateQueryParams } = useQueryParams();
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination();
  const [paginationTotal, setPaginationTotal] = useState(0);

  const [currentTab, setCurrentTab] = useState(
    getCurrentTabFromUrl(queryParams)
  );
  const [searchValues, setSearchValues] = useState(getSearchValue(queryParams));
  const [selectedJobHash, setSelectedJobHash] = useState(
    queryParams?.get(SEARCH_PARAMS.JOB_HASH)
  );
  const [defaultJobHash, setDefaultJobHash] = useState(
    queryParams?.get(SEARCH_PARAMS.JOB_HASH)
  );
  const isValidDefaultJobHash = getIsValidUUID(defaultJobHash);
  const [isJobHashFound, setIsJobHashFound] = useState(true);

  const pipelineDetailsLink = useMemo(() => {
    if (currentProject && currentTab && selectedJobHash) {
      return getPipelineDetailsUrl(currentProject, currentTab, selectedJobHash);
    }

    return null;
  }, [currentProject, currentTab, selectedJobHash]);

  useEffect(() => {
    updateQueryParams({ jobHash: selectedJobHash });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedJobHash]);

  useEffect(() => {
    if (defaultJobHash && (!isValidDefaultJobHash || !isJobHashFound)) {
      setDefaultJobHash(null);
      setSelectedJobHash(null);
      updatePosition({ horizontal: 'center', vertical: 'bottom' });
      setMessage('Pipeline not found', ALERT_SEVERITY.success);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultJobHash, isValidDefaultJobHash, isJobHashFound]);

  const handleChangeJobHash = (jobHash) => {
    setSelectedJobHash(jobHash);
    setDefaultJobHash(null);

    if (jobHash !== null) {
      amplEvent(
        `${EVENT_CLICK} ${currentTab} -> Pipeline Details -> Job Hash: ${jobHash}`
      );
    }
  };

  const handleChangeTab = (selectedTab) => {
    if (queryParams.get(SEARCH_PARAMS.TAB) !== selectedTab) {
      if (selectedTab === PipelineTabs.RESOURCE_ALLOCATION) {
        for (const defaultSearchKey in DefaultSearchValues) {
          queryParams.delete(defaultSearchKey);
        }
      }

      queryParams.set(SEARCH_PARAMS.TAB, selectedTab);
      history.replace({
        pathname: location.pathname,
        search: queryParams.toString(),
        state: history.location.state,
      });
    }
    amplEvent(`${EVENT_CLICK} ${selectedTab}`);
    setCurrentTab(selectedTab);
  };

  const handleChangePaginationTotal = (value) => {
    setPaginationTotal(value);
  };

  const handleChangeSearchValues = (searchKey, searchValue) => {
    handleChangePage({}, 0);
    const newSearchValues = { ...DefaultSearchValues };
    if (searchKey && searchValue) {
      newSearchValues[searchKey] = searchValue;
    }
    setSearchValues(newSearchValues);

    for (const defaultSearchKey in DefaultSearchValues) {
      if (defaultSearchKey === searchKey && searchValue) {
        if (
          searchKey === SELECTED_TABLE ||
          searchKey === SELECTED_DESTINATION
        ) {
          const param =
            searchValue.dataset && searchValue.table
              ? `${searchValue.dataset}${TABLE_NAME_SEPARATOR}${searchValue.table}`
              : `${searchValue.dataset}`;

          queryParams.set(defaultSearchKey, param);
          continue;
        }
        if (searchKey === SELECTED_LABEL) {
          queryParams.set(
            defaultSearchKey,
            `${searchValue.key}${LABEL_SEPARATOR}${searchValue.value}`
          );
          continue;
        }
        if (searchKey === SELECTED_TAGS && Array.isArray(searchValue)) {
          queryParams.delete(defaultSearchKey);
          for (const item of searchValue) {
            queryParams.append(defaultSearchKey, item);
          }
          continue;
        }
        queryParams.set(defaultSearchKey, searchValue);
      } else {
        queryParams.delete(defaultSearchKey);
      }
    }
    history.replace({
      pathname: location.pathname,
      search: queryParams.toString(),
      state: history.location.state,
    });
  };

  const handleChangeIsJobHashFound = (value) => {
    setIsJobHashFound(value);
  };

  const onClickCopyLink = () => {
    navigator.clipboard.writeText(pipelineDetailsLink);
  };

  return (
    <PipelinePageContext.Provider
      value={{
        currentTab,
        handleChangeTab,
        searchValues,
        handleChangeSearchValues,
        defaultJobHash,
        selectedJobHash,
        handleChangeJobHash,
        page,
        rowsPerPage,
        handleChangePage,
        handleChangeRowsPerPage,
        paginationTotal,
        handleChangePaginationTotal,
        pipelineDetailsLink,
        onClickCopyLink,
        handleChangeIsJobHashFound,
      }}
    >
      {children}
    </PipelinePageContext.Provider>
  );
};

const usePipelinePageContext = () => {
  const context = useContext(PipelinePageContext);

  if (!context) {
    throw new Error(
      'usePipelinePageContext must be used within a PipelinePageContext'
    );
  }

  return context;
};

export { PipelinePageContextProvider, usePipelinePageContext };
