import React from 'react';
import { useMemo, useState } from 'hooks/hooks.js';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { CostsStatsWidget } from 'Components/components.js';
import { COSTS_STATS_VIEW_TYPE } from 'constants/constants.js';
import { SavingsOptionsWidget } from './SavingsOptionsWidget.js';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: theme.spacing(6),
    alignItems: 'start',
  },
  largeStatsWidget: {
    gridColumn: (props) =>
      props.viewType === COSTS_STATS_VIEW_TYPE.graph
        ? '1 / 2'
        : props.largeGridItemPosition,
  },
}));

const CostsStats = ({
  costsStats,
  costsStatsPrevious,
  deadEndTablesStats,
  className = '',
  viewType = COSTS_STATS_VIEW_TYPE.table,
}) => {
  const [savingsOptionsWidgetExists, setSavingsOptionsWidgetExists] =
    useState(true);
  const largeGridItemPosition = savingsOptionsWidgetExists ? '1 / 2' : '1 / 3';

  const classes = useStyles({ largeGridItemPosition, viewType });

  const totalAmount = useMemo(() => {
    return costsStats.reduce((acc, { costs }) => acc + costs, 0);
  }, [costsStats]);

  const handleChangeSavingsOptionsWidget = (value) => {
    setSavingsOptionsWidgetExists(value);
  };

  return (
    <div className={clsx(classes.gridContainer, className)}>
      {costsStats.length > 0 && (
        <CostsStatsWidget
          costsStats={costsStats}
          costsStatsPrevious={costsStatsPrevious}
          className={classes.largeStatsWidget}
          viewType={viewType}
        />
      )}

      <SavingsOptionsWidget
        totalAmount={totalAmount}
        deadEndTablesStats={deadEndTablesStats}
        onChangeSavingsOptionsWidget={handleChangeSavingsOptionsWidget}
      />
    </div>
  );
};

export { CostsStats };
