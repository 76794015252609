import React from 'react';
import {
  useCallback,
  useDispatch,
  useEffect,
  useMemo,
  usePagination,
  useSelector,
  useState,
  useHistory,
} from 'hooks/hooks.js';
import { makeStyles } from '@mui/styles';
import { Table, TableBody } from '@mui/material';
import { DATA_STATUS, ORDER_TYPES } from 'constants/constants.js';
import {
  Button,
  BUTTON_COLOR,
  Pagination,
  PositionLoader,
  TableHeadWithCheckbox,
} from 'Components/components.js';
import { DataQualityTable } from 'Pages/DataQuality/components/components.js';
import Widget from 'Components/Widget/Widget.js';
import { useConfiguration } from 'context/context.js';
import { getDataScans } from 'slices/actions.js';
import {
  LastRun,
  ScanName,
  Schedule,
} from 'Pages/DataQuality/libs/enums/enums.js';
import { getWidgetLabel } from 'utils/helpers/helpers.js';
import { AppRoutes } from 'app-routes.js';
import { ReactComponent as DataplexLogo } from 'assets/img/dataplex-logo.svg';

const HEAD_CELLS = [ScanName, LastRun, Schedule];

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(4),
  },
  button: {
    width: 112,
  },
}));

const DataScanWidget = ({ tableInfo = null }) => {
  const { isDataplexConnected, isDataplexApiDisabled } = useConfiguration();
  const dispatch = useDispatch();
  const history = useHistory();
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination(false);
  const [order, setOrder] = useState(ORDER_TYPES.asc);
  const [sortBy, setSortBy] = useState(ScanName.id);
  const { dataScan } = getWidgetLabel();

  const { dataScans, dataScansNumber, dataScansStatus } = useSelector(
    (state) => ({
      dataScans: state.dataScans.dataScans,
      dataScansNumber: state.dataScans.dataScansNumber,
      dataScansStatus: state.dataScans.dataScansStatus,
    })
  );

  const isLoadingScans = useMemo(() => {
    return dataScansStatus === DATA_STATUS.pending;
  }, [dataScansStatus]);

  const isShowDivider = useMemo(() => {
    return isLoadingScans || dataScansNumber > 0;
  }, [dataScansNumber, isLoadingScans]);

  useEffect(() => {
    if (tableInfo) {
      dispatch(
        getDataScans({
          limit: rowsPerPage,
          page: page + 1,
          sortBy,
          order,
          dataset: tableInfo.dataset,
          table: tableInfo.table,
          search: '',
          dataPlexId: '',
        })
      );
    }
  }, [dispatch, order, page, rowsPerPage, sortBy, tableInfo]);

  const handleRequestSort = useCallback(
    (property) => {
      const isAsc = sortBy === property && order === ORDER_TYPES.asc;
      handleChangePage({}, 0);
      setOrder(isAsc ? ORDER_TYPES.desc : ORDER_TYPES.asc);
      setSortBy(property);
    },
    [handleChangePage, order, sortBy]
  );

  const navigateToCreateScanPage = useCallback(() => {
    history.push({
      pathname: AppRoutes.DataQuality_createScan.path,
      search: new URLSearchParams({
        dataset: tableInfo?.dataset,
        table: tableInfo?.table,
      }).toString(),
    });
  }, [history, tableInfo?.dataset, tableInfo?.table]);

  if (!isDataplexConnected || isDataplexApiDisabled) {
    return <UnconnectedDataplex />;
  }

  return (
    <Widget
      label={dataScan.title}
      secondaryLabel={dataScan.subtitle}
      incidentCount={dataScansNumber}
      count={true}
      existingDivider={isShowDivider}
      initialSettings={{ size: 'L', height: 'auto' }}
      isShowButton={true}
      onWidgetButtonClick={navigateToCreateScanPage}
      widgetButtonText='Create scan'
    >
      {isLoadingScans ? (
        <div className='m-4'>
          <PositionLoader />
        </div>
      ) : (
        <div>
          {dataScans.length > 0 && (
            <Table>
              <TableHeadWithCheckbox
                onRequestSort={handleRequestSort}
                headCells={HEAD_CELLS}
                selectedColumn={false}
              />
              <TableBody>
                {dataScans.map((scan) => {
                  return (
                    <DataQualityTable
                      key={`${scan.dataPlexId}/${scan.id}`}
                      rowData={scan}
                      isShowTableNameCell={false}
                      isShowEditCell={false}
                    />
                  );
                })}
              </TableBody>
            </Table>
          )}

          <Pagination
            count={dataScansNumber}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      )}
    </Widget>
  );
};

const UnconnectedDataplex = () => {
  const classes = useStyles();
  const history = useHistory();

  const onConnectButtonClick = () => {
    history.push(AppRoutes.DataQuality.path);
  };

  return (
    <Widget
      existingDivider={false}
      initialSettings={{ size: 'L', height: 'auto' }}
    >
      <div className={classes.flexContainer}>
        <div className={classes.title}>
          <DataplexLogo />
          <p className='txt-grey-13-500'>
            Connect Dataplex to automate data quality checks
          </p>
        </div>
        <Button
          text='Connect'
          fullWidth={false}
          onClick={onConnectButtonClick}
          className={classes.button}
          color={BUTTON_COLOR.secondary}
        />
      </div>
    </Widget>
  );
};

export { DataScanWidget };
