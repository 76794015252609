import { makeStyles } from '@mui/styles';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Typography, TYPOGRAPHY_VARIANT } from 'Components/components.js';
import { numberFormat } from 'utils/helpers/helpers.js';
import ArrowRight from 'assets/img/icons/arrows/arrow-right-16.svg';

const useStyles = makeStyles((theme) => ({
  card: {
    flex: '1 1 0',
    position: 'relative',
    padding: theme.spacing(6),
    background: theme.palette.common.white,
    borderRadius: theme.spacing(4),
  },

  title: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  statsContainer: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3, 2),
    background: theme.palette.secondary.light,
    borderRadius: theme.spacing(2),
    '& > a': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },

  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(4),
  },
}));

const IncidentsStatsCard = ({ children }) => {
  const classes = useStyles();

  return <section className={classes.card}>{children}</section>;
};

const IncidentsStatsItem = ({ cardInfo }) => {
  const classes = useStyles();
  const { title, icon, count, path, issues } = cardInfo;

  return (
    <>
      <div className={classes.title}>
        <Image src={icon} width={18} height={18} alt='logo' />
        <Typography>{title}</Typography>
      </div>

      <Link to={path}>
        <Typography variant={TYPOGRAPHY_VARIANT.h2} className='mb-4'>
          {numberFormat(count)}
        </Typography>
      </Link>

      {issues.map(({ name, value, path }) => (
        <section key={name} className={classes.statsContainer}>
          <Link to={path}>
            <Typography>{name}</Typography>
            <div className={classes.flexContainer}>
              <Typography>{numberFormat(value)}</Typography>
              <Image
                src={ArrowRight}
                width={16}
                height={16}
                alt='arrow right'
              />
            </div>
          </Link>
        </section>
      ))}
    </>
  );
};

export { IncidentsStatsCard, IncidentsStatsItem };
