import React from 'react';
import { Redirect } from 'react-router-dom';
import { useState, useHistory, useEffect } from 'hooks/hooks.js';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { AppRoutes } from 'app-routes.js';
import { useSignUpInfo, useUserInfo } from 'context/context.js';
import { Alert, Button, BUTTON_VARIANT } from 'Components/components.js';
import {
  HeaderInfoContainer,
  GoogleDeployment,
  ManualDeployment,
} from './components/components.js';
import { DOCUMENT_TITLE } from 'constants/constants.js';
import { ReactComponent as Logo } from 'assets/img/full-white-logo.svg';
import { ReactComponent as SecurityIcon } from 'assets/img/securityIcon.svg';

export const useStyles = makeStyles((theme) => ({
  headerContainer: {
    '& .headerInfo': {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '12px 0',
      '& .leftAlignText': {
        textAlign: 'left',
      },
    },
    '& .securityInfo': {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
      alignSelf: 'center',
      '& svg path': {
        fill: theme.palette.common.white,
      },
    },
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 32,
  },
  alert: {
    margin: '32px 36px 0',
  },
  mainContainer: {
    display: 'flex',
    gap: 100,
    padding: '32px 36px 36px',
  },
  badgeContainer: {
    display: 'flex',
    gap: 16,
    '& .badgeItem': {
      padding: '4px 12px',
      backgroundColor: theme.palette.secondary.main,
      borderRadius: 4,
    },
  },
  deploymentButton: {
    width: 280,
  },
}));

const Deployment = () => {
  const classes = useStyles();
  const [error, setError] = useState(null);
  const { signUpInfo, setSignUpInfo } = useSignUpInfo();
  const history = useHistory();
  const { user } = useUserInfo();

  useEffect(() => {
    document.title = DOCUMENT_TITLE.deployment;
  }, []);

  const handleChangeError = (errorText) => {
    setError(errorText);
  };

  if (!signUpInfo && !user) {
    return <Redirect to={AppRoutes.SignUp.path} />;
  }

  if (user && signUpInfo?.user?.email !== user.email) {
    setSignUpInfo({
      user: user,
      accessToken: user.accessToken,
      name: user.info.name,
      company: user.info.company,
      companyRole: '',
      companyUseCase: '',
    });
  }

  return (
    <div>
      <div className={clsx(classes.headerContainer, 'headerContainer')}>
        <div className={classes.logoContainer}>
          <Logo />
          <HeaderInfoContainer />
        </div>

        <h2 className='txt-mainDark-32-700 textWhiteColor mb-1'>
          How do you get started
        </h2>
        <div className='txt-mainDark-16-500 textWhiteColor'>
          To operate we need following resources in your Google Cloud project
        </div>

        <div className='headerInfo'>
          <ul className='mb-0'>
            <li className='txt-mainDark-13-500 textWhiteColor leftAlignText'>
              Pub/Sub topic and subscription.&nbsp;
              {/*<a*/}
              {/*  href='https://docs.mastheadata.com/masthead-agent/security#pub-sub-topic'*/}
              {/*  target='_blank'*/}
              {/*  rel='noreferrer'*/}
              {/*  className='link'*/}
              {/*>*/}
              {/*  See details*/}
              {/*</a>*/}
            </li>
            <li className='txt-mainDark-13-500 textWhiteColor leftAlignText'>
              Logs Router with specific filter.&nbsp;
              {/*<a*/}
              {/*  href='https://docs.mastheadata.com/masthead-agent/security#logs-router'*/}
              {/*  target='_blank'*/}
              {/*  rel='noreferrer'*/}
              {/*  className='link'*/}
              {/*>*/}
              {/*  See details*/}
              {/*</a>*/}
            </li>
          </ul>

          <ul className='mb-0'>
            <li className='txt-mainDark-13-500 textWhiteColor leftAlignText'>
              Custom role to get metadata of datasets and tables.&nbsp;
              {/*<a*/}
              {/*  href='https://docs.mastheadata.com/masthead-agent/security#custom-role'*/}
              {/*  target='_blank'*/}
              {/*  rel='noreferrer'*/}
              {/*  className='link'*/}
              {/*>*/}
              {/*  See details*/}
              {/*</a>*/}
            </li>
            <li className='txt-mainDark-13-500 textWhiteColor leftAlignText'>
              Masthead Service Account with custom permissions.&nbsp;
              {/*<a*/}
              {/*  href='https://docs.mastheadata.com/masthead-agent/security#bind-service-account'*/}
              {/*  target='_blank'*/}
              {/*  rel='noreferrer'*/}
              {/*  className='link'*/}
              {/*>*/}
              {/*  See details*/}
              {/*</a>*/}
            </li>
          </ul>
        </div>

        <div className='securityInfo'>
          <SecurityIcon />
          <div className='txt-mainDark-13-700 textWhiteColor'>
            Masthead does not read or edit your data at any point.{' '}
            <span className='txt-mainDark-13-500 textWhiteColor'>
              Review&nbsp;
              <a
                href='https://docs.mastheadata.com'
                target='_blank'
                rel='noreferrer'
                className='link'
              >
                docs
              </a>
              &nbsp;for further info.
            </span>
          </div>
        </div>
      </div>

      <Alert alertComponent={error} className={classes.alert} />

      <div className={classes.mainContainer}>
        <GoogleDeployment handleChangeError={handleChangeError} />
        <ManualDeployment />
      </div>

      <Button
        onClick={history.goBack}
        text='Cancel'
        variant={BUTTON_VARIANT.text}
        className='p-0 bottomText'
        fullWidth={false}
      />
    </div>
  );
};

export default Deployment;
