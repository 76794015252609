import { useQuery, useMemo } from 'hooks/hooks.js';
import { fetcherGet } from 'utils/utils.js';
import { QUERY_TYPES } from 'constants/constants.js';

const usePipelineTags = ({ jobHash }) => {
  const { data, isFetching: isFetchingTags } = useQuery(
    [QUERY_TYPES.pipelineTags, jobHash],
    ({ queryKey }) => {
      const [url, jobHash] = queryKey;
      return fetcherGet(url, { jobHash });
    },
    { enabled: Boolean(jobHash) }
  );

  const pipelineTags = useMemo(() => {
    return data?.values || [];
  }, [data?.values]);

  return { pipelineTags, isFetchingTags };
};

export { usePipelineTags };
