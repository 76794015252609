import {
  INCIDENT_STATUS_FILTER,
  INCIDENT_STATUS_SHORT_FILTER,
  INCIDENT_TYPE_FILTER,
  LOOKER_ALERT_FILTER,
  LOOKER_INCIDENT_TYPE_FILTER,
  PIPELINE_INCIDENT_TYPE_FILTER,
  TABLE_ALERT_FILTER,
  TECHNOLOGY_FILTER,
} from '../constants/constants.js';
import { SEARCH_PARAMS } from 'constants/constants.js';

const getKeyForURL = (type) => {
  switch (type) {
    case INCIDENT_TYPE_FILTER:
      return SEARCH_PARAMS.INCIDENT_TYPE;
    case LOOKER_INCIDENT_TYPE_FILTER:
      return SEARCH_PARAMS.LOOKER_INCIDENT_TYPE;
    case PIPELINE_INCIDENT_TYPE_FILTER:
      return SEARCH_PARAMS.PIPELINE_INCIDENT_TYPE;
    case TABLE_ALERT_FILTER:
      return SEARCH_PARAMS.TABLE_ALERT_TYPE;
    case LOOKER_ALERT_FILTER:
      return SEARCH_PARAMS.DASHBOARD_ALERT_TYPE;
    case TECHNOLOGY_FILTER:
      return SEARCH_PARAMS.PIPELINE_TYPE;
    case INCIDENT_STATUS_FILTER:
      return SEARCH_PARAMS.INCIDENT_STATUS;
    case INCIDENT_STATUS_SHORT_FILTER:
      return SEARCH_PARAMS.INCIDENT_SHORT_STATUS;
    default:
      return null;
  }
};

export { getKeyForURL };
