import { useEffect, useMemo, useJobFreshness } from 'hooks/hooks.js';
import { Freshness } from 'Components/components.js';
import { GRAPH_HEIGHT } from 'constants/constants.js';

const PipelineDataFreshness = ({
  jobHash,
  onLoadingFreshnessDataChange,
  freshnessDataLimit = null,
  freshnessHeight = GRAPH_HEIGHT.large,
}) => {
  const { jobFreshnessData, isFetching } = useJobFreshness({ jobHash });

  const data = useMemo(() => {
    if (freshnessDataLimit) {
      return jobFreshnessData.slice(-freshnessDataLimit);
    }

    return jobFreshnessData;
  }, [jobFreshnessData, freshnessDataLimit]);

  useEffect(() => {
    if (onLoadingFreshnessDataChange) {
      onLoadingFreshnessDataChange(isFetching);
    }
  }, [isFetching, onLoadingFreshnessDataChange]);

  if (!isFetching && !data.length) {
    return (
      <div className='txt-grey-13-500 text-center text-italic'>
        No data for graph
      </div>
    );
  }

  return (
    <Freshness
      data={data}
      isFetchingData={isFetching}
      freshnessHeight={freshnessHeight}
    />
  );
};

export { PipelineDataFreshness };
