import React from 'react';
import { useMemo, useEmptySearchMessage } from 'hooks/hooks.js';
import { Table, TableBody, TableContainer } from '@mui/material';
import { useStyles } from 'Pages/Pipelines/GlobalPipeline.styles.js';
import { EmptySearchData } from 'Pages/Pipelines/components/components.js';
import { PipelineTableHead } from './PipelineTableHead.js';
import { PipelineTableRows } from './PipelineTableRows.js';
import { getFullTableName } from 'utils/helpers/helpers.js';
import {
  EMPTY_SEARCH_MESSAGES,
  SEARCH_PARAMS_CONFIG,
} from 'Pages/Pipelines/libs/constants/constants.js';

const PipelineTable = ({
  destinationTables = [],
  isFetchingDestinationTables,
  startDate,
  endDate,
}) => {
  const classes = useStyles();
  const { emptySearchMessage } = useEmptySearchMessage({
    searchParamsConfig: SEARCH_PARAMS_CONFIG,
    emptySearchMessageConfig: {
      messages: EMPTY_SEARCH_MESSAGES,
    },
  });

  const isEmptySearch = useMemo(() => {
    return Boolean(
      !destinationTables.length &&
        !isFetchingDestinationTables &&
        emptySearchMessage.length
    );
  }, [
    emptySearchMessage.length,
    destinationTables.length,
    isFetchingDestinationTables,
  ]);

  return (
    <TableContainer sx={{ padding: 0 }}>
      <Table>
        <PipelineTableHead />
        <TableBody className={classes.tableBody}>
          {isEmptySearch ? (
            <EmptySearchData colSpan={9} text={emptySearchMessage} />
          ) : (
            destinationTables.length > 0 &&
            destinationTables.map((destinationTable) => (
              <PipelineTableRows
                key={`${getFullTableName(
                  destinationTable.table,
                  destinationTable.dataset
                )}/${destinationTable.pipelineType}`}
                destinationTable={destinationTable}
                startDate={startDate}
                endDate={endDate}
              />
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export { PipelineTable };
