import React from 'react';
import Widget from 'Components/Widget/Widget.js';
import { DictionaryTabs, SEARCH_PARAMS } from 'constants/constants.js';
import { AppRoutes } from 'app-routes.js';
import { ReportsMemo } from './Reports.js';
import { getWidgetLabel } from 'utils/helpers/helpers.js';
import lookerIcon from 'assets/img/looker.svg';

const ReportsWidget = ({
  table,
  dataset,
  getLinkPath,
  onChangeExistingData,
}) => {
  const { dependentReports } = getWidgetLabel();

  const linkPath = getLinkPath(AppRoutes.Dictionary.path, {
    [SEARCH_PARAMS.TAB]: DictionaryTabs.LOOKER,
  });

  return (
    <Widget
      label={dependentReports.title}
      secondaryLabel={dependentReports.subtitle}
      path={linkPath}
      linkName='View in Dictionary'
      initialSettings={{ size: 'L', height: 'auto' }}
      iconPath={lookerIcon}
    >
      <ReportsMemo
        table={table}
        dataset={dataset}
        onChangeExistingData={onChangeExistingData}
      />
    </Widget>
  );
};

export { ReportsWidget };
