import React from 'react';
import {
  useJobFrequency,
  useJobsExecutionCount,
  useState,
  useMemo,
  useEffect,
} from 'hooks/hooks.js';
import { Divider, JobsExecutionGraph } from 'Components/components.js';
import Widget from 'Components/Widget/Widget.js';
import { JobsRunsTable } from './components/components.js';
import { getWidgetLabel } from 'utils/helpers/helpers.js';

const DEFAULT_FREQUENCY_MILLISECONDS = 3600 * 24 * 1000;

const JobExecutionWidget = ({ jobHash }) => {
  const {
    jobsExecution: { title, subtitle },
  } = getWidgetLabel();
  const { jobsExecutionCountData, isLoadingJobsExecutionCountData } =
    useJobsExecutionCount({ jobHash });
  const { frequencyMilliseconds } = useJobFrequency(jobHash);

  const [selectedJobExecutionDate, setSelectedJobExecutionDate] =
    useState(null);

  const defaultJobExecutionDate = useMemo(() => {
    return jobsExecutionCountData.at(-1)?.datetime;
  }, [jobsExecutionCountData]);

  useEffect(() => {
    if (defaultJobExecutionDate) {
      setSelectedJobExecutionDate(defaultJobExecutionDate);
    }
  }, [defaultJobExecutionDate]);

  const handleChangeJobExecutionDate = (value) => {
    setSelectedJobExecutionDate(value || defaultJobExecutionDate);
  };

  return (
    <Widget
      label={title}
      secondaryLabel={subtitle}
      initialSettings={{ size: 'L', height: 'auto' }}
      hidden={true}
      isLoading={isLoadingJobsExecutionCountData}
    >
      <div className='pt-3'>
        {jobsExecutionCountData.length ? (
          <div>
            <JobsExecutionGraph
              data={jobsExecutionCountData}
              frequencyMilliseconds={
                frequencyMilliseconds || DEFAULT_FREQUENCY_MILLISECONDS
              }
              selectedDatetime={selectedJobExecutionDate}
              onChangeSelectedDate={handleChangeJobExecutionDate}
            />
            <Divider />
            <JobsRunsTable
              jobHash={jobHash}
              frequencyMilliseconds={
                frequencyMilliseconds || DEFAULT_FREQUENCY_MILLISECONDS
              }
              startDate={selectedJobExecutionDate}
            />
          </div>
        ) : (
          <div className='txt-grey-13-500 text-center text-italic'>
            No data for graph
          </div>
        )}
      </div>
    </Widget>
  );
};

export { JobExecutionWidget };
