import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  button: {
    '&.MuiButton-containedPrimary': {
      '& svg path': {
        fill: (props) => !props.colorfulIcon && theme.palette.common.white,
      },
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    '&.MuiButton-containedSecondary': {
      '& svg path': {
        fill: (props) => !props.colorfulIcon && theme.palette.primary.main,
      },
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
    },

    '&.MuiButton-textPrimary': {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },

    '&.MuiButton-outlinedPrimary': {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
      borderColor: theme.palette.divider,
      '&:hover': {
        backgroundColor: theme.palette.common.white,
      },
    },
  },
}));
